export function showPageLoader(container = document.body, type = 'page') {
    let pageLoader = container['@@@_loader_' + type];

    if (!(pageLoader && pageLoader.parentElement)) {
        pageLoader = document.createElement('span');
        pageLoader.setAttribute('hidden', 'hidden');
        pageLoader.setAttribute('class', 'b-loader b-loader--' + type);
        pageLoader.innerHTML = '<span class="b-loader__text"></span>';
        container.appendChild(pageLoader);
        container['@@@_loader_' + type] = pageLoader;
    }

    pageLoader.removeAttribute('hidden');
    return pageLoader;
}

export function hidePageLoader(container = document.body, type = 'page') {
    const pageLoader = container['@@@_loader_' + type];

    if (pageLoader) {
        pageLoader.setAttribute('hidden', 'hidden');
    }

    return pageLoader;
}
