import BasicInput from "./BasicInput";

/**
 * @description Implementation
 */
export default class InputRadio extends BasicInput {
    getValue() {
        const el = (this.ref('self').get());

        if (el) {
            return Array.from(el.querySelectorAll('[data-ref="field"]'))
                .reduce((val, el) => {
                    const tmpEl = /** @type {HTMLInputElement} */(el);
                    return val || ((tmpEl.checked || '') && tmpEl.value);
                }, '');
        }
        return '';
    }
    getDataValue() {
        const el = (this.ref('self').get());

        if (el) {
            return Array.from(el.querySelectorAll('[data-ref="field"]'))
                .reduce((val, el) => {
                    const tmpEl = /** @type {HTMLInputElement} */(el);
                    return val || ((tmpEl.checked || '') && tmpEl.getAttribute('data-value'));
                }, '');
        }
        return '';
    }
    validate() {
        return true;
    }
    setValue(value) {
        const el = (this.ref('self').get());

        if (el) {
            Array.from(el.querySelectorAll('[data-ref="field"]'))
                .forEach((el) => {
                    const tmpEl = /** @type {HTMLInputElement} */(el);

                    if (tmpEl.value === value) {
                        tmpEl.checked = true;
                        tmpEl.setAttribute('checked', 'checked');
                    } else {
                        tmpEl.checked = false;
                        tmpEl.removeAttribute('checked');
                    }
                });
        }
    }
}