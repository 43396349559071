import { getContentByUrl as getContentByURL } from 'widgets/toolbox/ajax';
import { replaceParameterInURL, getUrlParams, appendParamsToUrl } from 'widgets/toolbox/util';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { throttle } from 'widgets/toolbox/throttle';
import viewtype from 'widgets/toolbox/viewtype';
import { getScrollTop } from 'widgets/toolbox/scroll';
import Widget from '../Widget';

export function withInfiniteScroll(ClassToExtend) {
    /**
     * @description Infinite scroll implementation
     */
    return class extends ClassToExtend {
        /**
         * @param {HTMLElement} el DOM element
         * @param {object} config widget config
         */
        constructor(el, config = {}) {
            super(el, config);
            this.retailProducts = [];
            this.removedInitialProducts = 0;
        }

        prefs() {
            return {
                contentWrapper: 'productWrapper',
                contentList: 'productGrid',
                ...super.prefs()
            };
        }

        getDefaultPageSize() {
            if (viewtype.isMobileView() && this.config.sitePreferences.DEFAULT_MOBILE_PAGE_SIZE) {
                return this.config.sitePreferences.DEFAULT_MOBILE_PAGE_SIZE
            }
            return this.config.sitePreferences.DEFAULT_PAGE_SIZE;
        }

        init() {
            super.init();
            this.checkInfinite();
            this.config.pageNumber = getUrlParams(window.location.href).page ? +getUrlParams(window.location.href).page : 0;
            this.pageTitle = document.title;
            this.pageDescription = document.getElementsByName('description').length ? document.getElementsByName('description')[0].content : null;

            if (this.config.pageNumber) {
                document.title = this.pageTitle + ' | Page ' + this.config.pageNumber;
                if (this.pageDescription) {
                    document.getElementsByName('description')[0].content = this.pageDescription + ' | Page ' + this.config.pageNumber;
                }
            }

            this.eventBus().on('retail.product.loaded', 'addRetailProduct');
            this.eventBus().on('remove.initial.products', 'removeInitialProducts');
        }

        onRefresh() {
            super.onRefresh();
            this.checkInfinite();
        }

        checkInfinite() {
            if (this.ref('self').data('loadMoreButton')) {
                const loadMoreButton = document.querySelector('#loadMore');
                if (loadMoreButton) {
                    loadMoreButton.addEventListener('click', () => {
                        this.loadMore();
                    });
                }
                if (getUrlParams(window.location.href).page) {
                    this.loadMore(true);
                }
            } else if (this.getStart(undefined, this.getDefaultPageSize()) < this.ref('self').data('count')) {
                if (!this.cleanUpScroll) {
                    const scrollHandler = () => this.loadMore();
                    var fn = throttle(scrollHandler, 75);
                    window.addEventListener('scroll', fn);
                    this.cleanUpScroll = () => {
                        window.removeEventListener('scroll', fn);
                    };
                }
                if (this.config.pageNumber != getUrlParams(window.location.href).page) {
                    this.loadMore();
                }
            } else if (this.cleanUpScroll) {
                this.cleanUpScroll();
                this.cleanUpScroll = void 0;
            }
        }

        loadMore(ignorePageParam) {
            const loadMoreUrl = this.ref('self').data('loadMoreUrl');

            if (!this.isNotLoaded && loadMoreUrl) {
                this.isNotLoaded = true;

                const contentWrapper = this.ref(this.prefs().contentWrapper);
                const contentList = this.ref(this.prefs().contentList);

                const htmlHeight = this.ref('html').prop('clientHeight');
                let listHeight = contentList.prop('scrollHeight');
                let initialStart;
                if (this.has('contentListInitial')) {
                    const contentListInitial = this.ref('contentListInitial');
                    if (contentListInitial.els.length) {
                        listHeight = contentListInitial.prop('scrollHeight');
                    }
                    initialStart = contentListInitial.data('start');
                }

                const isAboveFooter = this.isAboveFooter(getScrollTop());

                let scrollTop = getScrollTop(); 
                const isAboveFold = ((scrollTop + htmlHeight) >= (listHeight - htmlHeight));
                if (
                    (listHeight > 0 && isAboveFold && isAboveFooter) ||
                    ignorePageParam
                ) {
                    const pageStart = this.getStart(initialStart, this.getDefaultPageSize());

                    if (this.ref('self').data('count') > pageStart) {

                        let url = replaceParameterInURL(
                            loadMoreUrl,
                            'start', pageStart.toString()
                        );

                        if (this.retailProducts && this.retailProducts.length) {
                            url = appendParamsToUrl(url, {
                                retailResults: this.retailProducts.join(',')
                            });
                        }

                        url = appendParamsToUrl(url, {
                            removedInitialProducts: this.removedInitialProducts
                        });

                        if (ignorePageParam) {
                            url = replaceParameterInURL(
                                url,
                                'page', getUrlParams(window.location.href).page
                            );
                        }

                        if (this.getDefaultPageSize() !== this.config.sitePreferences.DEFAULT_PAGE_SIZE) {
                            url = replaceParameterInURL(
                                url,
                                'sz', this.getDefaultPageSize()
                            );
                        }


                        return getContentByURL(url)
                            .then(response => {
                                let isOnFooter = false;

                                scrollTop = getScrollTop();
                                if (!this.isAboveFooter(scrollTop)) {
                                    isOnFooter = true;
                                }
                                contentList.append(response);
                                if (response.indexOf('loadMoreBtnHide') !== -1) {
                                    document.querySelector('#loadMore').remove();
                                }
                                if (!ignorePageParam) {
                                    this.config.pageNumber++;
                                    const currentUrl = replaceParameterInURL(window.location.href, 'page', this.config.pageNumber);
                                    window.history.replaceState(null, '', currentUrl);
                                    document.title = this.pageTitle + ' | Page ' + this.config.pageNumber;
                                }
                                if (this.pageDescription) {
                                    document.getElementsByName('description')[0].content = this.pageDescription + ' | Page ' + this.config.pageNumber;
                                }
                                if (isOnFooter) {
                                    let nextListHeight = contentList.prop('scrollHeight');
                                    window.scrollBy(0, nextListHeight - listHeight);

                                }
                                this.moreLoaded();
                                this.checkInfinite();
                            }).finally(() => {
                                // hidePageLoader(contentWrapper.get(), 'inline');
                                this.isNotLoaded = false;
                            });
                    }
                } else {
                    this.isNotLoaded = false;
                }
            }
        }

        getStart(initialStart, defaultPageSize) {
            return (initialStart ? +initialStart : this.config.sitePreferences.INITIAL_PAGE_SIZE) +
                    this.prefs().pageNumber * defaultPageSize;
        }

        isAboveFooter(scrollTop) {
            const footerEl = this.ref('html').get().querySelector('#pageFooter');
            const footerOffset = footerEl.offsetTop + (footerEl.clientHeight * 2 / 3);
            return (scrollTop + window.innerHeight) < footerOffset;
        }

        moreLoaded() {}

        addRetailProduct(product) {
            if (!this.retailProducts.find(id => id === product)) {
                this.retailProducts.push(product);
            }
        }

        removeInitialProducts(numberOfProducts) {
            this.removedInitialProducts = numberOfProducts;
        }
    };
}

const InfiniteScroll = withInfiniteScroll(Widget); 
export default InfiniteScroll;