import { debounce } from 'widgets/toolbox/debounce';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import BasicInput from 'widgets/forms/BasicInput';

const parentContainer = '#header-search';
const carouselContainer = '.b-carousel__content';
const assetContainer = '.js-banner-wrapper';

const suggestionProductClasses = {
    current: 'current-product-suggestions',
    preloaded: 'default-product-suggestions'
};

const suggestionRefs = {
    current: {
        product: 'productSuggestions',
        general: 'generalSuggestions'
    },
    preloaded: {
        product: 'productSuggestions-default',
        general: 'generalSuggestions-default'
    }
};

const mobileContainerClass = 'b-hamburger__item--search';

/**
 * @description Input type="search" implementation
 */
export default class InputSearchBox extends BasicInput {
    constructor(...args) {
        super(...args);
        this.getSuggestions = debounce(this.getSearchSuggestions.bind(this), 300);
        this.eventBus().on('sync.search.query', 'syncSearch');
    }
    prefs() {
        return {
            minChars: 3,
            maxChars: 50,
            url: '',
            ...super.prefs()
        };
    }
    clearValue() {
        this.setValue('');
    }

    syncSearch(value) {
        this.ref('field').val(value);
    }

    showMobileSearch () {
        this.eventBus().emit('mobile.search.show');
    }

    hideMobileSearch () {
        this.eventBus().emit('mobile.search.hide');
        this.ref('field').val('');
        this.getSearchSuggestions();
    }

    getSearchSuggestions() {
        const fieldValue = this.getValue();

        this.eventBus().emit('sync.search.query', fieldValue);

        if (fieldValue.length === 0) {
            this.eventBus().emit('sync.menu.products', {type: 'preloaded'});
            this.eventBus().emit('sync.menu.suggestions', {
                type: 'preloaded',
                markup: null
            });
            return;
        }

        if (fieldValue.length >= this.prefs().minChars && fieldValue !== this.previousValue && fieldValue.length <= this.prefs().maxChars) {
            const suggestionContainerProducts = this.getLoaderWrapper();

            if (suggestionContainerProducts) {
                showPageLoader(suggestionContainerProducts, 'bound');
            }

            getContentByUrl(this.prefs().url, { q: fieldValue })
                .then(response => {
                    if (response) {
                        this.processResponse(response);
                    }

                    if (suggestionContainerProducts) {
                        hidePageLoader(suggestionContainerProducts, 'bound');
                    }
                });
        }
    }

    /**
     * Fetches the container in which to put the loader spinner
     * @returns {Element | null}
     */

    getLoaderWrapper() {
        let suggestionContainerProducts;
        const currentContainer = document.querySelector(`${parentContainer} .${suggestionProductClasses.current}`);
        const preloadedContainer = document.querySelector(`${parentContainer} .${suggestionProductClasses.preloaded}`);

        if (!currentContainer?.classList.contains('hide')) {
            suggestionContainerProducts = currentContainer?.querySelector(carouselContainer);
        } else {
            var carouselContent = preloadedContainer?.querySelector(carouselContainer);
            var assetContent = preloadedContainer?.querySelector(assetContainer);

            suggestionContainerProducts = carouselContent ? carouselContent : assetContent;
        }

        return suggestionContainerProducts || null;
    }

    /**
     * Hides the search suggestion container of the given type
     * @param {'preloaded' | 'current'} type
     * @returns {void}
     */

    hideSuggestions (type) {
        const selectors = suggestionRefs[type];
        for (const suggestionSelector in selectors) {
            this.ref(selectors[suggestionSelector]).hide();
        }
    }

    /**
     * Shows the search suggestion container of the given type
     * @param {'preloaded' | 'current'} type
     * @returns {void}
     */

    showSuggestions (type) {
        const selectors = suggestionRefs[type];
        for (const suggestionSelector in selectors) {
            this.ref(selectors[suggestionSelector]).show();
        }
    }

    /**
     * Process Ajax response for SearchServices-GetSuggestions
     *
     * @param {Object|string} response - Empty object literal if null response or string with rendered
     *                                   suggestions template contents
     */

    processResponse(response) {
        if (typeof(response) === 'string') {
            const suggestions = new DOMParser().parseFromString(response, 'text/html');
            const productSuggestionContainer = suggestions.querySelector('.' + suggestionRefs.current.product);
            const generalSuggestionContainer = suggestions.querySelector(`.${suggestionRefs.current.general}`);

            const productCount = productSuggestionContainer?.querySelectorAll('.b-product-tile').length;

            if (productCount && productCount >= 1) {
                this.eventBus().emit('sync.menu.products', {type: 'current', markup: productSuggestionContainer?.innerHTML});
            } else {
                this.eventBus().emit('sync.menu.products', {type: 'preloaded'});
            }

            this.ref(suggestionRefs.current.general).show();
            this.ref(suggestionRefs.preloaded.general).hide();

            this.eventBus().emit('sync.menu.suggestions', {
                type: 'current',
                markup: generalSuggestionContainer?.innerHTML
            });
        }
    }

    isMobileSearch() {
        const parentContainer = this.ref('self').els[0].parentElement;
        return parentContainer?.classList.contains(mobileContainerClass);
    }

    inputFocus() {
        this.ref('html').addClass(this.prefs().classesHtmlFocus);
        if (this.isMobileSearch()) {
            this.eventBus().emit('mobile.search.show');
        }
    }

    inputBlur() {
        this.ref('html').removeClass(this.prefs().classesHtmlFocus);
    }

    handleSubmit(el, event) {

        // avoid empty search
        if (!(this.ref('field').val() && this.validate()) && event instanceof Event) {
            event.preventDefault();
            return false;
        }
    }
}