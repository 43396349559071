import AjaxForm from 'widgets/forms/AjaxForm';
import BasicInput from 'widgets/forms/BasicInput';

/**
 * @description Email Subscribe footer implementation
 */
export default class OrderSurvey extends AjaxForm {
    prefs() {
        return {
            signupEmail: 'signupEmail',
            formContent: 'formContent',
            successClass: 'b-footer-sign-up__status_success',
            formFields: 'formFields',
            field: 'field',
            ...super.prefs()
        };
    }
    init() {
        this.ref(this.prefs().submitButton).disable();
        this.eventBus().on('signup.submitButtonState', 'submitButtonState');
    }

    /**
     * @param {Object} data submitted data
     */
    onSubmitted(data) {
        if (data.success) {
            this.eventBus().emit('signup.submitted', data);
            this.ref(this.prefs().successMessage).setText(data.msg);
            this.ref(this.prefs().successMessage).addClass(this.prefs().successClass);

            document.querySelectorAll('.hide-success').forEach(hide => {
                hide.style.display = 'none';
            });
            this.ref(this.prefs().formFields).hide();
            this.ref(this.prefs().submitButton).hide();

            var formWrapper = document.querySelector('.form-wrapper');

            if (formWrapper) {
                formWrapper.classList.add('align-top');
            }
        }

        if (data.error) {
            this.ref(this.prefs().signupEmail).setError(data.msg);
        }
    }

    /**
     * @param {Boolean} isActiveState button state
     */
    submitButtonState(isActiveState) {
        if (isActiveState) {
            this.ref(this.prefs().submitButton).enable();
        } else {
            this.ref(this.prefs().submitButton).disable();
        }
    }
    handleSubmit() {
        var valid = this.isChildsValid();
        // avoid request when submit button is disabled (e.g. press Enter on Safari Mac)
        if (this.ref(this.prefs().submitButton).isDisabled() || !valid) {
            return;
        }
        this.eventBus().emit('email.submitted', this.getFormFields());
        super.handleSubmit();
    }
    changeAnswer() {
        this.eventBus().emit('signup.submitButtonState', true);
        var formFields = this.ref(this.prefs().formFields);

        if (formFields.els) {
            var otherAnswer = formFields.els[0].querySelector('#otherSurvey');
            var otherAnswerError = formFields.els[0].querySelector('.otherSurvey .invalid-feedback');
            var isChecked = formFields.els[0].querySelectorAll('[name="surveyAnswer"]:checked')[0].value === '';

            if (otherAnswer) {
                otherAnswer.removeAttribute('hidden');
                otherAnswer.removeAttribute('disabled');
                otherAnswer.style.display = isChecked ? 'block' : 'none';
                if (!isChecked) {
                    otherAnswer.disabled = true;
                }
            }

            if (otherAnswerError) {
                otherAnswerError.hidden = !isChecked;
            }
        }
    }
    getFormFields() {
        var form = this.refs.self.els[0];
        var formData = {};

        for (var i = 0; i < form.elements.length; i++) {
            var element = form.elements[i];
            if (element.name && (element.type !== 'radio' || element.checked)) {
                formData[element.name] = element.value;
            }
        }

        return formData;
    }
    /**
     * @param {Function} [cb] callback called if children is valid
     * @param {Boolean} [isSilentValidation] the flag which indicates that this is silent validation
     */
    isChildsValid(cb, isSilentValidation = false) {
        var valid = true;

        this.eachChild(item => {
            if (item instanceof BasicInput && typeof item.validate === 'function') {
                if (!item.validate(isSilentValidation)) {
                    if (valid && item.setFocus && !isSilentValidation) {
                        item.setFocus();
                    }
                    valid = false;
                } else if (item.items && item.items.length) {
                    item.items.map(nestedItem => {
                        if (!nestedItem.validate(isSilentValidation)) {
                            if (valid && nestedItem.setFocus && !isSilentValidation) {
                                nestedItem.setFocus();
                            }
                            valid = false;
                        }
                    });
                }
            }
        });

        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
}
