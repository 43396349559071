import InputCheckbox from './InputCheckbox';

/**
 * @description Implementation
 */
export default class inputSubscriptionCheckbox extends InputCheckbox {
    init() {
        super.init();
        this.syncWithMaster();
        // reinit checkbox after submission of billing form
        this.eventBus().on('reinit.subscription.checkbox', 'handleChange');

        if (this.config.gated) {
            this.gatedCheckBoxVisible = false;
            this.addLinkListener();
        }
    }

    onRefresh() {
        super.onRefresh();
        this.syncWithMaster();
    }

    syncWithMaster() {
        const field = this.ref('field');
        let masterFieldEl;
        if (field) {
            const masterFieldId = field.data('subscribe-field-id');
            masterFieldEl = document.getElementById(masterFieldId);
        }

        if (masterFieldEl && masterFieldEl.checked) {
            this.check();
        } else if (masterFieldEl) {
            this.uncheck();
        }
    }

    /**
     * @description it is used for subscription checkbox out of the form
     * @param {el} el
     */
    handleChange(el) {
        super.handleChange();

        var field = el || this.ref('field');
        var data = {subscribeFieldId: field.data('subscribe-field-id'), value: this.getValue()};
        this.eventBus().emit('change.subscription.in.form', data);
    }

    toggleGatedCheckboxVisible() {
        const container = this.ref('self');
        if (this.gatedCheckBoxVisible) {
            container.removeClass('b-billing__checkbox--show-gated-checkbox');
        } else {
            container.addClass('b-billing__checkbox--show-gated-checkbox');
        }
        this.gatedCheckBoxVisible = !this.gatedCheckBoxVisible;
    }

    addLinkListener() {
        const container = this.ref('checkbox-gate')?.get();
        const link = container?.querySelector('a');

        if (link) {
            link.addEventListener('click', this.toggleGatedCheckboxVisible.bind(this));
            this.onDestroy(() => link.removeEventListener('click', this.toggleGatedCheckboxVisible));
        }
    }
}