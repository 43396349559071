/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @template T
 * @param {T} func - callback
 * @param {Number} wait - timeout duration
 * @param {Boolean} [immediate] - executing the debounce without waiting
 * @returns {T} - Debounced function
 */
export function debounce(func, wait, immediate) {
    let timeout;
    return function (...args) {
        const context = this;
        /**
         * execute later function
         */
        const later = function later() {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
