import BasicInput from 'widgets/forms/BasicInput';

/**
 * @description Simplified password field for login form without password validation
 */
export default class InputPasswordLogin extends BasicInput {
    togglePasswordVisibility() {
        const field = this.ref('field');
        const showBtn = this.ref('showBtn');

        if (field.attr('type') === 'password') {
            field.attr('type', 'text');
            showBtn.setText(this.config.Resources.btn.hide);
        } else {
            field.attr('type', 'password');
            showBtn.setText(this.config.Resources.btn.show);
        }
    }

    /**
    * used on confirmation page (registration form)
    */
    continueButton() {
        if (this.validate()) {
            this.emit('continue');
        }
    }
}