import { clickOutside } from 'widgets/toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';
import InputRadio from './InputRadio';

/**
 * @description Implementation
 */
export default class inputRadioShipping extends InputRadio {
    prefs() {
        return {
            classOpen: 'm-open',
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.initDropdown();

        this.radioFields = Array.from(this.ref('self').get().querySelectorAll('[data-ref="radio"]')).map(radioField => {
            return new RefElement([radioField]);
        });
    }

    updateShippingMethods(el) {
        var shippingMethodId = el.data('value');
        if (!shippingMethodId) {
            return;
        }

        this.setValue(shippingMethodId);
        this.update();
    }

    getCheckedEl() {
        var checkedEl;
        var el = (this.ref('self').get());

        if (el) {
            Array.from(el.querySelectorAll('[data-ref="field"]'))
                .forEach((el) => {
                    var tmpEl = (el);

                    if (tmpEl.checked) {
                        checkedEl = tmpEl;
                    }
                });
        }
        return checkedEl;
    }

    initDropdown() {
        const checkedEl = this.getCheckedEl();
        if (checkedEl) {
            this.updateDropdownButton(checkedEl);
        }
    }

    updateDropdownButton(radioElement) {
        this.has('dropdownButton', dropdownButton => {
            if (radioElement.labels && radioElement.labels.length > 0) {
                dropdownButton.get(0).innerHTML = radioElement.labels[0].innerHTML;
            }
        });
    }

    toggleDropdown() {
        if (this.opened) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    openDropdown() {
        this.ref('self').addClass(this.prefs().classOpen);
        this.clickOutsideExpose = clickOutside(this.ref('self'), () => {
            this.closeDropdown();
            return false;
        });
        this.onDestroy(this.clickOutsideExpose);
        this.opened = true;
    }

    closeDropdown() {
        this.ref('self').removeClass(this.prefs().classOpen);
        if (this.clickOutsideExpose) {
            this.clickOutsideExpose();
        }
        this.opened = false;
    }

    showMoreItems(showCount = 3) {
        var shownCount = 0;
        var shownAll = this.radioFields.length === 0;

        this.radioFields.map((address, index) => {
            if (address.hasAttr('hidden') && shownCount < showCount) {
                address.show();
                shownCount++;
                shownAll = index === this.radioFields.length - 1;
            }
        });

        return shownAll;
    }

    update(el) {
        this.radioFields.forEach(radio=>radio.els[0].classList.remove('choosed'));
        el.els[0].parentElement.classList.add('choosed');
        this.eventBus().emit('check.name.validation', this.currentStep);
        super.update();
        this.updateDropdownButton(el.get(0));
    }
}