import AjaxForm from 'widgets/forms/AjaxForm';
import { isGiftCardCode } from 'widgets/util/helpers';
import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @description Base button implementation
 */
export default class CouponForm extends AjaxForm {
    prefs() {
        return {
            msgEmptyCoupon: '',
            classesShow: 'show',
            ...super.prefs()
        };
    }
    isChildsValid() {
        if (!this.ref('couponCode').getValue() && this.prefs().msgEmptyCoupon) {
            this.ref('couponCode').setError(this.prefs().msgEmptyCoupon);
            return false;
        }
        return super.isChildsValid();
    }
    handleSubmit() {
        const codeInput = this.getFormFields().couponCode;

        if (!this.getGiftCardFormUrl() || !isGiftCardCode(codeInput)) {
            return super.handleSubmit();
        }

        this.handleGiftCardCodeSubmit();
    }
    /**
     * @param {Object} data submited data
     */
    onSubmitted(data) {
        if (data.error) {
            this.ref('couponCode')
                .setError(data.errorMessage);
            this.eventBus().emit('coupon.error');

        } else if (data.fields) {
            Object.entries(data.fields).forEach(([name, errorMsg]) => {
                this.ref(name).setError(errorMsg);
            });
            this.eventBus().emit('coupon.error');
        } else {
            if (!data.giftCertificates || !data.giftCertificates.items || !data.giftCertificates.items.length) {
                this.ref('couponCode').setValue('');
            }

            const cart = data.cart;

            if (cart && data.giftCertificates) {
                cart.giftCertificates = data.giftCertificates;
            }

            this.eventBus().emit('coupon.updated', cart || data);
        }
    }
    toggleForm() {
        this.ref('self').toggleClass(this.prefs().classesShow);
    }
    removeCoupon(removeButton) {
        this.removeButton = removeButton;
        this.ref('removeCouponModal')
            .showDialog({ couponCode: removeButton.attr('data-code') });
    }
    confirmedRemoveCoupon() {
        if (this.removeButton) {
            this.showProgressBar();
            submitFormJson(this.prefs().removeCouponLineItem, {
                couponCode: this.removeButton.attr('data-code'),
                uuid: this.removeButton.attr('data-uuid')
            }, 'GET')
                .then((response) => {
                    this.eventBus().emit('order.updated', response);
                })
                .finally(() => {
                    this.hideProgressBar();
                });
            this.removeButton = void 0;
        }
    }
    getGiftCardFormUrl() {
        return this.ref('self').attr('data-gift-card-action');
    }
    handleGiftCardCodeSubmit() {
        const valid = this.isChildsValid();

        if (!valid || this.submitting) {
            return;
        }

        this.submitting = true;

        this.showProgressBar();
        this.ref(this.prefs().submitButton).disable();
        this.ref(this.prefs().errorMessageLabel).hide();

        submitFormJson(
            this.getGiftCardFormUrl(),
            this.getFormFields(),
            'POST'
        )
            .then(this.onSubmitted.bind(this))
            .catch(this.onError.bind(this))
            .finally(this.afterSubmission.bind(this));
    }

    onError(data) {
        super.setError(Array.isArray(data.error) ? data.errorMsg.join('<br/>') : data.errorMsg);
    }
}