import AjaxForm from "../forms/AjaxForm";

export default class PasswordResetForm extends AjaxForm {
    init() {
        if (this.config.sitePreferences.RECAPTHCA_VERSION == 'v2') {
            this.captcha = grecaptcha.render(this.ref('g-recaptcha-reset').els[0], {
                'sitekey': this.config.sitePreferences.RECAPTHCA_SITE_KEY_V2
            });
        }
        
    }
    prefs() {
        return {
            statusModal: 'resetPasswordStatusModal',
            ...super.prefs()
        };
    }
    /**
     *
     * @param {object} data - response data
     */
    onSubmitted(data) {
        if (data.rateLimiterExceeded) {
            this.ref('rateLimiterError').els[0].innerHTML = this.config.Resources.msg.ratelimiterreset;
        }
        if (data.success) {
            this.emitWithoutContext('submitted', data);
        } else {
            super.onSubmitted(data);
        }
    }
    closeForm() {
        this.emit('closed');
    }
    handlePasswordResetFormSubmit() {
        if (this.ref('recaptcha-field').shown) {
            this.ref('recaptcha-field').ref('field').val(grecaptcha.getResponse(this.captcha));
        }

        if (this.submitting === false) {
            this.closeForm();
        } else {
            this.handleSubmit();
        }
    }
}