import WidgetList from 'widgets/widgetsList';
import viewtype from 'widgets/toolbox/viewtype';
import '../../../../../int_sub2_w/cartridge/client/js/sub2';
import '../../../../../int_gtm_w/cartridge/client/js/clientGTM';
import './contentsquare.js';
import $ from 'jquery';
window.$ = $;

window.BV = window.BV || {};

Object.defineProperty(window.BV, '_staticAssetRegistry', {
    value: {
        assets: {
            'jquery-bv@3.5.1': window.$,
        },
        requests: {},
        define: false
    },
    writable: true
});

if (window.assetsStaticURL) {
    // @ts-ignore
    // eslint-disable-next-line camelcase, no-undef
    __webpack_public_path__ = window.assetsStaticURL;
}

if (!PRODUCTION) {
    // eslint-disable-next-line no-console
    console.groupCollapsed('Project level widgets registration');
    // console.profile('Registration widgets');
}
/////////////////////////////////////////////////


/////////////////////////////////////////////////
if (!PRODUCTION) {
    //  console.profileEnd('Registration widgets');
    // eslint-disable-next-line no-console
    console.groupEnd();
}

viewtype.init({
    useWindowListeners: true,
    mobileWidth: 0,
    tabletWidth: 768,
    desktopWidth: 1200
});
WidgetList.run();
