
import { submitFormJson } from '../toolbox/ajax';
import { timeout } from '../toolbox/util';
import BasicForm from '../forms/BasicForm';

export default class AjaxForm extends BasicForm {
    prefs() {
        return {
            scrollToError: false,
            successMessage: 'successMessage',
            errorMessage: 'errorMessage',
            ...super.prefs()
        };
    }

    /**
     * @param {Object} el
     * @param {Object} event
     * @param {Object} formFieldsObj modified object with extra data from form
     */
    handleSubmit(el, event, formFieldsObj) {
        const valid = this.isChildsValid();

        if (!valid || this.submitting) {
            return;
        }

        this.submitting = true;

        this.showProgressBar();
        this.ref(this.prefs().submitButton).disable();
        this.ref(this.prefs().errorMessageLabel).hide();

        submitFormJson(
            this.getFormUrl(),
            formFieldsObj ? formFieldsObj : this.getFormFields(),
            this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
        )
            .then(this.onSubmitted.bind(this))
            .catch(this.onError.bind(this))
            .finally(this.afterSubmission.bind(this));
    }
    /**
     * @param {Object} data submited data
     */
    onSubmitted(data) {

        if (data.success && data.redirectUrl) {
            window.location.assign(data.redirectUrl);
        } else if (data.error) {
            this.onError(data);
        } else if (data.fields) {
            var doScroll = this.prefs().scrollToError;
            Object.entries(data.fields).forEach(([name, errorMsg]) => {
                this.ref(name).setError(errorMsg);
                if (doScroll) {
                    this.ref(name).ref('self').scrollIntoView();
                    doScroll = false;
                }
            });
        } else {
            if (data.success && this.prefs().clearIfSuccess) {
                this.clearFields();
            }
            timeout(() => { // lets hideProgress finishes first
                this.emit('submit', data);
                this.showInformalMessage(data);
            });
        }
    }
    afterSubmission() {
        if (this.prefs().clearIfSuccess) {
            this.data('handleSubmitBtn');
        } else {
            this.ref(this.prefs().submitButton).enable();
        }
        this.hideProgressBar();
        this.submitting = false;
    }
    showInformalMessage(data) {
        [this.prefs().successMessage, this.prefs().errorMessage]
            .map((messageKey, index) => this._toggleInformalMessages(messageKey, index, data.success));
    }
    onError(data) {
        this.setError(Array.isArray(data.error) ? data.error.join('<br/>') : data.error);
    }
    setError(msg, msg2) {
        this.ref(this.prefs().errorMessageLabel)
            .setText(msg2 || msg)
            .show();
    }
    clearError() {
        this.ref(this.prefs().errorMessageLabel)
            .setText('')
            .hide();
    }
    clearFields() {
        const formFields = this.data('getFormFields');
        if (formFields) {
            Object.keys(formFields).forEach(fieldWidgetID => {
                this.has(fieldWidgetID, fieldWidget => {
                    fieldWidget.data('reset');
                });
            });
        }
    }
    _toggleInformalMessages(messageKey, index, isSuccess) {
        this.has(messageKey, ref => ref.toggle(isSuccess ^ index));
    }
}