import viewtype from 'widgets/toolbox/viewtype';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import Widget from '../Widget';

/**
 * @description Manager for search page
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class SearchMgr extends Widget {
    prefs() {
        return {
            ...super.prefs()
        };
    }

    init() {
        this.isGridView = this.config.viewType;
        this.viewTypeChange();
        this.eventBus().on('search.updated', 'contentUpdate');
        this.eventBus().on('search.tabs.activate', 'countUpdate');
        this.eventBus().on('search.view.list', 'setListView');
        this.eventBus().on('search.view.grid', 'setGridView');
        this.eventBus().on('viewtype.change', 'viewTypeChange');
    }

    viewTypeChange() {
        this.isMobileView = viewtype.isMobileView();
        this.setupMobileCarousels();
    }

    contentUpdate(templateHTML = '') {
        this.has('topContent', topContent => this.render(void 0, void 0, topContent, templateHTML));
    }

    countUpdate(tabName = '') {
        const isProductTab = tabName === 'product-search-results';
        this.has('productCount', productCount => productCount[isProductTab ? 'show' : 'hide'](void 0, false));
        this.has('contentCount', contentCount => contentCount[isProductTab ? 'hide' : 'show'](void 0, false));
    }

    setListView() {
        this.isGridView = false;
        this.setupMobileCarousels();
        var sessionUpdate = getJSONByUrl(this.config.Constants.URL_SET_VIEW_TYPE, {setView: 'list'}).then((response) => {});
    }

    setGridView() {
        this.isGridView = true;
        this.setupMobileCarousels();
        var sessionUpdate = getJSONByUrl(this.config.Constants.URL_SET_VIEW_TYPE, {setView: 'grid'}).then((response) => {});
    }

    setupMobileCarousels() {
        if (this.isMobileView) {
            const itemsPerView = this.isGridView ? 2 : 1;
            var element = this.ref('html');
            if (this.isGridView) {
                document.getElementById('appendtoggleContent').innerHTML =
                '<div id="listview" class="list-view-toggleContent">' +
                '</div>' +
                '<div id="gridview" class="grid-view-toggleContent"></div>';
                var that = this;
                setTimeout(function(){
                    element.addClass('grid-alternative-view');
                    var sessionUpdate = getJSONByUrl(that.config.Constants.URL_SET_VIEW_TYPE, {setView: 'grid'}).then((response) => {});
                }, 10);
            } else {
                document.getElementById('appendtoggleContent').innerHTML = '';
            }
            this.eachChild(widget => {
                if (widget.carousel) {
                    widget.data('refresh', {
                        breakpoints: {
                            767: {
                                perView: itemsPerView
                            }
                        }
                    });
                }
            });
        }
    }
}