import Widget from '../Widget';
import { clickOutside } from 'widgets/toolbox/util';

/**
 * @description header search implementation
 */
export default class HeaderSearch extends Widget {
    init() {
        this.eventBus().on('header.search.opened', 'focusInput');
        this.eventBus().on('header.search.opened', 'initClickOutside');
        this.eventBus().on('header.search.closed', 'clearClickOutside');
        this.onDestroy(this.clearClickOutside);
    }

    initClickOutside() {
        this.clearClickOutside();
        this.exposeClickOutside = clickOutside(this.ref('self'), () => {
            this.clearClickOutside();
            this.eventBus().emit('header.search.close');
        });
    }

    clearClickOutside() {
        if (this.exposeClickOutside) {
            this.exposeClickOutside();
            this.exposeClickOutside = void 0;
        }
    }

    focusInput() {
        this.getById('simpleSearch', simpleSearch => simpleSearch.data('focus'));
    }
}