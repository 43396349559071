import Modal from '../global/Modal';

/**
 * @description Base ModalLink implementation
 * @param {typeof import('../global/Modal').default} modal Base widget for extending
 */
export default class PasswordResetModal extends Modal {
    show() {
        if (!this.shown) {
            this.ref(this.prefs().refContainer).show();
            this.shown = true;
        }
        return this;
    }
    hide() {
        if (this.shown) {
            this.ref(this.prefs().refContainer).hide();
            this.shown = false;
        }
        return this;
    }
    renderTemplate(templateData) {
        this.has(this.prefs().refContainer, container => this.render(void 0, templateData, container));
    }
}