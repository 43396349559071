import customerWishlist from 'widgets/toolbox/wishlist';
import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';
import { appendParamToURL } from 'widgets/toolbox/util';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import BaseButton from './Button';

/**
 * @description Add to wishlist button
 * @param {typeof import('../Widget').default} baseButton Base widget for extending
 */
export default class AddToWishlistButton extends BaseButton {
    prefs() {
        return {
            pid: '',
            addToWishlistUrl: '',
            checkStateEvent: '',
            wishlistType: '',
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.checkProductInlist();

        this.eventBus().on('wishlist.added', 'handleAdding');
        this.eventBus().on('wishlist.removed', 'handleRemoving');
        this.eventBus().on('wishlist.inited', 'checkProductInlist');
    }

    checkProductInlist(data) {
        const wishlist = data || customerWishlist.get();
        if (wishlist && wishlist.items) {
            const productID = this.getProductID();
            this.setState(wishlist.items.some((item) => {
                return productID && (item.pid === productID || productID.includes(item.pid));
            }));
        }
    }

    handleClick() {
        if (this.added) {
            this.removeItem();
        } else {
            this.addItem();
        }
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
    }

    addItem() {
        var addToWishlistURL = this.prefs().addToWishlistUrl;

        this.data('disable');
        submitFormJson(addToWishlistURL, {
            pid: this.getProductID()
        }).then(response => {
            if (response.success) {
                this.eventBus().emit('wishlist.added', {
                    pid: response.pid,
                    count: response.itemsCount,
                    productName: response.productName,
                    addToWishlistType: this.prefs().wishlistType,
                    analytics: response.analytics
                });
                customerWishlist.add(response.pid);
            } else {
                this.data('enable');
            }
        }).catch(() => {
            this.data('enable');
        });
    }

    removeItem() {
        var removeFromWishlistURL = this.prefs().removeFromWishlistUrl;
        var url = appendParamToURL(removeFromWishlistURL, 'pid', this.getProductID());

        this.data('disable');
        getJSONByUrl(url).then(response => {
            if (!response.error) {
                this.eventBus().emit('wishlist.removed', {
                    pid: response.removedProductID,
                    count: response.wishlist && response.wishlist.length,
                    productName: response.productName
                });
                if (response.wishlist) {
                    customerWishlist.set(response.wishlist);
                }
            } else {
                this.data('enable');
            }
        }).catch(() => {
            this.data('enable');
        });
    }

    /**
     * @param {{ pid: string, count: number; }} data
     */
    handleAdding(data) {
        if (data && data.pid === this.getProductID()) {
            this.setState();
        }
    }

    /**
     * @param {{ pid: string, count: number; }} data
     */
    handleRemoving(data) {
        if (data && data.pid === this.getProductID()) {
            this.setState(false);
        }
    }

    setState(added = true) {
        this.added = added;
        this.data('enable');
        this.data(added ? 'activate' : 'deactivate');
    }

    getProductID() {
        return this.data('pid') || this.prefs().pid;
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }
}