/* eslint-disable complexity */

import { scrollTo } from 'widgets/toolbox/scroll';
import { RefElement } from 'widgets/toolbox/RefElement';
import { validateEmail } from 'widgets/toolbox/loqateValidation';
import Widget from '../Widget';

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class BasicInput extends Widget {
    prefs() {
        return {
            skipValidation: false,
            classesErrorState: 'is-invalid',
            classesValidState: 'is-valid',
            classesDisabled: 'm-disabled',
            classesLocked: 'm-locked',
            classesWrapperErrorState: 'is-invalid',
            classesWrapperValidState: 'is-valid',
            missingError: this.ref('field').attr('data-missing-error') || this.config.Resources.msg.missingError,
            maxRangeError: this.ref('field').attr('data-max-range-error') || this.config.Resources.msg.stirng.rangeError,
            doScroll: true,
            ...super.prefs()
        };
    }
    init() {
        this.timeout = null; //timeout for key up event
        this.delay = 500; //delay for key up event

        this.initValue = this.getValue();
        if (!this.id && this.ref('field').attr('name')) {
            this.id = this.ref('field').attr('name');
        }
        this.disabled = this.ref('field').attr('disabled') === 'disabled';

        // check for autofilled email
        if (this.config.widget === 'inputEmail') {
            if (!!this.getValue()) {
                this.keyup();
            }
        }
    }
    handleBlur() {
        const trimmedValue = this.getValue().trim();
        this.setValue(trimmedValue, true);
        this.validate();
    }
    handleKeyUp() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout((self) => {
            self.validate();
        }, this.delay, this);

    }
    getValue() {
        return /** @type {string} */(this.ref('field').val());
    }
    getName() {
        return this.ref('field').attr('name');
    }
    focus() {
        var field = this.ref('field').get();
        if (field) {
            field.focus();
        }
    }
    setFocus() {
        if (this.prefs().doScroll) {
            scrollTo(this.ref('self').get());
        }
        this.focus();
    }
    blur() {
        var field = this.ref('field').get();
        if (field) {
            field.blur();
        }
    }
    setValue(newVal = '', silently = false) {
        this.ref('field').val(String(newVal));
        if (!silently) {
            this.update();
        }
    }
    update() {
        this.validate();
        this.emit('change', this);
    }
    keyup() {
        var self = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout( () => {
            self.emit('keyup', self);
        }, this.delay);
    }
    change() {
        this.emit('change', this);
    }
    clearError() {
        this.ref('field').removeClass(this.prefs().classesErrorState);
        this.ref('field').removeClass(this.prefs().classesValidState);
        this.ref('self').removeClass(this.prefs().classesWrapperErrorState);
        this.ref('self').removeClass(this.prefs().classesWrapperValidState);

        this.ref('errorFeedback').hide();
    }
    /**
     * @param {string} [error] error message
     */
    setError(error) {

        if (error) {
            this.ref('field').removeClass(this.prefs().classesValidState);
            this.ref('self').removeClass(this.prefs().classesWrapperValidState);

            this.ref('field').addClass(this.prefs().classesErrorState);
            this.ref('self').addClass(this.prefs().classesWrapperErrorState);

            this.ref('errorFeedback').setText(error).show();
        } else {
            this.ref('field').removeClass(this.prefs().classesErrorState);
            this.ref('self').removeClass(this.prefs().classesWrapperErrorState);

            this.ref('field').addClass(this.prefs().classesValidState);
            this.ref('self').addClass(this.prefs().classesWrapperValidState);

            this.ref('errorFeedback').hide();
        }
    }
    isValid() {
        // use browsers validation
        const field = this.ref('field');
        if (field.val().trim() === '') {
            this.firstChecked = false;
            delete this.prevResult;
            delete this.loqateCheckedValue;
        }
        if (field.get().dataset.loqateValidate === 'true' && this.storedValue && this.storedValue === field.val() && typeof this.prevResult === 'boolean') {
            return this.prevResult;
        }

        this.storedValue = field.val();

        if (this.firstChecked && window.SitePreferences.LOQATE_CONFIGS_ENABLED && field && field.get().dataset.loqateValidate === 'true') {
            validateEmail(this);
            return false;
        }
        var validity = field.getValidity();
        var { state, msg } = validity || { msg: '', state: /** @type {ValidityState} */({ valid: true }) };
        const maxLength = field.attr('max-length');
        var isMaxLengthReached = false;

        if (maxLength && (Number(maxLength) < field.val().length) && this.prefs().rangeError) {
            msg = this.prefs().maxRangeError;
            isMaxLengthReached = true;
        } else if (state.patternMismatch) {
            const minLength = field.attr('minlength') || field.attr('min-length');

            if (minLength && (Number(minLength) > field.val().length) && this.prefs().rangeError) {
                msg = this.prefs().rangeError;
            } else if (this.prefs().patternMismatch) {
                msg = this.prefs().patternMismatch;
            }
        } else if (state.typeMismatch && this.prefs().typeMismatch) {
            msg = this.prefs().typeMismatch;
        } else if (
            (state.rangeOverflow || state.rangeUnderflow || state.tooLong || state.tooShort) &&
            this.prefs().rangeError
        ) {
            msg = this.prefs().rangeError;
        } else if (state.valueMissing && this.prefs().missingError) {
            msg = this.prefs().missingError;
        }

        this.error = msg;
        if (state.valid && field.get().dataset.loqateValidate === 'true' && !this.firstChecked) {
            this.firstChecked = true;
            this.loqateValidate();
        }
        return state.valid && !isMaxLengthReached;
    }
    // /**
    //  * @private
    //  * @param {number} minLength minLength
    //  * @param {number} maxLength maxLength
    //  * @param {any} value value
    //  * @param {string} error error
    //  * @deprecated
    //  */
    // _doValidate(minLength, maxLength, value, error) {
    //     if (this.config.rangelengthtext && minLength && maxLength &&
    //         (value.length < minLength || value.length > maxLength)) {
    //         error = format((this.config.rangelengthtext + ''), String(minLength), String(maxLength));
    //     } else if (minLength && value.length < minLength) {
    //         error = format(String(this.config.minlengthtext), String(minLength));
    //     } else if (maxLength && value.length > maxLength) {
    //         error = format(String(this.config.maxlengthtext), String(maxLength));
    //     } else if (this.config.regEx && !(new RegExp(String(this.config.regEx), 'g')).test(value)) {
    //         error = String(this.config.parseError);
    //     }
    //     return error;
    // }

    validate(isSilentValidation = false) {
        const trimmedValue = this.getValue().trim();
        this.setValue(trimmedValue, true);

        if (typeof this.prevResult === 'boolean') {
            return this.prevResult;
        }
        if (!this.shown || this.disabled || this.prefs().skipValidation) {
            return true;
        }

        const valid = this.isValid();

        if (valid) {
            this.setError();
        } else if (typeof isSilentValidation !== 'boolean' || !isSilentValidation) {
            this.setError(this.error);
        }

        this.eventBus().emit('input.validated');

        return valid;
    }
    disable() {
        this.disabled = true;
        this.ref('field').disable();
        this.ref('self').addClass(this.prefs().classesDisabled);
        return this;
    }
    enable() {
        this.disabled = false;
        this.ref('field').enable();
        this.ref('self').removeClass(this.prefs().classesDisabled);
        return this;
    }
    lock() {
        this.locked = true;
        this.ref('field').attr('readonly', true);
        this.ref('self').addClass(this.prefs().classesLocked);
    }
    unlock() {
        this.locked = false;
        this.ref('field').attr('readonly', false);
        this.ref('self').removeClass(this.prefs().classesLocked);
    }
    isDisabled() {
        return this.disabled;
    }
    /**
     * @param {BasicInput} widgetToMatch cmp
     * @param {{[x: string]: string|undefined}} options to compare
     */
    setMatchCmp(widgetToMatch, options = {}) {
        this.widgetToMatch = widgetToMatch;
        this.widgetToMatchOpts = options;
    }
    skipSubmission() {
        return false;
    }
    getMinLength() {
        const field = this.ref('field');
        return field.attr('minlength') || field.attr('min-length');
    }
    getMaxLength() {
        const field = this.ref('field');
        return field.attr('maxlength') || field.attr('max-length');
    }
    reset() {
        this.setValue(this.ref('field').attr('value'), true);
    }
    loqateValidate() {
        const field = this.ref('field');
        if (field.get().dataset.loqateValidate === 'true' && this.storedValue && this.storedValue === field.val() && typeof this.prevResult === 'boolean') {
            if (!this.prevResult) {
                this.error = window.Resources.msg.email.invalidError;
                this.setError(this.error);
                this.eventBus().emit('registration.validate');
            } else {
                this.error = '';
                this.setError();
                this.eventBus().emit('registration.validate', true);
            }
        }
        this.storedValue = field.val();
        if (window.SitePreferences.LOQATE_CONFIGS_ENABLED && field && field.get().dataset.loqateValidate === 'true') {
            validateEmail(this);
        }
    }
    cleanUpTargetWidgetErrors() {
        const field = this.ref('field').get();
        const cleanupTarget = field.dataset && field.dataset.cleanupTarget;

        if (cleanupTarget) {
            const targetWidget = document.querySelector(`[data-widget="${cleanupTarget}"]`);
            if (targetWidget) {
                targetWidget.querySelectorAll('.invalid-feedback').forEach(e => new RefElement([e]).hide());
            }
        }
    }
}