import InfiniteScrollLoadedContent from '../global/InfiniteScrollLoadedContent';
import { submitFormJson } from 'widgets/toolbox/ajax';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';

export default class PaymentMgr extends InfiniteScrollLoadedContent {
    setDefault(button) {
        this.showProgressBar();

        submitFormJson(button.attr('href'))
            .then((response) => {
                this.renderItems(response.paymentInstruments);
            })
            .finally(() => {
                this.hideProgressBar();
            });
    }

    removeCard(removeButton) {
        this.removeButton = removeButton;
        this.ref('deletePaymentModal')
            .showDialog({
                creditCardType: removeButton.attr('data-card-type'),
                maskedCreditCardNumber: removeButton.attr('data-card-number'),
                removeUrl: removeButton.attr('data-url')
            });
    }

    confirmedRemovePayment() {
        if (this.removeButton) {
            this.showProgressBar();

            submitFormJson(this.removeButton.attr('data-url'), {}, 'GET')
                .then((response) => {
                    this.renderItems(response.paymentInstruments);
                })
                .finally(() => {
                    this.hideProgressBar();
                });
        }
    }

    /**
     * @param {object[]} items
     */
    renderItems(items) {
        this.render('template', {
            paymentInstruments: items
        });
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }
}