import Button from 'widgets/global/Button';
import Widget from 'widgets/Widget';

const sizeGuideSelector = '.b-product__size-chart';
const sizeGuideTabId = 'size-guide';

/**
 * @description Base Tabs implementation
 */
export default class Tabs extends Widget {
    prefs() {
        return {
            activePanel: '',
            activeFirst: false,
            collapsible: false,
            ...super.prefs()
        };
    }
    init() {
        super.init();
        const hashValue = window.location.hash.substr(1);

        /**
         * @type {string[]}
         */
        this.panelNames = [];

        this.eachChild(childCmp => {
            if (childCmp instanceof Button && this.panelNames) {
                this.panelNames.push(String(childCmp.config.panelName));
            }
        });

        if (this.prefs().activePanel) {
            this.activatePanel(this.prefs().activePanel);
        } else if (this.prefs().useHash && this.has(hashValue)) {
            this.activatePanel(hashValue);
        } else if (this.prefs().activeFirst && this.panelNames.length) {
            this.activatePanel(this.panelNames[0]);
        }
    }
    /**
     * @param {import('widgets/Widget').default} clickedButton widget
     */
    handleTabClick(clickedButton) {
        const panelName = String(clickedButton.config.panelName);
        if (this.prefs().collapsible && clickedButton.active) {
            this.closePanel(panelName);
        } else {
            this.activatePanel(panelName);
        }
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });

    }
    /**
     * @param {string} panelName name of panel
     */
    activatePanel(panelName) {
        if (this.panelNames) {
            this.panelNames.forEach(id => {
                if (id === panelName) {
                    this.activePanel = panelName;
                    this.openPanel(id);
                } else {
                    this.closePanel(id);
                }
            });
            if (this.prefs().widgetPrefix) {
                this.eventBus().emit(this.prefs().widgetPrefix + '.activate', panelName);
            }
        }
        if (this.id === 'productTabs') {
            this.eventBus().emit('ua-event.pdp-open-tab.click');
        }
    }
    /**
     * @param {string} panelName name of panel
     */
    openPanel(panelName) {
        setTimeout(() => {
            this.has(panelName, panel => panel.activate());
            this.has('button-' + panelName, button => button.activate());
        }, 2);
    }
    /**
     * @param {string} panelName name of panel
     */
    closePanel(panelName) {
        this.has(panelName, panel => panel.deactivate());
        this.has('button-' + panelName, button => button.deactivate());
    }
    updateTabs(data) {
        const sizeGuideLink = this.ref('tabs').els[0].querySelector(sizeGuideSelector);
        this.updateSizeGuide(data.tabs, sizeGuideLink);

        this.ref('tabs').empty();
        this.render(data.template || 'template', { tabs: data.tabs }, this.ref('tabs'));
    }
    updateSizeGuide(tabs, sizeGuideLink) {
        if (sizeGuideLink) {
            for (let i = 0; i < tabs.length; i++) {
                if (tabs[i].id === sizeGuideTabId) {
                    tabs[i].markup += sizeGuideLink.outerHTML;
                    break;
                }
            }
        }
    }
    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} button
     */
    handleParentEvent(button) {
        this.emit('parent', button);
    }

    handleReviewClick(button) {
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
        window.document.getElementById('reviews').scrollIntoView();
    }

    handleSizeGuideTabClick(clickedButton) {
        const panelName = String(clickedButton.config.panelName);

        // size guide redesign navigation and title update
        const buttonEl = clickedButton.refs.self.els[0];
        const cmTabButton = document.querySelector('.b-tabs-redesign__measurements-btn__cm');
        const inchesTabButton = document.querySelector('.b-tabs-redesign__measurements-btn__inches');
        const cmTab = buttonEl.classList.contains('b-tabs-redesign__measurements-btn__cm');
        const measurementTitle = document.querySelector('.b-tabs-redesign__title');

        if (this.prefs().collapsible && clickedButton.active) {
            this.closePanel(panelName);
        } else {
            this.activatePanel(panelName);
        }

        if (cmTab) {
            cmTabButton.classList.add('active');
            inchesTabButton.classList.remove('active');
            measurementTitle.textContent = 'Body measurements (cm)';
        } else {
            inchesTabButton.classList.add('active');
            cmTabButton?.classList.remove('active');
            measurementTitle.textContent = 'Body measurements (inches)';
        }
    }
}