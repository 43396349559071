import Widget from '../Widget';

/**
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class LazyLoad extends Widget {
    constructor(el, config = {}) {
        super(el, config);
        this.highestScroll = 0;
        this.preloadThreshold = 200;
    }
    init () {
        super.init();
        if ('IntersectionObserver' in window) {
            this.lazyImageObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.target && entry.target.getClientRects().length) {
                        const lazyImage = entry.target;
                        lazyImage.removeAttribute('loading');
                        this.lazyImageObserver.unobserve(lazyImage);
                    }
                });
            }, { rootMargin: `${this.preloadThreshold}px` });
            this.observeImages();
            window.addEventListener('scroll', ev => this.reobserveOnScroll(ev));
        }
    }
    observeImages() {
        /** @type {any[]} */
        const lazyImages = [].slice.call(document.querySelectorAll('img[loading="lazy"]'));
        lazyImages.forEach((lazyImage) => {
            this.lazyImageObserver.observe(lazyImage);
        });
    }
    reobserveOnScroll() {
        if (scrollY > (this.highestScroll + this.preloadThreshold)) {
            this.highestScroll = scrollY;
            this.observeImages();
        }
    }
}