import { getData } from './util';
import { elementOffset, scrollIntoView } from 'widgets/toolbox/scroll';

let HIDDEN_CLASS = 'hide';

/**
 * @param {string} newClass
 */
export function setHiddenClass(newClass) {
    HIDDEN_CLASS = newClass;
}

/**
 *
 * @param {string} s
 */
function toSnakeCase(s) {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '-' + y.toLowerCase()).replace(/^_/, '');
}

/**
 *
 * @param {object} elements
 */
function getTransitionDuration(elements) {
    var duration = 0;
    try {
        duration = parseFloat(getComputedStyle(elements[0]).transitionDuration) * 1000;
    } catch {
        //
    }
    return duration;
}

/**
 * jQuery like wrapper for simple access to DOM
 */
export class RefElement {
    /**
     * @param {HTMLElement[]} els array of elements
     */
    constructor(els) {
        this.els = els;
        this.transitionDuration = null;
    }
    get length() {
        return this.els.length;
    }
    /**
     * @param {string} name
     * @param {any} [value]
     * @param {any} [value2]     *
     */
    // eslint-disable-next-line no-unused-vars
    data(name, value, value2) {
        const attrName = 'data-' + toSnakeCase(name);
        if (typeof value === 'undefined') {
            if (this.hasAttr(attrName)) {
                return getData(this.attr(attrName));
            }
            return void 0;
        }
        return this.attr(attrName, value);
    }
    /**
     * @param {string} [value] value to set
     */
    val(value) {
        if (typeof value === 'undefined') {
            return this.els.map(el => /** @type {HTMLInputElement} */(el).value).join('');
        } else if (typeof value === 'string' || typeof value === 'boolean') {
            this.els.forEach(el => /** @type {HTMLInputElement} */(el).value = value);
        }
        return this;
    }
    getValidity() {
        const element = this.els[0];

        if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement) {
            return {
                state: element.validity,
                msg: element.validationMessage
            };
        }
    }
    /**
     * @param {string} str template to append
     */
    append(str) {
        this.els.forEach(el => {
            const tempEl = document.createElement('div');
            tempEl.innerHTML = str;
            Array.from(tempEl.childNodes).forEach(child => {
                el.appendChild(child);
            });
            tempEl.innerHTML = '';
        });
    }
    /**
     * @param {string} str template to append
     */
    prepend(str) {
        this.els.forEach(el => {
            const tempEl = document.createElement('div');
            tempEl.innerHTML = str;
            Array.from(tempEl.childNodes).forEach(child => {
                el.prepend(child);
            });
            tempEl.innerHTML = '';
        });
    }
    /**
     *
     * @param {string} attrName attribute name
     * @param {string|false|true|undefined} [attrValue] attribute value (null to remove attribure)
     */
    attr(attrName, attrValue) {
        if (attrValue === false) {
            this.els.forEach(el => el.removeAttribute(attrName));
        } else if (attrValue === true) {
            this.els.forEach(el => el.setAttribute(attrName, attrName));
        } else if (attrValue !== undefined) { // eslint-disable-line
            this.els.forEach(el => el.setAttribute(attrName, attrValue));
        } else {
            return this.els.map(el => el.getAttribute(attrName)).join('');
        }

        return this;
    }
    /**
     * @param {string} attrName name of attribute
     */
    hasAttr(attrName) {
        return this.els.some(el => el.hasAttribute(attrName));
    }
    /**
     *
     * @param {string} propName attribute name
     * @param {string|number|false|true|undefined} [propValue] attribute value (null to remove attribure)
     */
    prop(propName, propValue) {
        if (typeof propValue === 'undefined') {
            return /** @type {HTMLInputElement} */(this.els[0])[propName];
        }
        this.els.forEach(el => /** @type {HTMLInputElement} */(el)[propName] = propValue);
    }
    get(idx = 0) {
        if (this.els[idx]) {
            return this.els[idx];
        }
        return null;
    }
    empty() {
        this.els.forEach(el => el.textContent = '');
        return this;
    }
    remove() {
        this.els.forEach(el => el.parentNode && el.parentNode.removeChild(el));
        return this;
    }
    disable() {
        this.attr('disabled', true);
        this.addClass('m-disabled');
        return this;
    }
    enable() {
        this.removeClass('m-disabled');
        this.attr('disabled', false);
        return this;
    }

    isDisabled() {
        return this.attr('disabled') === 'disabled';
    }

    /**
     * @param {string} value value to set (illegal characters will be escaped)
     */
    setText(value) {
        this.els.forEach(el => el.textContent = value);
        return this;
    }
    getText() {
        return this.els.map(el => el.textContent).join();
    }
    /**
     * @param {Function} [cb] callback when element is fully hidden
     * @param {boolean} [animate] use animate for showing
     */
    hide(cb, animate = true) {
        if (!this.hasAttr('hidden')) {
            this.addClass(HIDDEN_CLASS);
            if (animate) {
                if (this.transitionDuration === null) {
                    this.transitionDuration = getTransitionDuration(this.els);
                }
                if (this.transitionDuration > 0) {
                    this.els.forEach(el => el.style.height = el.scrollHeight + 'px');
                    this.reflow();
                    this.els.forEach(el => el.style.height = '0px');
                }

                // use setTimeout to be able animate hidden
                setTimeout(() => {
                    this.attr('hidden', true);
                    this.attr('aria-hidden', 'true');
                    this.els.forEach(el => {
                        el.style.height = '';
                    });
                    if (typeof cb === 'function') {
                        cb();
                    }
                }, this.transitionDuration);
            } else {
                this.attr('hidden', true);
                this.attr('aria-hidden', 'true');
            }
        }

        return this;
    }
    /**
     * @param {Function} [cb] callback when element is fully shown
     * @param {boolean} [animate] use animate for showing
     */
    show(cb, animate = true) {
        if (this.hasAttr('hidden')) {
            this.attr('hidden', false);
            this.attr('aria-hidden', 'false');
            this.removeClass(HIDDEN_CLASS);

            if (animate) {
                if (this.transitionDuration === null) {
                    this.transitionDuration = getTransitionDuration(this.els);
                }
                if (this.transitionDuration > 0) {
                    this.els.forEach(el => el.style.height = '0px');
                    this.reflow();
                    this.els.forEach(el => el.style.height = el.scrollHeight + 'px');
                }

                setTimeout(() => {
                    this.els.forEach(el => {
                        el.style.height = '';
                    });
                    if (typeof cb === 'function') {
                        cb();
                    }
                }, this.transitionDuration);
            }
        }

        return this;
    }
    /**
     * @param {boolean} [initialState]  true - show else false hide
     * @param {Function} [cb] callback when element is fully shown
     */
    toggle(initialState, cb) {
        const state = typeof initialState !== 'undefined' ? initialState : this.hasClass(HIDDEN_CLASS);

        this[state ? 'show' : 'hide'](cb);
        return this;
    }
    /**
     * @param {string} name name of class
     * @param {boolean|undefined} state true to show/false to hide/undefined to auto
     */
    toggleClass(name, state) {
        if (state === void 0) {
            if (this.hasClass(name)) {
                this.removeClass(name);
            } else {
                this.addClass(name);
            }
        } else if (state) {
            this.addClass(name);
        } else {
            this.removeClass(name);
        }
        return this;
    }
    /**
     * @param {string[]|string} classNames array or string of classnames
     */
    addClass(classNames) {
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }
        classNames.forEach(className => {
            this.els.forEach(el => {
                if (!this.hasClass(className)) {
                    el.classList.add(className);
                }
            });
        });
        return this;
    }
    /**
     * @param {string|string[]} classNames array or string of classnames
     */
    removeClass(classNames) {
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }
        classNames.forEach(className => {
            this.els.forEach(el => {
                if (this.hasClass(className)) {
                    el.classList.remove(className);
                }
            });
        });
        return this;
    }
    /**
     * @param {string} className name of class to check
     */
    hasClass(className) {
        return this.els.every(el => el.classList.contains(className));
    }
    /**
     * @description Specifies the position of the element in the window
     * @returns {Object} object with top and left position in px
     */
    offset() {
        return elementOffset(this.els[0]);
    }

    /**
     * @param {string} event
     * @param {string|function} selector
     * @param {function} [callback]
     */
    on(event, selector, callback) {
        this.els.every(element => {
            if (typeof selector === 'function') {
                element.addEventListener(event, event => selector(event));
            } else if (typeof callback === 'function') {
                const nodeList = Array.from(element.querySelectorAll(selector));
                nodeList.every(element => element.addEventListener(event, event => callback(event)));
            }
        });
    }

    reflow() {
        return this.els.every(el => el.offsetHeight);
    }

    scrollIntoView(scrollParent = false) {
        var el = this.els[0];
        if (el && scrollParent && el.parentElement) {
            el = el.parentElement;
        }
        scrollIntoView(el);
    }
}
