import { getContentByUrl, submitFormJson } from '../toolbox/ajax';
import Modal from './Modal';
/**
 * @description Cookie acceptance component (with SFRA supported backend)
 */
export default class ConsentTracking extends Modal {
    prefs() {
        return {
            contentUrl: '',
            acceptUrl: '',
            rejectUrl: '',
            ...super.prefs()
        };
    }

    init() {

        super.init();
        this.loadContent();
    }
    /**
     * Loading default content asset tracking-hint
     */
    loadContent() {
        getContentByUrl(this.prefs().contentUrl).then(content => this.showModal({ content }));
    }
    /**
     * accept cookies
     */
    accept() {
        submitFormJson(this.prefs().acceptUrl, void 0, 'GET').finally(() => this.closeModal());
    }
    /**
     * reject cookies
     */
    reject() {
        submitFormJson(this.prefs().rejectUrl, void 0, 'GET').finally(() => this.closeModal());
    }
}