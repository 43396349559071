
import { timeout, changeSubmitButtonState } from '../toolbox/util';
import Widget from '../Widget';
import BasicInput from 'widgets/forms/BasicInput';

export default class BasicForm extends Widget {
    prefs() {
        return {
            submitButton: 'submitButton',
            errorMessageLabel: 'errorMessageLabel',
            SUBMITTING_TIMEOUT: 5000,
            ...super.prefs()
        };
    }
    showProgressBar() {
        // todo: implement
    }
    hideProgressBar() {
        // todo: implement
    }
    getSubmitButtonName() {
        return this.submitButtonName;
    }
    init() {
        this.eventBus().on('updatecczipbutton.field', 'validatecczipbutton');
        this.submitButtonName = null;
        super.init();
        //this.eventMgr('FormSubmitter.submit.' + this.id, this.submit);

        if (this.config.match) {
            Object.entries(this.config.match).forEach(([key, item]) => {
                const wdgt1 = this.ref(key);
                const wdgt2 = this.ref(item.field);
                wdgt1.data('setMatchCmp', wdgt2, item);
            });
        }

        if (this.prefs().initSubmitState) {
            this.handleSubmitBtn();
        }
    }
    /**
     * @param {HTMLInputElement} el button element
     */
    saveSubmitButton(el) {
        this.submitButtonName = el.name;
    }
    submit() {
        /**
         * @type {HTMLElement|undefined}
         */
        var elem = this.ref(this.prefs().submitButton).get();

        if (elem) {
            elem.click();
        }
    }
    /**
     * @param {import('widgets/toolbox/RefElement').RefElement|import('widgets/Widget').default} el source of event
     * @param {Event|undefined} event event instance if DOM event
     */
    handleSubmit(el, event) {
        const valid = this.isChildsValid();

        if (!valid || this.submitting) {
            if (event && event instanceof Event) {
                event.preventDefault();
                return;
            }
        }

        this.submitting = true;

        this.onDestroy(timeout(() => this.submitting = false, Number(this.config.SUBMITTING_TIMEOUT)));

        this.emit('submit');
    }

    getFormFields() {
        /**
         * @type {{[x: string]: string}}
         */
        var fields = {};
        this.eachChild(widget => {
            if (widget instanceof BasicInput && !(widget.skipSubmission && widget.skipSubmission())) {
                const name = widget.getName && widget.getName();

                if (name) {
                    fields[name] = widget.getValue();
                }
            }
        });
        return fields;
    }
    /**
     * @param {Function} [cb] callback called if children is valid
     * @param {Boolean} [isSilentValidation] the flag which indicates that this is silent validation
     */
    isChildsValid(cb, isSilentValidation = false) {
        var valid = true;

        this.eachChild(item => {
            if (item instanceof BasicInput && typeof item.validate === 'function') {
                if (!item.validate(isSilentValidation)) {
                    if (valid && item.setFocus && !isSilentValidation) {
                        item.setFocus();
                    }
                    valid = false;
                }
            }
        });

        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
    validate() {
        return this.isChildsValid();
    }
    isValid() {
        var valid = true;

        this.eachChild(itemCmp => {
            if (itemCmp instanceof BasicInput && typeof itemCmp.isValid === 'function' && !itemCmp.isValid()) {
                valid = false;
                return false;
            }
        });
        return valid;
    }
    setFocus() {
    }
    getFormUrl() {
        return this.ref('self').attr('action');
    }
    /**
     * Disable submit button if form is invalid.
     */
    handleSubmitBtn() {
        changeSubmitButtonState(this.ref(this.prefs().submitButton), this.isValid());
    }

    validatecczipbutton() {
        if (this.ref(this.prefs().submitButton).els[0] && this.ref(this.prefs().submitButton).els[0].getAttribute('data-cczipbutton') === 'true') {
            this.handleSubmitBtn();
        }
    }
}