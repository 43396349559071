import Widget from '../Widget';
import { checkIfCookiesEnabled } from '../toolbox/cookie';

/**
 * @description Cookies notice implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class CookiesNotice extends Widget {
    init() {
        if (!checkIfCookiesEnabled()) {
            this.ref('self').show();
        }
    }
}