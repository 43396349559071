
import { getCookie, setCookie } from 'widgets/toolbox/cookie';
import Widget from '../Widget';


/**
 * @description Base dropdown implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class CountryBanner extends Widget {
    prefs() {
        return {
            ...super.prefs(),
            cookieTimeout: this.config.sitePreferences.GLOBALE_COOKIE_TIMEOUT,
            geEnabled: this.config.sitePreferences.geEnabled
        };
    }

    init() {
        const that = this;

        window.document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                if (!getCookie('country-banner-selected') && that.isBannerShown()) {
                    that.ref('currentCountry').setText(that.ref('currentCountryBanner').data('country-name'));
                    that.ref('currentCountryBanner').show();
                } else {
                    that.ref('currentCountryBanner').remove();
                }

                var cookieTimeout = that.prefs().cookieTimeout;
                if (that.prefs().geEnabled) {
                    window.onGlobaleReady = window.onGlobaleReady || [];
                    window.onGlobaleReady.push(function() {
                        window.gle('OnShippingSwitcherClosed', function (e) {
                            setCookie('country-popup-shown', 'true', cookieTimeout ? cookieTimeout / (60 * 24) : 1);
                            that.prefs().countrySwitcherShown = true;
                        });
                        if (that.isBannerShown() && that.isSelectorRequired() && (window.document.querySelector('.l-homepage') || window.document.querySelector('.l-search'))) {
                            if (document.querySelector('.CustomWelcomePopup')) {
                                var closeBtn = document.querySelector('.CustomWelcomePopup').querySelector('.glClose');
                                closeBtn.addEventListener('click', function() {
                                    gle('ShippingSwitcher', 'show');
                                    setCookie('country-popup-shown', 'true', cookieTimeout ? cookieTimeout / (60 * 24) : 1);
                                });
                            } else {
                                if(that.prefs().geEnabled) {
                                    gle('ShippingSwitcher', 'show');
                                }
                            }
                        }
                    });
                }
            }
        };

    }

    closeLocationBanner() {
        setCookie('country-banner-selected', 'true');
        this.ref('currentCountryBanner').hide();
    }

    isBannerShown() {
        const geoCountry = this.ref('currentCountryBanner').data('country');

        if (geoCountry == 'GB' || geoCountry == 'IM' || geoCountry == 'GG' || geoCountry == 'JE') {
            return false;
        } else if (this.ref('currentCountryBanner').data('site') === 'hush-UK' && geoCountry !== 'GB') {
            return true;
        } else if (window.GlobalE) {
            return window.GlobalE.Country !== geoCountry;
        }
        return (geoCountry !== 'GB' || geoCountry !== 'IM' || geoCountry !== 'GG' || geoCountry !== 'JE');
    }

    isSelectorRequired() {
        return !getCookie('country-popup-shown');
    }

    switchCountry(ref) {
        if (!window.GlobalE) {
            window.location.href = this.ref('currentCountryBanner').data('href');
        } else {
            if (this.prefs().geEnabled) {
                gle('ShippingSwitcher', 'show');
            }
        }
    }

}