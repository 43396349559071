import { report } from './util';
import eventBus from 'widgets/toolbox/eventBus';

const gtmPreSnippet = function(gtmData, filtersApplied) {
    const pageMetaData = {
        event: 'pageMetaData',
        page: {
            ...({...gtmData.categories, filtersApplied: filtersApplied || ''} || {}),
            country: gtmData.country,
            currency: 'GBP',
            language: gtmData.language,
            type: gtmData.type,
            visitorType: gtmData.visitorType,
            site: gtmData.site,
            responseCode: gtmData.responseCode,
            meta: gtmData.meta
        },
        previousPage: !gtmData.previousPage ? {} : {
            ...(gtmData.previousPage.categories || {}),
            country: gtmData.previousPage.country,
            currency: 'GBP',
            language: gtmData.previousPage.language,
            type: gtmData.previousPage.type,
            visitorType: gtmData.previousPage.visitorType,
            site: gtmData.previousPage.site,
            responseCode: gtmData.previousPage.responseCode,
            meta: gtmData.previousPage.meta
        },
        customer: gtmData.customer,
        cart: {
            ...gtmData.cart
        }
    };

    report(pageMetaData);
};

eventBus.on('spa.page.meta.data', ({gtmData, filtersApplied}) => {
    gtmPreSnippet(gtmData, filtersApplied);
});

export default gtmPreSnippet;
