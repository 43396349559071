import Widget from '../Widget';
import viewtype from 'widgets/toolbox/viewtype';

/**
 * @description Store item implementation
 */
export default class Store extends Widget {
    prefs() {
        return {
            tooltipHtml: '',
            ...super.prefs()
        };
    }

    init() {
        this.active = false;
        if (!this.prefs().tooltipHtml) {
            this.config.tooltipHtml = this.getTooltipHTML();
        }
        this.eventBus().on('store.deactivate.global', 'deactivate');
        this.eventBus().on('on.select.store', 'onSelectStore');
        this.eventBus().on('toggle.store.hours', 'toggleStoreHours');
    }

    activate() {
        this.has('input', input => {
            input.prop('checked', true);
            this.active = true;
        });
    }

    deactivate() {
        this.has('input', input => {
            input.prop('checked', false);
            this.active = false;
        });
    }

    inputChange() {
        this.has('input', input => {
            if (input.prop('checked')) {
                this.eventBus().emit('store.activate', {
                    id: input.els[0].value
                });
            }
        });
    }

    getTooltipHTML() {
        const detailsElement = this.ref('details').get();
        if (detailsElement) {
            return '<div class="b-store b-store--tooltip">' + detailsElement.innerHTML.replace('data-event-click="toggleStoreHours"', '').replace('data-event-click="inputChange"', '') + '</div>';
        }
        return '';
    }

    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} el
     */
    selectStore(el) {
        this.eventBus().emit('on.select.store', el);
    }

    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} el
     */
    onSelectStore(el) {
        this.has('selectButton', selectButton => {
            const isSelected = el && el.data('store-id') === selectButton.data('store-id');
            selectButton.toggleClass(this.prefs().classesActive, isSelected);
            this.ref('self').toggleClass(this.prefs().classesActive, isSelected);
        });
    }

    toggleStoreHours() {
        if (!viewtype.isMobileView()) {
            var storeHoursTitle = this.get().querySelector('.b-store__hours__title');
            var storeHoursContent = this.get().querySelector('.b-store__hours__content');

            if (storeHoursTitle.classList.contains('m-active')) {
                storeHoursTitle.classList.remove('m-active');
                storeHoursContent.classList.remove('open');
            } else {
                storeHoursTitle.classList.add('m-active');
                storeHoursContent.classList.add('open');
            }
        }
    }
}