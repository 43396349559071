/* eslint-disable complexity */
/* eslint-disable no-use-before-define, jsdoc/require-param-description*/
'use strict';

import WidgetDeferred from './widgetDeferred';
import { log, getData } from './toolbox/util';
//import eventMgr from './EventMgr';
import { RefElement } from 'widgets/toolbox/RefElement';
import Widget from 'widgets/Widget';

const WIDGET_PROP_NAME = '@@_widget_instance_@@';
const WIDGET_PROMISE = '@@_widget_promise_@@';
const WIDGET_DISPOSABLE_VALUES = '@@_widget_events_disposable_@@';
const WIDGET_PREFIX = 'data-event-';

class RootWidget extends Widget { }

if (!document.head.parentElement) {
    throw Error('No document');
}

var rootWidget = new RootWidget(document.head.parentElement, {});
const widgetsInitMetric = Object.create(null);

class WidgetMgr {
    constructor() {
        /**
         * @type {{[key : string] : [() => typeof Widget, string] | undefined}}
         */
        this.widgets = Object.create(null);
        this.timeOfEvaluate = Date.now();
        this.widgets.widget = [() => Widget, ''];
        this.getting = false;
    }
    run() {
        this.timeOfRun = Date.now();
        // Init widgets once DOM is ready
        this.init();
    }
    getAll() {
        return this.widgets;
    }
    /**
     * @template {Widget} T
     * @param {string} name Name of registered widget (used as data-widget="<name>" in DOM)
     * @returns T
     */
    get(name) {
        this.getting = true;
        return Promise.resolve().then(() => {
            const widget = this.widgets[name];
            if (!widget || !widget.length) {
                return;
            }
            if (widget[0] instanceof WidgetDeferred) {
                return widget[0].load();
            }
            return widget[0];
        });
    }
    /**
     * @param {string} name
     * @param {[() => typeof Widget, string]} widget
     */
    set(name, widget) {
        return this.widgets[name] = widget;
    }
    /**
     *
     * @param {string} name name of widget (will be used in `data-widget="name"`)
     * @param {Function} widgetConstructor function mixin that returns class
     * @param {string} [baseWidget] base widget to extend
     */
    register(name, widgetConstructor, baseWidget) {
        if (!PRODUCTION) {
            if (this.getting) {
                log.warn('register widget after getting');
            }
        }


        if (this.widgets[name] && baseWidget === name) {
            const [superWidgetConstructor, superBaseWidget] = this.widgets[name];
            this.set(name, [(base) => widgetConstructor(superWidgetConstructor(base)), superBaseWidget]);
        } else {
            this.set(name, [widgetConstructor, baseWidget]);
        }
    }
    restartWidgets() {
        if (document.head.parentElement) {
            detachElement(document.head.parentElement);
            attachElements(document.head.parentElement);
        }
    }
    init() {
        this.timeOfInit = Date.now();

        if (!document.head.parentElement) {
            throw Error('No document');
        }

        const observer = new MutationObserver(mutations => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes') {
                    const target = /** @type {HTMLElement} */ (mutation.target);
                    if (target.getAttribute('data-initialized') !== '1') {
                        const widget = target[WIDGET_PROP_NAME];
                        if (widget) {
                            widget.onRefresh();
                        }
                        target.setAttribute('data-initialized', '1');
                    }
                } else {
                    const { addedNodes, removedNodes } = mutation;
                    removedNodes.forEach(removedNode => {
                        if (removedNode.nodeType === removedNode.ELEMENT_NODE) {
                            detachElement(/** @type {HTMLElement} */(removedNode));
                        }
                    });
                    addedNodes.forEach(addedNode => {
                        if (addedNode.nodeType === addedNode.ELEMENT_NODE && document.body.contains(addedNode)) {
                            attachElements(/** @type {HTMLElement} */(addedNode));
                        }
                    });
                }
            })
        });


        observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['data-initialized'],
            characterData: false,
            childList: true,
            subtree: true
        });

        attachElements(document.head.parentElement)
            .then(() => {

                if (!PRODUCTION) {
                    const total = Date.now() - window.headInitTime;
                    const timeInitWidgets = Date.now() - this.timeOfInit;
                    const timeToRegisterWidgets = this.timeOfRun - this.timeOfEvaluate;
                    const loadAndScriptingTime = this.timeOfEvaluate - window.headInitTime;
                    const waitToDomLoaded = this.timeOfInit - this.timeOfRun;

                    log.table({
                        loadAndScriptingTime: {
                            ms: loadAndScriptingTime,
                            percentage: Math.round(loadAndScriptingTime / total * 100)
                        },
                        registerWidgetsTime: {
                            ms: timeToRegisterWidgets,
                            percentage: Math.round(timeToRegisterWidgets / total * 100)
                        },
                        waitToDomLoaded: {
                            ms: waitToDomLoaded,
                            percentage: Math.round(waitToDomLoaded / total * 100)
                        },
                        initWidgetsTime: {
                            ms: timeInitWidgets,
                            percentage: Math.round(timeInitWidgets / total * 100)
                        },
                        total: {
                            ms: total,
                            percentage: Math.round(total / total * 100)
                        }
                    });
                    if (timeToRegisterWidgets > 50) {
                        // eslint-disable-next-line no-console
                        log.warn('High time of widgets registration');
                    }
                    if (timeInitWidgets > 30) {
                        // eslint-disable-next-line no-console
                        log.warn('High time of widgets initialization');
                    }
                    widgetsInitMetric.total = Object.values(widgetsInitMetric).reduce((a, b) => a + b, 0);
                    // eslint-disable-next-line no-console
                    log.groupCollapsed('Widgets initialization time (init method)');
                    // eslint-disable-next-line no-console
                    log.table(widgetsInitMetric);
                    // eslint-disable-next-line no-console
                    log.groupEnd();
                    //log.profileEnd('Initialization widgets');
                }
            })
    }
}
const widgetMgr = new WidgetMgr();

// Matches dashed string for camelizing
var rmsPrefix = /^-ms-/,
    rdashAlpha = /-([a-z])/g;

// Used by camelCase as callback to replace()
/**
 *
 * @param {any} all
 * @param {string} letter
 */
function fcamelCase(all, letter) {
    return letter.toUpperCase();
}

// Convert dashed to camelCase; used by the css and data modules
/**
 *
 * @param {string} string
 */
function camelCase(string) {
    return string.replace(rmsPrefix, 'ms-').replace(rdashAlpha, fcamelCase);
}


/**
 * @param {HTMLElement} el element to scan
 * @param {Widget} widgetInstance instance of element
 */
function scanEventAttrs(el, widgetInstance) {

    const attrs = el.getAttributeNames().filter(name => name.startsWith(WIDGET_PREFIX));

    if (attrs.length) {
        attrs.forEach(attr => {
            const [attrName, ...modifiers] = attr.replace(WIDGET_PREFIX, '').split('.');
            const attrValue = el.getAttribute(attr) || '@';

            if (!el[WIDGET_DISPOSABLE_VALUES]) {
                el[WIDGET_DISPOSABLE_VALUES] = [];
            }
            const disposableValues = el[WIDGET_DISPOSABLE_VALUES];

            if (typeof widgetInstance[attrValue] === 'function') {
                const prevent = modifiers.includes('prevent');
                const stop = modifiers.includes('stop');
                const once = modifiers.includes('once');
                const self = modifiers.includes('self');

                const disposables = widgetInstance.ev(attrName, function (element, event) {
                    if (prevent) {
                        event.preventDefault();
                    }
                    if (stop) {
                        event.stopPropagation();
                    }
                    if (once && disposables) {
                        disposables.forEach(diposable => diposable());
                    }
                    if (event.currentTarget !== event.target && self) {
                        return;
                    }
                    const target = Object.values(widgetInstance.refs || {})
                        .find((refEl) => Boolean(refEl && refEl instanceof RefElement && refEl.get() === element)) ||
                        new RefElement([element]);

                    widgetInstance[attrValue].call(this, target, event);
                }, el, modifiers.includes('passive'));

                if (disposableValues) {
                    // register events to remove once removed from DOM
                    disposableValues.push(...disposables);
                }

            } else {
                log.error('Widget "' + widgetInstance.constructor.name +
                    '" don\'t have method "' + attrValue +
                    '". Unable to assign event', { el });

            }
        });
    }
}
/**
 * @description -
 * @param {HTMLElement} domNode -
 */
// eslint-disable-next-line complexity
function initWidget(domNode) {
    const registeredWidgets = widgetMgr.getAll();

    var widgetName = domNode.getAttribute('data-widget');
    if (!widgetName) {
        setTimeout(() => {
            const error = Error('Empty Widget name');
            throw error;
        }, 0);

        return;
    }

    /**
     * @type {{[x: string]: string|number|boolean|undefined}}
     */
    var config = {};

    domNode.getAttributeNames().forEach(attrName => {
        if (typeof attrName === 'string' && attrName.includes('data-') && !attrName.startsWith(WIDGET_PREFIX)) {
            var value = domNode.getAttribute(attrName);

            if (typeof value === 'string') {
                config[camelCase(attrName.replace('data-', ''))] = getData(value);
            }
        }
    });

    const jsonConfig = domNode.getAttribute('data-json-config');

    if (jsonConfig) {
        try {
            const parsedConfig = JSON.parse(jsonConfig);
            config = Object.assign(config, parsedConfig);
        } catch (error) {
            throw new Error(`Invalid json config for widget ${domNode} ${error}`);
        }
    }

    if (!registeredWidgets[widgetName]) {
        setTimeout(() => {
            throw Error(`Widget "${widgetName}" is not found in registry`);
        }, 0);
        return Promise.resolve(undefined);
    }

    const nextInstancePromise = widgetMgr.get(widgetName)
        .then(WidgetClass => new WidgetClass(domNode, config));

    const parentPromise = findParentWidget(domNode);

    return Promise.all([nextInstancePromise, parentPromise]).then(([currentWidget, parentWidget]) => {
        if (parentWidget.items) {
            parentWidget.items.push(currentWidget);
        }
        if (currentWidget.refs && rootWidget.refs) {
            currentWidget.refs.html = rootWidget.refs.self;
        }
        if (currentWidget.id && parentWidget.refs) {
            parentWidget.refs[String(currentWidget.id)] = /** @type {RefElement} */(currentWidget);
        }
        currentWidget.parentHandler = () => void 0;
        currentWidget.parents = [parentWidget].concat(parentWidget.parents);

        const attrs = domNode.getAttributeNames().filter(name => name.startsWith('data-widget-event-'));
        if (attrs && attrs.length) {
            attrs.forEach(attr => {
                const [attrName] = attr.replace('data-widget-event-', '').split('.');
                const attrValue = domNode.getAttribute(attr);
                const prevHandler = currentWidget.parentHandler;

                if (typeof parentWidget[attrValue] === 'function') {
                    currentWidget.parentHandler = (name, ...args) => {
                        prevHandler(name, ...args);
                        if (name === attrName && typeof parentWidget[attrValue] === 'function') {
                            parentWidget[attrValue].call(parentWidget, ...args);
                        }
                    };
                } else {
                    log.error(`Widget "${parentWidget.constructor.name}" don't have method "${attrValue}"`);
                }

            });
        }
        domNode[WIDGET_PROP_NAME] = currentWidget;
        return currentWidget;
    });
}

/**
 *
 * @param {HTMLElement} element element to start scan
 */
function attachElementsOnReady(element) {
    const parentWidget = element[WIDGET_PROP_NAME] || findParentWidget(element);
    const ref = element.getAttribute('data-ref');
    // Attach refs
    if (ref && parentWidget.refs) {
        const refEl = new RefElement([element]);
        parentWidget.refs[ref] = refEl;
        if (!element[WIDGET_DISPOSABLE_VALUES]) {
            element[WIDGET_DISPOSABLE_VALUES] = [];
        }
        const disposableValues = element[WIDGET_DISPOSABLE_VALUES];

        if (disposableValues) {
            disposableValues.push(() => {
                // remove reference if element is removed from DOM ref points to the same element
                if (parentWidget && parentWidget.refs && refEl === parentWidget.refs[ref]) {
                    delete parentWidget.refs[ref];
                }
            });
        }
    }

    // Attach parent behaviours
    const attrs = element.getAttributeNames().filter(name => name.startsWith(WIDGET_PREFIX));
    if (attrs.length) {
        scanEventAttrs(element, parentWidget);
    }
    return element;
}


class WidgetObserver {
    constructor() {
        /**
         * @type Array<HTMLElement>
         */
        this.elements = [];
        this.observer = new IntersectionObserver((entries) => {
            for (let i = 0, l = entries.length; i < l; i += 1) {
                let entry = entries[i];
                if (entry.target && entry.target.getClientRects().length) {
                    if (entry.target[WIDGET_PROP_NAME] && entry.target[WIDGET_PROP_NAME].updateIntersection) {
                        entry.target[WIDGET_PROP_NAME].updateIntersection(entry.isIntersecting);
                    }
                }
            }
        }, { rootMargin: `100px` });
    }

    /**
     * 
     * @param {HTMLElement} el 
     */
    addElement(el) {
        if (el[WIDGET_PROP_NAME]) {
            this.elements.push(el);
            this.observer.observe(el);
        }
    }
    /**
     * 
     * @param {HTMLElement} el 
     */
    removeElement(el) {
        this.observer.unobserve(el);
        const idx = this.elements.indexOf(el);
        if (idx !== -1) {
            this.elements.splice(idx, 1);
        }
    }
}

const widgetObserver = new WidgetObserver();

/**
 *
 * @param {HTMLElement} element element to start scan
 */
function attachElements(element) {
    /** @type {Promise<HTMLElement>} */
    let promiseChain = Promise.resolve(element);
    widgetObserver.addElement(element);
    if (element.getAttribute('data-widget') && !element[WIDGET_PROP_NAME]) {
        if (!element[WIDGET_PROMISE]) {
            element[WIDGET_PROMISE] = initWidget(element);
            element[WIDGET_PROMISE] = element[WIDGET_PROMISE].then(() => attachElements(element));
        }
        return element[WIDGET_PROMISE];
    }


    return Promise.resolve(element)
        .then(element => attachElementsOnReady(element))
        .then((element) => {
            return Promise.all(
                Array.from(element.children || [])
                    .map((child) => {
                        return attachElements(child)
                            .catch(err => console.error(err))
                    })
            )
                .then(() => element);
        })
        .then((element) => {
            if (element[WIDGET_PROP_NAME]) {
                let widgetInstance = element[WIDGET_PROP_NAME]
                const startTime = Date.now();
                widgetInstance.init();
                element.setAttribute('data-initialized', '1');


                if (!PRODUCTION) {
                    var widgetName = element.getAttribute('data-widget');
                    if (widgetName && startTime) {

                        widgetsInitMetric[widgetName] = (widgetsInitMetric[widgetName] || 0) + (Date.now() - startTime);
                    }
                }
            }
        });
}

/**
 * @param {HTMLElement} el
 * @returns {Widget}
 */
function findParentWidget(el) {
    /**
     * @type {HTMLElement | null}
     */
    var parent = el;

    while (parent) {
        if (parent.getAttribute('data-widget') && el !== parent) {
            break;
        } else {
            parent = parent.parentElement;
        }
    }
    return parent && parent[WIDGET_PROP_NAME] || rootWidget;
}

/**
 * @param {HTMLElement} el
 */
function detachElement(el) {
    widgetObserver.removeElement(el);
    const disposableValues = el[WIDGET_DISPOSABLE_VALUES];
    if (disposableValues) {
        disposableValues.forEach((dispose) => dispose());
        delete el[WIDGET_DISPOSABLE_VALUES];
    }
    var currentWidget = el[WIDGET_PROP_NAME];
    if (currentWidget) {
        var parentWidget = findParentWidget(el);
        if (parentWidget.items) {
            var idx = parentWidget.items.indexOf(currentWidget);
            if (idx > -1) {
                parentWidget.items.splice(idx, 1);
            }
        }

        currentWidget.destroy();
        delete el[WIDGET_PROP_NAME];
    }

    var child = el.firstElementChild;
    while (child) {
        detachElement(child);
        child = child.nextElementSibling;
    }
}


export default widgetMgr;

