import { range, timeout } from 'widgets/toolbox/util';
import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import { submitFormJson, getContentByUrl, getJSONByUrl } from 'widgets/toolbox/ajax';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { windowScrollTo } from 'widgets/toolbox/scroll';
import checkProductsAvailability from 'widgets/util/checkProductsAvailability';
import { parseProductPrice } from 'widgets/util/helpers';
import Widget from '../Widget';

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class CartMgr extends Widget {
    prefs() {
        return {
            isCart: true,
            confirmDialog: false,
            ...super.prefs()
        };
    }
    init() {
        // render in next tick so will not block initialization
        this.onDestroy(timeout(() => {
            this.renderCart(this.prefs());
            this.eventBus().emit('cart.page.inited', this);
        }, 0));

        this.eventBus().on('cart.refresh', 'cartRefresh');
        this.eventBus().on('minicart.updated', 'renderCart');
        this.eventBus().on('product.updated', 'renderCart');
        this.eventBus().on('coupon.updated', 'renderCart');
        this.eventBus().on('bonus.product.added.to.cart', 'closeBonusModal');
        this.eventBus().on('product.detail.added.to.cart', 'renderCart');

        this.eventBus().on('cart.lineitem.remove', 'removeLineItem');

        this.eventBus().emit('cart.page.init', this);

        this.updateRecommendationCarousel(this.config.items.length > 0);

        this.initCartRefresh();
    }
    renderCart(cart) {
        this.sortItemsInCart(cart.items);
        cart.items = cart.items.map(item => {
            if (item.bonusProducts) {
                item.bonusProducts = item.bonusProducts.map(product => {
                    return {
                        ...product,
                        analytics: JSON.stringify(product.analytics),
                        freePrice: true
                    };
                });
            }
            const isItemNotInStock = item.availability.isInStock === false;
            return {
                ...item,
                price: parseProductPrice(item),
                analytics: JSON.stringify(item.analytics),
                isClickable: !item.isGiftBox && item.online,
                quantityOptionsList: range(
                    item.quantityOptions.minOrderQuantity,
                    item.quantityOptions.maxOrderQuantity + 1
                ).map(qty => ({
                    index: qty,
                    selected: qty === item.quantity ? 'selected' : ''
                })),
                lessBtnDisabled: (item.quantity <= item.quantityOptions.minOrderQuantity) || isItemNotInStock || !item.online,
                moreBtnDisabled: (item.quantity >= item.quantityOptions.maxOrderQuantity) || isItemNotInStock || !item.online,
                quantityInputDisabled: isItemNotInStock || !item.online,
                quantityCheck: item.quantity > 1,
                freePrice: (item.price && item.price.sales && item.price.sales.value === 0) || item.isBonusProductLineItem
            };
        });

        if (cart.giftBoxProducts) {
            cart.giftBoxProducts = cart.giftBoxProducts.map(product => {
                return {
                    ...product,
                    analytics: JSON.stringify(product.analytics),
                    freePrice: product.price.sales.value === 0
                };
            });
        }

        cart.shipments = (cart.shipments || []).map(shipment => {
            shipment.shippingMethods = (shipment.shippingMethods || []).map(shippingMethod => ({
                ...shippingMethod,
                selected: shippingMethod.ID === shipment.selectedShippingMethod ? 'selected' : ''
            }));
            return shipment;
        });

        cart.showCouponForm = true;

        if (cart.totals && cart.totals.discounts && cart.totals.discounts.length) {
            cart.totals.discounts.forEach(discount => {
                discount.isCouponDiscount = discount.type === 'coupon';
                discount.isShippingDiscount = discount.promotionClass === 'SHIPPING';
                discount.isOrderDiscount = discount.promotionClass === 'ORDER';
                if (discount.isCouponDiscount && discount.applied) {
                    cart.showCouponForm = false;
                }
            });
        }

        if (cart.giftCertificates && cart.giftCertificates.items && cart.giftCertificates.items.length) {
            cart.giftCertificatesItems = cart.giftCertificates.items;
        }

        cart.isGlobalSite = this.config.sitePreferences.IS_GLOBAL_SITE;

        cart.isApplePaySession = !!window.ApplePaySession;

        this.updateRecommendationCarousel(cart.items.length > 0);

        this.eventBus().emit('cart.updated', cart);
        this.ref('cartLineItemsList').empty();

        const allowedCheckout = cart.valid && cart.valid.allowedCheckout;

        this.render('cartTemplate', cart, this.ref('cartContainer'));
        this.updateCheckoutBtn(allowedCheckout);

        this.eventBus().emit('cart.rendered', cart);
    }

    initCartRefresh() {
        if (this.cleanUpCartRefreshHandler) {
            this.cleanUpCartRefreshHandler();
            this.cleanUpCartRefreshHandler = void 0;
        }

        const cartRefreshHandler = () => {
            this.cartRefresh();
        };

        window.addEventListener('visibilitychange', cartRefreshHandler);

        this.cleanUpCartRefreshHandler = () => {
            window.removeEventListener('visibilitychange', cartRefreshHandler);
        };
    }

    cartRefresh() {
        this.showProgressBar();

        getJSONByUrl(this.prefs().actionUrls.updateCartViewUrl, { isAjax: true })
            .then((res) => {
                if (res.items.length) {
                    this.renderCart(res);
                }
            })
            .catch(error => this._renderError(error))
            .finally(() => {
                this.hideProgressBar();
            });
    }

    // method added for consistency
    addGiftBox(button) {
        if (!button.refs.self.els[0].disabled) {
            this.showProgressBar();

            const action = button.prefs().action;

            submitFormJson(action, { pid: button.prefs().pid, quantity: 1})
                .then(response => {
                    if (response.error) {
                        this._renderError({
                            error: true,
                            errorMessage: response.message
                        });
                        windowScrollTo(this.ref('self').offset().top, 0, 'auto');
                    } else {
                        this.renderCart(response.cart);
                        this.eventBus().emit('cart.item.added', response.cart, button);
                    }
                })
                .catch(error => this._renderError(error))
                .finally(() => {
                    this.hideProgressBar();
                    this.eventBus().emit('emarsys.update');
                });
        }

    }
    updateQty(InputNumber) {
        this.showProgressBar();
        submitFormJson(InputNumber.ref('self').attr('data-action'), {
            pid: InputNumber.ref('self').attr('data-pid'),
            uuid: InputNumber.ref('self').attr('data-uuid'),
            quantity: InputNumber.getValue()
        }, 'GET')
            .then((response) => {
                if (response.error && response.redirectUrl) {
                    window.location.assign(response.redirectUrl);
                } else {
                    this.renderCart(response);
                    this.eventBus().emit('cart.item.updated', response, InputNumber);
                }
            })
            .catch(error => this._renderError(error))
            .finally(() => {
                this.hideProgressBar();
                this.eventBus().emit('emarsys.update');
            });
    }
    editProduct(editBtn) {
        disableScroll(true);
        this.showProgressBar();
        var uuid = editBtn.attr('data-uuid');
        getContentByUrl(editBtn.data('href'), { uuid: uuid })
            .then((response) => {
                var product = this.config.items.find(i => i.UUID === uuid);
                this.eventBus().emit('openGlobalModal', {
                    content: response,
                    'class': 'modal-quickview'
                }, true, (widget) => {
                    if (product) {
                        this.eventBus().emit('product.tile.quickview.open', this, widget, product.analytics);
                    }
                });
            })
            .catch(() => enableScroll())
            .finally(() => {
                this.hideProgressBar();
            });
    }
    selectBonus(selectBonusBtn) {
        this.showProgressBar();
        getContentByUrl(selectBonusBtn.data('href'), {
            DUUID: selectBonusBtn.attr('data-uuid'),
            pids: selectBonusBtn.attr('data-pids'),
            maxpids: selectBonusBtn.attr('data-maxpids'),
            pliuuid: selectBonusBtn.attr('data-pliuuid')
        })
            .then((response) => {
                this.eventBus().emit('openGlobalModal', {
                    content: response,
                    'class': 'modal-bonus'
                });
            })
            .finally(() => {
                this.hideProgressBar();
            });
    }
    closeBonusModal(cart) {
        timeout(() => {
            this.eventBus().emit('closeGlobalModal');
        }, 2000);
        this.renderCart(cart);
    }
    /**
     *
     * @param {InstanceType<ReturnType<import('widgets/forms/InputSelect').default>>} inputSelect shipping selector
     */
    updateShippingMethods(inputSelect) {
        const selectedOption = inputSelect.getSelectedOptions();

        if (selectedOption) {
            this.eventBus().emit('cart.update.shipping.method', this, selectedOption);

            submitFormJson(inputSelect.prefs().actionUrl, {
                methodID: selectedOption.data('shippingId')
            }, 'POST').then(resp => {
                if (resp.error && resp.redirectUrl) {
                    window.location.assign(resp.redirectUrl);
                } else {
                    this.renderCart(resp);
                }
            });
        }
    }
    removeProduct(removeButton) {
        this.removeButton = removeButton;
        if (this.prefs().confirmDialog) {
            this.ref('confirmDialog')
                .showDialog({ productName: removeButton.data('name') });
        } else {
            this.confirmedRemoveProduct();
        }
    }
    confirmedRemoveProduct() {
        if (this.removeButton) {
            this.eventBus().emit('cart.item.remove', this);
            this.removeLineItem(
                this.removeButton.data('removeAction') || this.removeButton.data('action'),
                this.removeButton.data('pid'),
                this.removeButton.data('uuid')
            );
        }
    }

    removeLineItem(action, pID, uuID) {
        this.showProgressBar();
        if (!this.removeButton) {
            this.getById('removeButton' + uuID, removeButton => this.removeButton = removeButton);
        }
        submitFormJson(action, {
            pid: pID,
            uuid: uuID
        }, 'GET')
            .then((response) => {
                this.confirmationRemoveCallback(response.basket);
                if (!response.basket.numItems) {
                    windowScrollTo(this.ref('self').offset().top, 0, 'auto');
                }
                this.eventBus().emit('cart.item.removed', response.basket, this.removeButton);
                this.removeButton = void 0;
                this.hideProgressBar();
                this.eventBus().emit('emarsys.update');
            })
            .catch(() => {
                this.removeButton = void 0;
                this.hideProgressBar();
            });
    }

    confirmationRemoveCallback(cart) {
        this.renderCart(cart);
    }
    removeCoupon(removeButton) {
        this.removeButton = removeButton;
        this.confirmedRemoveCoupon(); // remove coupon without confirmation
    }
    confirmedRemoveCoupon() {
        if (this.removeButton) {
            this.showProgressBar();
            submitFormJson(this.prefs().actionUrls.removeCouponLineItem, {
                couponCode: this.removeButton.attr('data-code'),
                uuid: this.removeButton.attr('data-uuid')
            }, 'GET')
                .then((response) => {
                    this.renderCart(response);
                })
                .finally(() => {
                    this.hideProgressBar();
                });
            this.removeButton = void 0;
        }
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }

    toggleCouponForm() {
        this.ref('couponForm').toggleForm();
    }

    /**
     * @param {HTMLFormElement} el
     * @param {Event} event
     */
    checkAvailabilityBeforeCheckout(el, event) {
        checkProductsAvailability(el, event, this, this.renderCart);
    }

    showPromoForm() {
        this.ref('promoButton').hide();
        this.ref('promoForm').show();
    }

    updateRecommendationCarousel(hasItems) {
        this.ref('noItemsRecommendations').toggle(!hasItems);
        this.ref('hasItemsRecommendations').toggle(hasItems);
    }

    _renderError(error) {
        if (error && error.error && error.errorMessage) {
            this.render('errorTemplate', { message: error.errorMessage }, this.ref('errorMsgs'));
        }
    }

    updateCheckoutBtn(enable) {
        if (enable) {
            this.has('cartCheckoutBtn', button => button.enable());
            this.has('cartCheckoutBtnDuplicate', button => button.enable());
            this.has('cartApplePayBtn', button => button.enable());
            if (this.has('PayPal')) {
                this.refs.PayPal.has('cartPayPalBtn', button => button.enable());
            }
        } else {
            this.has('cartCheckoutBtn', button => button.disable());
            this.has('cartCheckoutBtnDuplicate', button => button.disable());
            this.has('cartApplePayBtn', button => button.disable());
            if (this.has('PayPal')) {
                this.refs.PayPal.has('cartPayPalBtn', button => button.disable());
            }
        }
    }

    sortItemsInCart(items) {
        items = items.map((item, index) => ({index, ...item}));
        items.sort((item1, item2) => {
            if (+item1.available > +item2.available) {
                return 1;
            }
            if (+item1.available < +item2.available) {
                return -1;
            }
            if (item1.available === item2.available) {
                return item1.index - item2.index;
            }
        });
        items = items.map(item => {
            delete item.index;
            return item;
        });
    }

    continueShoppingClick(ref) {
        this.eventBus().emit('continueShopping');
        if (document.referrer && document.referrer !== '') {
            history.back();
        } else {
            location.href = ref.attr('href');
        }
    }

    removeGiftVoucher(button) {
        const url = button.attr('href');
        getJSONByUrl(url, {
            giftCertificateCode: button.data('giftCertificateCode'),
            isCart: true
        }).then(data => {
            const cart = data.cart;
            if (data.giftCertificates) {
                cart.giftCertificates = data.giftCertificates;
            }
            if (data && !data.error) {
                this.renderCart(cart);
            }
        });
    }
}