import { getContentByUrl } from 'widgets/toolbox/ajax';
import { replaceParameterInURL } from 'widgets/toolbox/util';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import InfiniteScroll from '../global/InfiniteScroll';

/**
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */

export default class WishlistMgr extends InfiniteScroll {
    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);
        this.removedItemsCount = 0;
    }

    prefs() {
        return {
            ...super.prefs(),
            contentList: 'wishlistItemCards',
            shareContainer: 'shareWihsListContainer',
            guestContainer: 'guestWihsListContainer',
            emptyContainer: 'emptyWihsListContainer'
        };
    }

    init() {
        super.init();
        this.eventBus().on('wishlist.added', 'handleAdding');
        this.eventBus().on('wishlist.removed', 'removeTile');
        this.eventBus().emit('product.listing.init', this);
    }

    moreLoaded() {
        this.eventBus().emit('product.listing.updated', this);
    }

    removeTile(data) {
        this.has('tile-' + data.pid, wishlistTile => wishlistTile.remove());
        this.removedItemsCount++;

        if (!data.count) {
            this.toggleState(true);
        } else {
            this.data('loadMore', true);
        }
    }

    toggleState(empty = false) {
        this.ref(this.prefs().contentList)[empty ? 'hide' : 'show']();
        this.ref(this.prefs().emptyContainer)[empty ? 'show' : 'hide']();
        this.has(this.prefs().guestContainer, guestContainer => guestContainer[empty ? 'hide' : 'show']());
        this.has(this.prefs().shareContainer, shareContainer => shareContainer[empty ? 'hide' : 'show']());
    }

    handleAdding() {
        const loadMoreUrl = this.ref('self').data('loadMoreUrl');

        if (!this.isNotLoaded && loadMoreUrl) {
            this.isNotLoaded = true;
            showPageLoader();

            getContentByUrl(replaceParameterInURL(
                loadMoreUrl,
                'pageSize', '1'
            ))
                .then(response => {
                    this.ref(this.prefs().contentList).prepend(response);
                    this.ref('self').attr('data-count', this.ref('self').data('count') + 1);
                    this.removedItemsCount--;
                    this.toggleState();
                    this.data('checkInfinite');
                }).finally(() => {
                    hidePageLoader();
                    this.isNotLoaded = false;
                });
        }
    }

    getStart() {
        return this.config.sitePreferences.INITIAL_PAGE_SIZE +
                this.prefs().pageNumber * this.config.sitePreferences.DEFAULT_PAGE_SIZE -
                this.removedItemsCount;
    }
}