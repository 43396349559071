import { clickOutside, swipeEvent } from 'widgets/toolbox/util';
import { disableScroll, enableScroll } from '../toolbox/scroll';
import SwipeEvent from './swipeEvent';

const navigationClass = 'l-header__navigation';

/**
 * @description Navigation bar implementation
 */
export default class NavigationBar extends SwipeEvent {
    init() {
        if (this.ref('self').hasClass(navigationClass)) {
            this.eventBus().on('open.nav.search', 'openSearchNavBar');
        }
    }

    prefs() {
        return {
            classesOpenedBar: 'opened-bar',
            classesSearchMode: 'search-mode',
            clickOutsideRef: 'self',
            ...super.prefs()
        };
    }

    openSearchNavBar() {
        this.ref('html').addClass(this.prefs().classesSearchMode);
        this.openNavBar();
    }

    openNavBar() {
        disableScroll();
        this.ref('html').addClass(this.prefs().classesOpenedBar);
        this.appendNavigationOffset();

        if (this.prefs().triggerOpen) {
            this.eventBus().emit(this.prefs().triggerOpen);
        }

        this.clearClickOutside();
        this.exposeClickOutside = clickOutside([this.ref(this.prefs().clickOutsideRef), '.b-header-icons'], () => {
            this.closeNavBar();
        });
        this.onDestroy(this.exposeClickOutside);
    }

    closeNavBar() {
        enableScroll();
        this.ref('html').removeClass(this.prefs().classesOpenedBar);
        this.ref('html').removeClass(this.prefs().classesSearchMode);

        if (this.prefs().triggerClose) {
            this.eventBus().emit(this.prefs().triggerClose);
        }

        this.eventBus().emit('mobile.search.hide');
        this.clearClickOutside();
    }

    toggleNavBar() {
        if (this.ref('html').hasClass(this.prefs().classesOpenedBar)) {
            this.closeNavBar();
        } else {
            this.openNavBar();
        }
    }

    clearClickOutside() {
        if (this.exposeClickOutside) {
            this.exposeClickOutside();
            this.exposeClickOutside = void 0;
        }
    }

    appendNavigationOffset() {
        const mobileMenu = this.ref('mobileMenu').get();

        if (!mobileMenu) {
            return;
        }

        const parentRefs = this.parents[0].refs;
        const offset = parentRefs.pageHeader && parentRefs.pageHeader.get().getBoundingClientRect().top;
        const parsedOffset = parseInt(offset, 10);
        mobileMenu.style.paddingTop = parsedOffset + 'px';
    }
}