/* eslint-disable indent */

import { submitFormJson } from 'widgets/toolbox/ajax';
import Widget from '../Widget';

/**
 * @description Base button implementation
 */
export default class Bambuser extends Widget {
    prefs() {
        return {
            ...super.prefs()
        };
    }

    init() {
      window.onBambuserLiveShoppingReady = player => {
          player.configure({
            locale: 'en-GB',
            currency: 'GBP',
            buttons: {
              dismiss: player.BUTTON.CLOSE
            }
          });

          this.playerInit(player)

          player.on(player.EVENT.ADD_TO_CART, (addedItem, callback) => {
            this.addToCart(addedItem.sku)
              .then((responseData) => {
                callback(true);
              })
              .catch(error => {
                callback({
                  success: false,
                  reason: 'custom-error',
                  message: error
                });
              });
          });

          player.on(player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem, callback) => {
            this.updateCart(updatedItem.sku, updatedItem.quantity)
            .then(() => {
              callback(true);
            })
            .catch((error) => {
              callback({
                success: false,
                reason: 'custom-error',
                message: error
              });
            });
          });

          player.on(player.EVENT.CHECKOUT, () => {
            player.showCheckout(this.data('checkouturl'));
          });

          player.on(player.EVENT.SYNC_CART_STATE, () => {
              player.updateCart({
                items: []
              });
          });
      };
    }

    async getProductData(sku) {
      const getProductUrl = this.data('getproducturl');
      let data = await fetch(getProductUrl + `?pid=${sku}`);
      data = await data.json();
      if (!data.success) {
        throw new Error();
      }
      return data.product;
    }

    playerInit(player) {
      player.on(player.EVENT.PROVIDE_PRODUCT_DATA, async (event) => {
        for (const productData of event.products) {
          const { ref: sku, id: bambuserId } = productData;
          let product;
          try {
            product = await this.getProductData(sku);
          } catch (err) { }

          if (!product) {
            continue;
          }

          player.updateProduct(bambuserId, (productFactory) =>
            productFactory

              // -> The .product() method takes a function
              //    returning a product detail object
              .product((productDetailFactory) =>
                productDetailFactory

                  // Name of the product
                  .name(product.name)

                  // Brand name to display for the product
                  .brandName(product.brand)

                  // (Optional) Short product introductory text 
                  .introduction(product.introduction)

                  // (Optional) Description for the product
                  // Supports text as well as HTML string
                  .description(product.description)

                  // sku (or any other identifier for your product)
                  // NOTE: Should be the same as your product reference
                  // defined in Bambuser Dashboard
                  .sku(product.sku)

                  // Describes which index in the variations list below
                  // contains the default variation
                  // (e.g. if variations contain colors, and you want to display
                  //  the white version of a shirt, send the index for the white variation)
                  .defaultVariationIndex(0)

                  // -> The .variations() method takes a function
                  //    returning an array of variations
                  .variations((variationFactory) =>
                    product.variations.map((variation) =>
                      // VariationFactory contains a factory returning a new instance of a variation
                      variationFactory()
                        // -> (Optional) The .attributes() method takes a function
                        //    defines color attribute for the variation
                        .attributes((attributeFactory) =>
                          attributeFactory
                            // Color name in the variation dropdown selector,
                            .colorName(variation.colorName)

                            // (Optional) Color Hex code e.g. '#7d58ee'
                            .colorHexCode(variation.colorHexCode)
                        )
                        // List of image urls for the variation
                        // ordered the same as you want it displayed
                        .imageUrls(variation.images)

                        // Name of the variation
                        // Shown if colorName is not provided as attribute.
                        .name(variation.name)

                        // sku (or any other identifier for your product)
                        // specific down to this variation
                        .sku(variation.variationId)

                        // -> The .sizes() method takes a function that
                        //    returns an array of sizes
                        .sizes((sizeFactory) =>
                          variation.sizes.map((size) =>
                            // s contains a factory returning a new instance of a variation
                            sizeFactory()
                              // Name of the size
                              // (used in dropdowns)
                              .name(size.name)

                              // Set whether this combination of
                              // variation and size is in stock
                              .inStock(size.inStock)

                              // sku (or any other identifier for your product)
                              // specific down to this size (used for add-to-cart)
                              .sku(size.id)

                              // -> The price method contains a new chain
                              //    defines price for the variation and size combo
                              .price((priceFactory) =>
                                priceFactory
                                  // currency to show price in
                                  // Optional - overrides the default currency
                                  .currency(size.currency)

                                  // current price as a number
                                  .current(size.currentPrice)

                                  // (Optional) original price
                                  // Used in case the current is a sale price
                                  .original(size.originalPrice)
                              )
                          )
                        )
                    )
                  )
              )
          );
        }
      });
    }

    addToCart(sku) {
      return new Promise((resolve, reject) => {
        const addToCartUrl = this.data('addtocarturl');
        const addToCartParams = {
          pid: sku,
          quantity: 1
        };

        submitFormJson(addToCartUrl, addToCartParams)
        .then(response => {
          if (response.error) {
            reject(response.errorMessage);
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
            reject(err.errorMessage || err.message || err);
        });
      });
    }

    updateCart(sku, quantity) {
      return new Promise((resolve, reject) => {
        const updateQtyUrl = this.data('updateqtyurl');

        fetch(updateQtyUrl + `?pid=${sku}&quantity=${quantity}`)
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            reject(data.errorMessage);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
            reject(err.errorMessage || err);
        });
      });
    }
}