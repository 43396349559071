import { extend } from 'widgets/toolbox/util';
import Carousel from './Carousel';

const SLIDES_PER_MOVE = 3;
const PER_VIEW = 3;

/**
 * @description search carousel implementation
 */
export default class SearchCarousel extends Carousel {
    prefs() {
        return {
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.eventBus().emit('product.listing.init', this);
        this.eventBus().on('search.carousel.init', 'reset');
    }

    reset() {
        super.refresh();
    }

    initOptions() {
        super.initOptions();
        extend(this.options, JSON.parse(this.config.sitePreferences.GLOBAL_CAROUSEL_CONFIGURATION), this.prefs().carouselOptions || {});
        this.options.perView = PER_VIEW;
    }

    initEvents() {
        super.initEvents();
        this.carousel.on('run.before', (evt) => {
            evt.steps = evt.direction === '>' ? -SLIDES_PER_MOVE : SLIDES_PER_MOVE;
        })
    }
}