import eventBus from './eventBus';
import { debounce } from './debounce';

const mobileId = 'Mobile',
    tabletId = 'Tablet',
    desktopId = 'Desktop';

const mediaM = 'screen and (max-width: 767px)',
    mediaT = 'screen and (min-width: 768px) and (max-width: 1199px)',
    mediaD = 'screen and (min-width: 1200px)';

let queriesMap = [
    [mobileId, mediaM],
    [tabletId, mediaT],
    [desktopId, mediaD]
];

let currentWindowWidth = window.innerWidth;
let currentWindowHeight = window.innerHeight;

const windowDimensions = {
    width: 0,
    height: 0
};

/**
 * @return {String}
 */
const getCurrentViewType = () => {
    const matchQuery = queriesMap.find(([, query]) =>
        window.matchMedia(query).matches
    );
    return (matchQuery && matchQuery[0]) || mobileId;
};

const onWindowChange = () => {
    if (currentWindowWidth !== window.innerWidth || currentWindowHeight !== window.innerHeight) {
        currentWindowWidth = window.innerWidth;
        currentWindowHeight = window.innerHeight;
        eventBus.emit('viewtype.windowChange', {
            currentWindowWidth: currentWindowWidth,
            currentWindowHeight: currentWindowHeight
        });
    }
};

const ScreenDetector = {
    currentDevice: getCurrentViewType(),
    MOBILE: mobileId,
    TABLET: tabletId,
    DESKTOP: desktopId,
    /**
     * @returns {String}
     */
    getViewType: () => ScreenDetector.currentDevice,
    isTouchDevice: () => ('ontouchstart' in window) ||
        !!(window.DocumentTouch && document instanceof window.DocumentTouch),
    isMobileView: () => ScreenDetector.getViewType() === mobileId,
    isTabletView: () => ScreenDetector.getViewType() === tabletId,
    isDesktopView: () => ScreenDetector.getViewType() === desktopId,
    isIPhone: () => navigator.userAgent.match(/iphone/i),
    /**
     *
     * @param {typeof mobileId|typeof tabletId| typeof desktopId} newDevice device type
     */
    matchViewport(newDevice) {
        const previousDevice = this.currentDevice;

        this.currentDevice = newDevice;
        //emitter.emit(newDevice, newDevice);
        if (previousDevice !== this.currentDevice) {
            eventBus.emit('viewtype.change', this.currentDevice);
        }
    },
    /**
     * detects iPhone
     */
    getWindowHeight() {
        // Non-iOS browsers return window.innerHeight per usual.
        if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
            return window.innerHeight;
        }

        // Store initial orientation
        const axis = Math.abs(window.orientation);

        if (windowDimensions.width === 0) {
            // Creates an element with a height of 100vh since iOS accurately
            const ruler = document.createElement('div');
            ruler.style.position = 'fixed';
            ruler.style.height = '100vh';
            ruler.style.width = '0px';
            ruler.style.top = '0px';
            document.documentElement.appendChild(ruler);

            // Set cache conscientious of device orientation
            windowDimensions.width = axis === 90 ? ruler.offsetHeight : window.innerWidth;
            windowDimensions.height = axis === 90 ? window.innerWidth : ruler.offsetHeight;

            // Clean up after ourselves
            document.documentElement.removeChild(ruler);
        }

        if (axis !== 90) {
            return windowDimensions.height;
        }

        return windowDimensions.width;
    },
    /**
     *
     * @param {object}  config object
     * @param {boolean} config.useWindowListeners use listeners or MediaQueryListener
     * @param {number} config.mobileWidth size of mobile in pixels
     * @param {number} config.tabletWidth size of tablet in pixels
     * @param {number} config.desktopWidth size of desktop in pixels
     */
    init(config) {
        queriesMap = [
            [mobileId, `(max-width: ${config.tabletWidth - 1}px)`],
            [tabletId, `(min-width: ${config.tabletWidth}px) and (max-width: ${config.desktopWidth - 1}px)`],
            [desktopId, `(min-width: ${config.desktopWidth}px)`]
        ];

        if (config.useWindowListeners) {
            const windowResize = debounce(() => {
                onWindowChange();
                ScreenDetector.matchViewport(getCurrentViewType());
            }, 50);

            window.addEventListener('resize', windowResize, { passive: true });
            window.addEventListener('orientationchange', windowResize, { passive: true });
        } else {

            const applyCurrentDeviceType = debounce(() =>
                ScreenDetector.matchViewport(getCurrentViewType()), 50);

            queriesMap.forEach(([, query]) =>
                window.matchMedia(query).addListener(applyCurrentDeviceType)
            );
        }
        this.currentDevice = getCurrentViewType();

        eventBus.emit('viewtype.change', this.currentDevice);

        if (ScreenDetector.isTouchDevice()) {
            document.documentElement.classList.add('touch');
        } else {
            document.documentElement.classList.add('non-touch');
        }
    }
};


export default ScreenDetector;
