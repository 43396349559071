import { replaceParameterInURL } from 'widgets/toolbox/util';
import noUiSlider from 'nouislider';
import Widget from '../Widget';

/**
 * @description Price filter widget implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class FilterPrices extends Widget {
    prefs() {
        return {
            ...super.prefs(),
            classesDisabled: 'm-disabled',
            step: 1,
            margin: 2,
            applyPriceBtn: this.ref('applyPrice'),
            minMoneyAmount: 1
        };
    }
    init() {
        this.initPrefs();
        this.initSlider();
        this.eventBus().on('refinement.price.check', 'checkRange');
    }
    onRefresh() {
        this.initPrefs();
        this.initSlider();
    }
    initPrefs() {
        this.config.minValue = +this.ref('self').data('min-value') || 0;
        this.config.maxValue = +this.ref('self').data('max-value') || 0;
        if (this.config.minValue === this.config.maxValue) {
            this.config.maxValue = this.config.minValue + this.prefs().step;
        }
        this.config.minRange = +this.ref('self').data('min-range') || 0;
        this.config.maxRange = +this.ref('self').data('max-range') || 0;
        if (this.config.maxRange - this.config.minRange < this.prefs().margin) {
            this.ref('self').addClass(this.prefs().classesDisabled);
            if (this.config.maxRange === this.config.minRange) {
                this.config.maxRange = this.config.minRange + this.prefs().step;
            }
        } else {
            this.ref('self').removeClass(this.prefs().classesDisabled);
        }
    }
    initSlider() {
        this.ref('slider').els.every((sliderElement) => {
            if (sliderElement.noUiSlider) {
                sliderElement.noUiSlider.destroy();
            }
            noUiSlider.create(sliderElement, {
                start: [
                    this.prefs().minValue,
                    this.prefs().maxValue
                ],
                step: this.prefs().step,
                margin: this.prefs().margin,
                connect: true,
                range: {
                    min: this.prefs().minRange,
                    max: this.prefs().maxRange
                }
            });
            sliderElement.noUiSlider.on('change', this.updateInputs.bind(this, 'change'));
            sliderElement.noUiSlider.on('slide', this.updateInputs.bind(this, 'slide'));
        });
    }
    updateInputs(event, values) {
        const minValue = (+values[0]).toFixed();
        const maxValue = (+values[1]).toFixed();
        this.ref('minValueElement').setText(minValue);
        this.ref('maxValueElement').setText(maxValue);
        this.ref('minValueInput').val(minValue);
        this.ref('maxValueInput').val(maxValue);

        if (event === 'change' && document.documentElement.classList.contains('plp-layout-test-2')) {
            this.updateView();
        }
    }
    updatePriceUrl() {
        const { applyPriceBtn, minMoneyAmount } = this.prefs();
        const minPriceInput = this.ref('minValueInput');
        const maxPriceInput = this.ref('maxValueInput');
        let serchURL = applyPriceBtn.attr('data-href');

        if (+minPriceInput.val() >= +maxPriceInput.val()) {
            maxPriceInput.val((+minPriceInput.val() + minMoneyAmount).toString());
            minPriceInput.val((+maxPriceInput.val() - minMoneyAmount).toString());
        }

        serchURL = replaceParameterInURL(serchURL, this.config.Constants.PMIN, minPriceInput.val());
        serchURL = replaceParameterInURL(serchURL, this.config.Constants.PMAX, maxPriceInput.val());

        applyPriceBtn.attr('data-href', serchURL);
        applyPriceBtn.attr('href', serchURL);
    }
    updateView(el) {
        if (!el) {
            el = this.ref('applyPrice');
        }

        this.updatePriceUrl();
        this.emit('updateview', el);
    }
    checkRange(updated = true) {
        this.ref('error')[updated ? 'hide' : 'show']();
    }
}