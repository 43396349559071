import Widget from '../Widget';

/**
 * @description Widget which handles the syncing of the general search suggestions
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class searchSuggestions extends Widget {
    init () {
        this.type = this.getType();
        this.eventBus().on('sync.menu.suggestions', 'syncSuggestions');
    }

    prefs () {
        return {
            generalSearchContainerRef: 'self',
            ...super.prefs()
        };
    }

    getType () {
        if (this.ref(this.prefs().generalSearchContainerRef).els[0].classList.contains('default-general-suggestions')) {
            return 'preloaded';
        }

        return 'current';
    }

    syncSuggestions (data) {
        if (data.type === this.type) {
            this.ref(this.prefs().generalSearchContainerRef).show();

            if (this.type === 'current') {
                this.ref(this.prefs().generalSearchContainerRef).els[0].innerHTML = data.markup;
            }
        } else {
            this.ref(this.prefs().generalSearchContainerRef).hide();
        }
    }
}