import Widget from '../Widget';
import { setCurrentToken } from '../toolbox/ajax';

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class Token extends Widget {
    init() {
        setCurrentToken(this.config.tokenName, this.config.tokenValue);
    }
}