import { RefElement } from 'widgets/toolbox/RefElement';
import customSelect from 'custom-select';
import BasicInput from 'widgets/forms/BasicInput';

/**
 * @description Implementation
 */
export default class InputSelect extends BasicInput {
    prefs() {
        return {
            custom: true,
            classesEmpty: 'is-empty',
            ...super.prefs()
        };
    }
    init() {
        super.init();

        if (this.prefs().custom) {
            this.initCustom();
        }
    }
    initCustom() {
        customSelect(this.ref('field').get());
        this.customSelect = this.ref('field').prop('customSelect');
        if (this.customSelect) {
            this.checkCustomSelect();
            this.customSelect.select.addEventListener('change', () => {
                this.checkCustomSelect();
            });
            if (this.customSelect.select.dataset.eventBlur) {
                this.customSelect.opener.addEventListener('blur', () => {
                    this.data(this.customSelect.select.dataset.eventBlur);
                });
            }
        }
    }
    checkCustomSelect() {
        if (!this.customSelect.select.value) {
            this.customSelect.container.classList.add(this.prefs().classesEmpty);
        } else {
            this.customSelect.container.classList.remove(this.prefs().classesEmpty);
        }
    }
    /**
     * @description change selected value in the custom select
     * @param {String} newVal new value for custom select
     */
    setCustomSelectValue(newVal) {
        if (this.customSelect) {
            this.customSelect.value = newVal;
        }
    }
    /**
     * @param {import('widgets/toolbox/RefElement').RefElement|import('widgets/Widget').default} el source of event
     * @param {Event|undefined} event event instance if DOM event
     */
    onChange(el, event) {
        if (!this.config.dontPrevent && event && event instanceof Event) {
            event.preventDefault();
        }

        this.update();
    }
    /**
     * @param {string} newVal new value
     * @param {boolean} silently validate after change
     */
    setValue(newVal, silently) {
        if (this.initValue && newVal === void 0) {
            newVal = this.initValue;
        }
        super.setValue(newVal, silently);
        this.setCustomSelectValue(newVal);
    }
    getValue() {
        const selectedOption = this.getSelectedOptions();
        if (selectedOption) {
            if (selectedOption.length) {
                return /** @type {string} */(selectedOption.val());
            }
            const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

            if (field && field.options.length) {
                const item = field.options.item(0);
                if (item) {
                    return item.value;
                }
            }
        }
        return '';
    }
    getSelectedOptions() {
        const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

        if (field) {
            return new RefElement([field.options[field.selectedIndex]]);
        }

        return null;
    }
    getText() {
        const field = this.ref('field').get();

        if (field) {
            const selectedNode = Object.values(field.childNodes)
                .find(node => /** @type {HTMLOptionElement} */(node).selected);

            if (selectedNode) {
                return selectedNode.textContent || '';
            }
            return '';
        }
        return '';
    }
    lock() {
        if (!this.locked) {
            super.lock();
            this.ref('field').attr('disabled', true);
            // this.$lockInput =
            // $(`<input class="${lockClass}" type="hidden" name="${this.getName()}" value="${this.getValue()}"/>`);
            // this.$field.append(this.$lockInput);
        }
    }
    unlock() {
        if (this.locked) {
            super.unlock();
            this.ref('field').attr('disabled', false);
            // this.$lockInput.remove();
        }
    }
}