import Widget from 'widgets/Widget';
import { swipeEvent } from 'widgets/toolbox/util';

/**
 * @description Base button implementation
 */
export default class SwipeEvent extends Widget {
    prefs() {
        return {
            swipeRef: 'self',
            ...super.prefs()
        };
    }
    init() {
        if (this.has(this.prefs().swipeRef)) {
            swipeEvent(this.ref(this.prefs().swipeRef));
        }
        super.init();
    }
}
