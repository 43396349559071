import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import AjaxForm from './AjaxForm';

/**
 * @description Base button implementation
 */
export default class firstLastNameForm extends AjaxForm {
    init() {
        super.init();
        this.eventBus().on('submit.click.and.collect', 'submitClickAndCollect');
    }

    /**
     * @param {widget} checkoutMgrWidget determine the widgetObject
     */
    submitClickAndCollect(checkoutMgrWidget) {
        if (this.validate() && checkoutMgrWidget.selectedStoreData) {
            this.handleSubmit(null, null, {storeData: JSON.stringify({...checkoutMgrWidget.selectedStoreData, ...this.getFormFields()})});
        }
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }
}