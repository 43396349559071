import { getCookie, setCookie, checkIfCookiesEnabled } from 'widgets/toolbox/cookie';
import Widget from '../Widget';

/**
 * @description Cookies acceptance implementation
 */
export default class CookiesAcceptance extends Widget {
    prefs() {
        return {
            cookieName: 'cookiesAccepted',
            ...super.prefs()
        };
    }
    init() {
        if (checkIfCookiesEnabled() && !getCookie(this.prefs().cookieName)) {
            this.ref('self').show();
        }
    }
    accept() {
        setCookie(this.prefs().cookieName, 'true');
        this.ref('self').hide();
    }
}