import ToggleButton from '../global/ToggleButton';

/**
 * @description search dropdown toggle
 */

const inputSelector = '.b-header-search__input.searchbar-input';

export default class SearchToggleButton extends ToggleButton {
    init() {
        this.input = document.querySelector(`${this.config.toggleContent} ${inputSelector}`);
        super.init();
    }

    openNavigation () {
        this.eventBus().emit('open.nav.search');
    }

    toggleContent() {
        if (super.getState()) {
            super.close(true, false);
            this.input.blur();

            this.eventBus().emit('mobile.search.hide');
        } else {
            this.open(true, false);
            this.input.focus();
            this.eventBus().emit('search.carousel.init');
        }
    }
    handleClick(el, event) {
        var headerNav = this.ref('self').attr('data-headernavelement');
        if (headerNav) {
            this.eventBus().emit('navbar.click', 'Header', headerNav);
        } else {
            this.eventBus().emit('navbar.click', 'Footer', this.ref('self').attr('data-footernavelement'));
        }
    }
}