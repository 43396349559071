import viewtype from 'widgets/toolbox/viewtype';
import Widget from '../Widget';

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 */
function getDistance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(Math.abs(x1 - x2), 2) + Math.pow(Math.abs(y1 - y2), 2));
}

/**
 * @description image carousel implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ImageZoom extends Widget {
    prefs() {
        return {
            classesEnabled: 'm-zoom-enabled',
            classesMax: 'm-zoom-max',
            ...super.prefs()
        };
    }

    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);

        this.enable = false;
        this.allowStateChange = true;

        this.zoom = window.directZoomEnabledInModal ? 0.5 : 1;
        this.zoomStep = 0.5;
        this.zoomMax = 1;
        this.zoomRatio = 2;

        this.mouseSpeed = 1;
        this.pinchSpeed = 3;

        this.imageWidth = 0;
        this.imageHeight = 0;
        this.imageWidthInit = 0;
        this.imageWidthCurrent = 0;
        this.imageHeightCurrent = 0;
    }

    init() {
        this.setup();
        this.bindEvents();
    }

    setup() {
        this.ref('self').get().style.lineHeight = this.ref('self').prop('clientHeight') + 'px';
    }

    /**
     * @param {any} el
     * @param {Event} e
     * @param {boolean} useInitSize
     */
    enableZoom(el, e, useInitSize = false) {
        if (this.enable || !this.allowStateChange) {
            return;
        }

        const imageElement = this.ref('image').get();

        if (imageElement) {
            this.allowStateChange = false;

            const elementOffset = this.ref('image').offset();
            const elementWidth = this.ref('image').prop('clientWidth');
            const elementHeight = this.ref('image').prop('clientHeight');

            this.imageWidthInit = elementWidth;

            this.ref('self').addClass(this.prefs().classesEnabled);

            this.setupImage();
            this.applyZoom(el, e, useInitSize, elementOffset, elementWidth, elementHeight);

            this.emit('enable');
            this.enable = true;
            this.allowStateChange = true;
        }
    }

    disableZoom() {
        if (!this.enable || !this.allowStateChange) {
            return;
        }

        const imageElement = this.ref('image').get();

        if (imageElement) {
            this.allowStateChange = false;

            this.ref('self').removeClass(this.prefs().classesEnabled);
            this.ref('self').toggleClass(this.prefs().classesMax);
            imageElement.style.width = '';

            this.emit('disable');
            this.enable = false;
            this.allowStateChange = true;
        }
    }

    toggleZoom() {
        this[this.enable ? 'disableZoom' : 'enableZoom'].apply(this, arguments);
    }

    handleZoom() {
        if (this.allowStateChange) {
            if (this.enable) {
                if (this.zoom < this.zoomMax) {
                    this.zoom += this.zoomStep;
                    this.applyZoom.apply(this, arguments);
                } else {
                    this.disableZoom.apply(this, arguments);
                }
            } else {
                this.zoom = this.zoomStep;
                this.enableZoom.apply(this, arguments);
            }
        }
    }

    /**
     * @param {any} el
     * @param {Event} e
     * @param {boolean} useInitSize
     * @param {Object} imageOffset
     * @param {number} imageWidth
     * @param {number} imageHeight
     */
    applyZoom(el, e, useInitSize, imageOffset, imageWidth, imageHeight) {
        var clickX = 0.5;
        var clickY = 0.5;

        if (e) {
            const elementOffset = imageOffset || this.ref('image').offset();
            const elementWidth = imageWidth || this.ref('image').prop('clientWidth');
            const elementHeight = imageHeight || this.ref('image').prop('clientHeight');
            let clientX = 0;
            let clientY = 0;
            if (e.touches && e.touches.length > 0) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else if (e.changedTouches && e.changedTouches.length > 0) {
                clientX = e.changedTouches[0].clientX;
                clientY = e.changedTouches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }
            clickX = (clientX - elementOffset.left) / elementWidth;
            clickY = (clientY - elementOffset.top) / elementHeight;
        }

        if (useInitSize) {
            this.imageWidthCurrent = this.imageWidthInit;
        } else {
            this.imageWidthCurrent = this.imageWidth * this.zoom;
        }
        this.imageHeightCurrent = this.imageWidthCurrent * this.imageRatio;
        this.ref('image').get().style.width = this.imageWidthCurrent + 'px';

        this.ref('self').prop('scrollLeft', (this.ref('self').prop('scrollWidth') - this.ref('self').prop('clientWidth')) * clickX);
        this.ref('self').prop('scrollTop', (this.ref('self').prop('scrollHeight') - this.ref('self').prop('clientHeight')) * clickY);

        this.ref('self').toggleClass(this.prefs().classesMax, this.zoom >= this.zoomMax);
    }

    setupImage() {
        const imageElement = this.ref('image').get();

        if (imageElement) {
            const image = imageElement.getElementsByTagName('img')[0];

            if (image) {
                image.style.width = 'auto';
                image.style.maxWidth = 'none';

                this.imageWidth = image.clientWidth;
                this.imageHeight = image.clientHeight;
                this.imageRatio = this.imageHeight / this.imageWidth;

                if (imageElement.parentElement) {
                    if (this.imageWidth < imageElement.parentElement.clientWidth * this.zoomRatio) {
                        this.imageWidth = imageElement.parentElement.clientWidth * this.zoomRatio;
                        this.imageHeight = this.imageWidth * this.imageRatio;
                    }
                    if (this.imageHeight < imageElement.parentElement.clientHeight * this.zoomRatio) {
                        this.imageHeight = imageElement.parentElement.clientHeight * this.zoomRatio;
                        this.imageWidth = this.imageHeight / this.imageRatio;
                    }
                }

                image.style.width = '';
                image.style.maxWidth = '';
            }
        }
    }

    /**
     * @param {String} imageSrc
     */
    imageLoaded(imageSrc) {
        if (!window.directZoomEnabledInModal) {
            return;
        }

        if (imageSrc === this.config.src) {
            this.enableZoom.apply(this, arguments);
        }
    }

    bindEvents() {
        this.eventBus().on('viewtype.windowChange', 'setup');
        this.eventBus().on('imageLoaded', 'imageLoaded');

        if (viewtype.isTouchDevice()) {
            this.bindTouchEvents();
        } else {
            this.bindMouseEvents();
        }
    }

    bindMouseEvents() {
        const zoomElement = this.ref('self').get();
        const imageElement = this.ref('image').get();

        if (zoomElement && imageElement) {
            let downX = 0;
            let downY = 0;
            let downLeft = 0;
            let downTop = 0;
            let isDown = false;

            this.ev('click', (el, e) => {
                this.handleZoom(el, e);
            }, imageElement, false);

            this.ev('mousedown', (el, e) => {
                if (!this.enable) {
                    return;
                }
                this.allowStateChange = true;
                isDown = true;
                downX = e.pageX;
                downY = e.pageY;
                downLeft = el.scrollLeft;
                downTop = el.scrollTop;
            }, null, false);

            this.ev('mousemove', (el, e) => {
                if (!isDown) {
                    return;
                }
                e.preventDefault();
                this.allowStateChange = false;

                zoomElement.scrollLeft = downLeft + (downX - e.pageX) * this.mouseSpeed;
                zoomElement.scrollTop = downTop + (downY - e.pageY) * this.mouseSpeed;
            }, null, false);

            this.ev('mouseleave', () => {
                isDown = false;
            }, null, false);

            this.ev('mouseup', () => {
                isDown = false;
            }, null, false);
        }
    }

    bindTouchEvents() {
        const zoomElement = this.ref('self').get();
        const imageElement = this.ref('image').get();

        if (zoomElement && imageElement) {
            let downX = 0;
            let downY = 0;
            let downX2 = 0;
            let downY2 = 0;

            let latestTap = 0;
            let doubleTap = false;

            this.ev('touchstart', (el, e) => {
                e.preventDefault();
                const now = new Date().getTime();
                if ((now - latestTap) < 600) {
                    if (e.touches.length < 2) {
                        doubleTap = true;
                        latestTap = 0;
                    }
                } else {
                    latestTap = now;
                }
            }, imageElement, false);

            this.ev('touchmove', (el, e) => {
                e.preventDefault();
                this.allowStateChange = false;

                if (downX > 0) {
                    if (e.touches.length > 1) {
                        doubleTap = false;

                        // handle pinch
                        const diff = getDistance(e.touches[0].clientX, e.touches[0].clientY, e.touches[1].clientX, e.touches[1].clientY) -
                                        getDistance(downX, downY, downX2, downY2);

                        if (!this.enable) {
                            this.allowStateChange = true;
                            this.enableZoom(el, e, true);
                        }

                        const newImageWidth = this.imageWidthCurrent + diff * this.pinchSpeed;

                        if (newImageWidth > this.imageWidth) {
                            return;
                        }

                        if (newImageWidth < this.imageWidthInit) {
                            this.allowStateChange = true;
                            this.disableZoom();
                            return;
                        }

                        const newImageHeight = newImageWidth * this.imageRatio;

                        imageElement.style.width = newImageWidth + 'px';
                        zoomElement.scrollLeft += (newImageWidth - this.imageWidthCurrent) * (e.touches[0].clientX + (e.touches[1].clientX - e.touches[0].clientX) / 2) / zoomElement.clientWidth;
                        zoomElement.scrollTop += (newImageHeight - this.imageHeightCurrent) * (e.touches[0].clientY + (e.touches[1].clientY - e.touches[0].clientY) / 2) / zoomElement.clientHeight;

                        this.imageWidthCurrent = newImageWidth;
                        this.imageHeightCurrent = newImageHeight;
                    } else {
                        // handle swipe
                        zoomElement.scrollLeft += (downX - e.touches[0].clientX) * this.mouseSpeed;
                        zoomElement.scrollTop += (downY - e.touches[0].clientY) * this.mouseSpeed;
                    }
                }

                downX = e.touches[0].clientX;
                downY = e.touches[0].clientY;
                if (e.touches[1]) {
                    downX2 = e.touches[1].clientX;
                    downY2 = e.touches[1].clientY;
                }
            }, null, false);

            this.ev('touchend', (el, e) => {
                e.preventDefault();
                if (doubleTap) {
                    this.toggleZoom(el, e);
                }
                doubleTap = false;
                downX = 0;
                downY = 0;
                downX2 = 0;
                downY2 = 0;
                this.allowStateChange = true;
            }, null, false);
        }
    }
}