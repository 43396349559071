import { RefElement } from 'widgets/toolbox/RefElement';
import Widget from '../Widget';
/**
 * @description Emit event for eventBus implementation
 */
export default class ZmagsAnalytics extends Widget {
    prefs() {
        return {
            adobeEnabled: this.config.sitePreferences.ZMAGS_ADOBE_ANALYTICS_ENABLED,
            googleEnabled: this.config.sitePreferences.ZMAGS_GOOGLE_ANALYTICS_ENABLED,
            ZMAGSSOURCE: 'creator',
            zmagsSettings: window.__zmags.demandware.analytics.creator.settings,
            zmagsData: window.__zmags.demandware.analytics.creator.data,
            elements: {
                dummyAnchor: 'zmagsQuickViewDummyAnchor', // ID
                quickViewModal: 'quickViewModal', // ID
                checkoutButtons: '.minicart .checkout-btn, .cart .checkout-btn'
            },
            //taken from zmagscreatoranalytics.isml
            zmags: window.__zmags,
            ...super.prefs()
        };
    }

    init() {
        const { adobeEnabled, googleEnabled } = this.prefs();

        if (adobeEnabled) {
            this.initAdobeAnalytics();
        }

        if (googleEnabled) {
            this.initGoogleAnalytics();
        }

        if (adobeEnabled || googleEnabled) {
            this.initializeAnalytics();
        }
    }

    initAdobeAnalytics() {
        const s_account = window.__zmags.demandware.analytics.creator.settings.adobeAnalyticsRsid;
        const s_trackingServer = window.__zmags.demandware.analytics.creator.settings.adobeAnalyticsTrackingServer;
        const s = window.s_gi(s_account);
        s.account = s_account;
        s.trackingServer = s_trackingServer;
        s.trackingServerSecure = s.trackingServer;
        s.trackDownloadLinks = true;
        s.trackExternalLinks = true;
        s.trackInlineStats = true;
        s.linkDownloadFileTypes = 'exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx';
        s.linkInternalFilters = 'javascript:'; //optional: add your internal domain here
        s.linkLeaveQueryString = false;
        s.linkTrackVars = 'None';
        s.linkTrackEvents = 'None';
        // @ts-ignore
        window.__zmags.demandware.analytics.creator.AppMeasurementInstance = s;
    }

    initGoogleAnalytics() {
        // @ts-ignore
        if (!window.ga) {
            // @ts-ignore
            (function(i,s,o,g,r,a,m) {i['GoogleAnalyticsObject']=r;i[r]=i[r]||function() {
            // @ts-ignore
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            // @ts-ignore
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
        }
        // create tracker if it wasn't created already
        // @ts-ignore
        ga(function() {
            // @ts-ignore
            if (!ga.getByName('creator')) {
                // @ts-ignore
                ga('create', window.__zmags.demandware.analytics.creator.settings.googleAnalyticsId, 'auto', {'name': 'creator'});
            }
        });
    }

    /**
     * Actual tracking requests to Google and/or Adobe are issued here
     *
     * @param {string} action The event to track
     * @param {string} zmagsSource Cartridge identifier
     * @param {string|null} pid Product ID
     * @param {number} qty Quantity
     */
    trackZmagsEvent(action, zmagsSource, pid, qty) {
        const { ZMAGSSOURCE, adobeEnabled, googleEnabled } = this.prefs();
        /**
         * Clear tracking data
         *
         * @param {Object} s AppMeasurement instance
         * @returns {Object} AppMeasurement instance
         */
        function clearAdobeVars(s) {
            const result = s;
            const svarArr = ['pageName', 'channel', 'products', 'events', 'campaign', 'purchaseID', 'state', 'zip', 'server', 'linkName'];

            for (let i = 0; i < 75; i++) {
                result['prop' + i] = '';
                result['eVar' + i] = '';
                if (i <= 5) {
                    result['hier' + i] = '';
                }
            }

            for (let i = 0; i < svarArr.length; i++) {
                result[svarArr[i]] = '';
            }

            return result;
        }

        const label = pid ? pid + ', ' + zmagsSource : zmagsSource;
        const category = 'Zmags DW cartridge';

        if (googleEnabled) {
            // Google Analytics:
            if (qty) {
                window.ga(ZMAGSSOURCE + '.send', 'event', category, action, label, qty);
            } else {
                window.ga(ZMAGSSOURCE + '.send', 'event', category, action, label);
            }
        }

        if (adobeEnabled) {
            // Adobe Analytics:
            let s = window.__zmags.demandware.analytics[ZMAGSSOURCE].AppMeasurementInstance;
            s = clearAdobeVars(s);
            s.channel = category;
            s.pageName = action;
            s.prop1 = ZMAGSSOURCE;
            s.prop2 = action;
            s.prop3 = label;
            if (pid) {
                s.products = pid;
            }
            if (qty) {
                s.prop4 = qty;
            }
            let scode = s.t();
            if (scode) {
                document.write(scode);
            }
        }
    }

    /**
     * Track opening of QuickView
     *
     * @param {string} pid Product ID
     * @param {string} zmagsSource Cartridge identifier
     */
    trackQuickviewOpened(pid, zmagsSource) {
        const action = 'Zmags QuickView Launched';
        this.trackZmagsEvent(action, zmagsSource, pid, 0);
    }

    /**
     * Track adding a product to cart from quickview openened by Zmags
     *
     * @param {string} pid Product ID
     * @param {string} zmagsSource Cartridge identifier
     * @param {number} qty Quantity
     */
    trackAddToCart(pid, zmagsSource, qty) {
        const action = 'Zmags Add To Cart';
        this.trackZmagsEvent(action, zmagsSource, pid, qty);
    }

    /**
     * Track viewing the cart page
     */
    trackCartViewed() {
        const action = 'Zmags Cart Viewed';
        this.trackZmagsEvent(action, this.prefs().zmagsData.zmagsSource, null, this.prefs().zmagsData.qty);
    }

    /**
     * Track starting checkout
     */
    trackGoToCheckout() {
        const action = 'Zmags Checkout';
        this.trackZmagsEvent(action, this.prefs().zmagsData.zmagsSource, null, this.prefs().zmagsData.qty);
    }

    /**
     * Track succefully placed order
     */
    trackOrderPlaced() {
        const action = 'Zmags Order Placed';
        this.trackZmagsEvent(action, this.prefs().zmagsData.zmagsSource, null, this.prefs().zmagsData.qty);
    }

    /**
     * Retrieves the parameter value for name from url.
     * e.g. getParameterByName('abc?x=123&y=456', 'x') returns '123'
     *
     * @param {string} url Request URL
     * @param {string} name Parameter name
     * @returns {string|null} Parameter value
     */
    getParameterByName(url, name) {
        let query = null;
        const splittedUrl = url.split('?');
        if (splittedUrl.length > 1) {
            query = splittedUrl[1];
        } else {
            query = url;
        }
        query = query.split('&');

        let parameterValue = null;
        let token = null;

        for (let i = 0; i < query.length; i++) {
            const value = query[i];
            token = value.split('=');
            if (token.length > 1) {
                let key = decodeURIComponent(token[0]);
                if (key === name) {
                    parameterValue = decodeURIComponent(token[1]);
                    break;
                }
            }
        }

        return parameterValue;
    }

    /**
     * Expose a quickview object to be used by Zmags publications
     *
     * The quickview cam be opened like this:
     * __zmags.demandware.quickview.show({queryString: '?zmagssrc=creator&pid=25697561M'});
     */
    exposeQuickview() {
        const quickview = (window.__zmags && window.__zmags.demandware && window.__zmags.demandware.quickview) || null;
        const { zmagsSettings } = this.prefs();

        if (!quickview) {
            window.__zmags = window.__zmags || {};
            window.__zmags.demandware = window.__zmags.demandware || {};
            window.__zmags.demandware.quickview = {
                /**
                 * Show QuickView
                 *
                 * @param {Object} options parameters
                 * @param {string} options.queryString URL parameters for product detail controller
                 */
                show: (options) => {
                    const url = zmagsSettings.quickViewUrl + options.queryString;
                    const pid = this.getParameterByName(url, this.config.Constants.PID);
                    const zmagsSource = this.getParameterByName(url, 'zmagssrc');
                    const elements = this.prefs().elements;

                    // create a dummy element which we'll use to trigger the storefront's quickview
                    let dummyNode =  new RefElement(document.getElementById(elements.dummyAnchor)).get();
                    if (dummyNode.length !== 0) {
                        dummyNode.remove();
                    }
                    const html =
                        '<a id="zmagsQuickViewDummyAnchor" class="quickview" style="display:none">' +
                            '<div id="zmagsQuickViewDummyDiv">the quickview is triggered by simulating a click on this div</div>' +
                        '</a>';

                    const bodyRef = new RefElement(document.getElementsByTagName('body')).get();
                    bodyRef.append(html);
                    dummyNode = new RefElement(document.getElementById(elements.dummyAnchor)).get();
                    dummyNode.attr('href', url);
                    dummyNode.click();

                    // track
                    this.trackQuickviewOpened(pid, zmagsSource);

                    // store Zmags source once quickview element exists
                    const interval = setInterval(function () {
                        const quickViewRef = new RefElement(document.getElementById(this.prefs().elements.quickViewModal)).get();
                        if (quickViewRef.length) {
                            clearInterval(interval);
                            quickViewRef.data('zmagssource', zmagsSource);
                        }
                    }, 50);
                }
            };
        }
    }

    /**
     * Determine which page is being displayed
     *
     * @returns {string|null} page type
     */
    getPageType() {
        let result = null;
        const element = new RefElement(document.querySelector('.page .cart-page')).get();
        if (element.length > 0) {
            // cart page with items in basket
            result = 'cart';
        } else if (new RefElement(document.querySelector('.page .receipt .confirm-details')).get()) {
            // order placed
            result = 'orderconfirmation';
        }
        return result;
    }

    initializeAnalytics() {
        this.exposeQuickview();
        // zmagsData.zmagsSource is only set if the basket or order contains
        // a product added from Zmags quickview
        if (this.prefs().zmagsData.zmagsSource === this.prefs().ZMAGSSOURCE) {
            // -> basket/order contains product added from Zmags quickview
            var pageType = this.getPageType();
            if (pageType === 'cart') {
                this.trackCartViewed();
            } else if (pageType === 'orderconfirmation') {
                this.trackOrderPlaced();
            }

            // track checkout button clicks
            const body = document.getElementsByTagName('body');

            if (body.length) {
                const bodyRef = new RefElement([document.getElementsByTagName('body')[0]]);
                bodyRef.on('click', this.prefs().elements.checkoutButtons, this.trackGoToCheckout);
            }
        }
    }
}
