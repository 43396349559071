import { getScrollTop } from 'widgets/toolbox/scroll';
import Widget from '../Widget';

/**
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */

export default class InfiniteScrollLoadedContent extends Widget {
    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);
        /** @type {number} */
        this.itemsShown = 0;
    }

    prefs() {
        return {
            items: [],
            contentList: 'contentList',
            initialItemsShown: 12,
            ...super.prefs()
        };
    }

    init() {
        super.init();

        if (this.prefs().items) {
            this.renderNextItems(this.prefs().initialItemsShown);
        }
    }

    /**
     * @param {object[]} items
     */
    renderItems(items) {
        this.render('template', {
            items: items
        });
    }

    renderNextItems(itemsCount = this.config.sitePreferences.DEFAULT_PAGE_SIZE) {
        this.itemsShown = this.itemsShown + itemsCount;
        this.renderItems(this.prefs().items.slice(0, this.itemsShown));
        this.checkInfinite();
    }

    checkInfinite() {
        if (this.prefs().items.length > this.itemsShown) {
            if (!this.cleanUpScroll) {
                const scrollHandler = () => this.loadMore();
                window.addEventListener('scroll', scrollHandler);
                this.cleanUpScroll = () => {
                    window.removeEventListener('scroll', scrollHandler);
                };
            }
            this.loadMore();
        } else if (this.cleanUpScroll) {
            this.cleanUpScroll();
            this.cleanUpScroll = void 0;
        }
    }

    loadMore() {
        this.has(this.prefs().contentList, contentList => {
            const htmlHeight = this.ref('html').prop('clientHeight');
            const listHeight = contentList.prop('scrollHeight');

            if (
                listHeight > 0 &&
                getScrollTop() + htmlHeight >= listHeight - htmlHeight
            ) {
                this.renderNextItems();
            }
        });
    }
}