export function throttle(mainFunction, delay) {
    let timerFlag = null; // Variable to keep track of the timer
    let executeWhenDone = null;
    const timerFn = () => {
        timerFlag = null;
        if (executeWhenDone) {
            executeWhenDone();
        }
    }

    // Returning a throttled version 
    return (...args) => {
        if (timerFlag === null) { // If there is no timer currently running
            mainFunction(...args); // Execute the main function 
            executeWhenDone = null;
            timerFlag = setTimeout(timerFn, delay);
        } else {
            executeWhenDone = () => mainFunction(...args);
        }
    };
}