import Widget from "../Widget";

export default class EmarsysCart extends Widget {
    init() {
        this.emarsysCartUpdate();
        this.eventBus().on('emarsys.update', 'emarsysCartUpdate'); //for PLP and Cart page
    }

    emarsysCartUpdate() {
        var dataUrl = document.getElementById('emarsysCartDataUrl').value;

        fetch(dataUrl).then(response => response.json())
            .then(data => {
                this.pushData(data.cartData);
            });
    }

    pushData(dataFromCart) {
        var cartData;
        var dataLayer = window.dataLayer || [];
        try {
            cartData = JSON.parse(dataFromCart);
        } catch (error) {
            cartData = null;
        }

        var cartIndex = dataLayer.findIndex((el) => {
            return el.cartEmarsys && !el.event;
        });

        var cartObject = {
            event: 'emarsysCart',
            cartEmarsys: cartData || []
        };

        if (cartIndex !== -1) {
            dataLayer[cartIndex] = cartObject;
        } else {
            dataLayer.push(cartObject);
        }
    }
}