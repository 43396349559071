import InputSelect from "./InputSelect";

export default class SendingDateInputSelect extends InputSelect {
    init() {
        const type = this.data('sendingDateType');
        this.eventBus().on(`update.option.fields.${type}`, 'updateOptionsFields');
        super.init();
    }
    updateOptionsFields(options) {
        this.render(options.template, options.data, this.ref(options.refEl).empty());
        // reinit rerendered options
        setTimeout(() => {
            super.init();
        }, 0);
    }
}