import { getContentByUrl } from 'widgets/toolbox/ajax';
import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { clickOutside } from 'widgets/toolbox/util';
import viewtype from 'widgets/toolbox/viewtype';
import Widget from '../Widget';

/**limitoff
 * @description Base button implementation
 */
export default class ReviewShow extends Widget {
    prefs() {
        return {
            classesShow: 'show',
            classActiveBullet: 'b-pagination__button--active',
            classesOpened: 'opened-reviews',
            elPopoverContent: 'popoverContentReview',
            elReview: 'b-product-rating',
            elPopoverFlyout: 'popoverFlyoutReview',
            elReviewContent: 'reviewsShow',
            elReviewEmptyWrapper: 'reviewEmptyWrapper',
            elReviewsShowWrapper: 'reviewsShowWrapper',
            elReviewHeading: 'reviewHeading',
            elBvPopupContent: 'bvPopupContent',
            ...super.prefs()
        };
    }

    init() {
        this.needUpdate = true;
        this.viewTypeChange();
        this.eventBus().on('reviews.show', 'reRenderReviewWrapper');
        this.eventBus().on('viewtype.change', 'viewTypeChange');
        this.eventBus().on('navigation.change', 'reRenderReviewWrapper');
        this.hideReviewsContent();
    }

    viewTypeChange() {
        this.isMobileView = viewtype.isMobileView();
        this.isDesktopView = viewtype.isDesktopView();
    }


    reRenderReviewWrapper() {
        this.needUpdate = true;
        this.showReviewPopover();
    }

    handleReviewForm() {

    }

    /**
     * @param {string} selected - selected option from the sort by dropdown list
     */
    reRenderReviewContent(selected, pageNumber) {
        pageNumber = parseInt(pageNumber, 10);
        this.showReviewProgressBar();
        var url = new URL(this.ref('self').els[0].dataset.actionUrl);
        var offset = this.config.sitePreferences.BV_DEFAULT_PAGE_SIZE;

        if (selected) {
            url.searchParams.set('sortBy', selected);
        }

        if (pageNumber && pageNumber !== 1) {
            url.searchParams.set('offset', (offset * (pageNumber - 1)));
            url.searchParams.set('page', pageNumber);
        }
        var modifiedUrl = getContentByUrl(url.toString());

        return modifiedUrl.then((response) => {
            this.ref(this.prefs().elReviewsShowWrapper).get().innerHTML = response;
            if (!selected && !pageNumber) {
                this.hideReviewsContent();
            }
            var idDropdown = '#' + selected;
            var select = this.ref('self').els[0].querySelector(idDropdown);

            if (select) {
                select.setAttribute('selected', true);
            }
        }).catch((error) => {
            console.warn('Failed to render', error);
        }).finally(() => {
            this.hideReviewProgressBar();
        });
    }

    /**
     * @param {HTMLElement} el - each pagination number when clicked on
     */
    navigationButtonClick(el) {
        var pageNumber = el.els[0].getAttribute('data-page');

        el.els[0].classList.add(this.prefs().classActiveBullet);
        this.reRenderReviewContent(null, pageNumber);
    }

    reviewAdded() {
        this.reRenderReviewWrapper();
    }

    reviewsShow() {
        this.reRenderReviewWrapper();
        this.showReviewPopover();
    }

    /**
     * @param {HTMLElement} el - DOM element that was clicked on from sort by dropdown
     */
    sortListClick(el) {
        var selected = el.els[0].value;
        this.reRenderReviewContent(selected);
    }

    cleanUpListener() {
        if (this.listener) {
            this.listener();
            this.listener = void 0;
        }
    }

    showReviewPopover() {
        disableScroll();
        this.isPopoverShow = true;
        this.ref('html').addClass(this.prefs().classesOpened);

        this.listener = clickOutside(this.ref('self'), () => {
            this.hideReviewPopover();
            return false;
        });

        if (this.needUpdate) {
            this.reRenderReviewContent();
        }
    }

    hideReviewPopover() {
        this.isPopoverShow = false;
        this.cleanUpListener();
        this.ref('html').removeClass(this.prefs().classesOpened);
        this.hideReviewsContent();
        enableScroll();
    }

    hideReviewsContent() {
        this.ref(this.prefs().elReviewsShowWrapper).toggleClass('hide');
    }

    showReviewProgressBar() {
        showPageLoader(this.ref(this.prefs().elPopoverFlyout).get(), 'inside');
    }

    hideReviewProgressBar() {
        hidePageLoader();
    }
}