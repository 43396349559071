import ImageCarousel from '../global/ImageCarousel';
import Widget from '../Widget';

/**
* @param {Number} n
* @param {Number} min
* @param {Number} max
 */
const clamp = (n, min, max) => {
    return Math.min(Math.max(n, min), max);
};

/**
 * @param {Touch} touch1
 * @param {Touch} touch2
 */
function calculateDistance(touch1, touch2) {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
}

/**
 * @description Product tile implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ProductPictureHoverZoom extends Widget {
    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);

        this.scale = 1;
        this.initialDistance = 0;
        this.startX = null;
        this.startY = null;
        this.lastY = 0;
        this.lastX = 0;
        this.pinching = false;
        this.dragging = false;

    }

    prefs() {
        return {
            classesShow: 'b-product-picture__zoom-container--show',
            zoom: 2.5,
            numberOfTilesVertical: 8,
            numberOfTilesHorizontal: 8,
            ...super.prefs()
        };
    }

    async init() {
        this.url = this.data('url').split('?')[0];
        this.grid = new Array(this.prefs().numberOfTilesVertical * this.prefs().numberOfTilesHorizontal).fill({ url: null });

        const height = this.data('image-natural-height');
        const width = this.data('image-natural-width');

        if (!height || !width) {
            console.log('No image dimensions available, disabling hover zoom');
        } else {
            this.dimensions = { w: width, h: height };
        }

        this.parentCarousel = this.parents.find(x => x instanceof ImageCarousel);
        this.isWithinVerticalCarousel = this.parentCarousel?.ref('self').els[0].querySelector('.vertical-carousel-wrapper').contains(this.ref('self').els[0]);
    }

    /**
     * @param {Number} indexX
     * @param {Number} indexY
     * @param {Number} containerW
     * @param {Number} containerH
     * @param {Number} scale
     */
    getTileURL(indexX, indexY, containerW, containerH, scale) {
        const cw = this.dimensions.w / this.prefs().numberOfTilesHorizontal;
        const ch = this.dimensions.h / this.prefs().numberOfTilesVertical;

        const cx = cw * (indexX);
        const cy = ch * (indexY);
        const sw = containerW / scale;
        const sh = containerH / scale;
        const url = this.url + '?cw=' + cw + '&ch=' + ch + '&cx=' + cx + '&cy=' + cy + '&sw=' + sw + '&sh' + sh;

        return url;
    }

    /**
     * @param {Number} mouseX
     * @param {Number} mouseY
     * @param {Number} scale
     */
    updateDisplayedTiles(mouseX, mouseY, scale) {
        const {
            numberOfTilesHorizontal: nth,
            numberOfTilesVertical: ntv
        } = this.prefs();

        const rect = this.ref('self').get()?.getBoundingClientRect() || { left: 0, top: 0, width: 0, height: 0 };
        const cw = rect.width;
        const ch = rect.height;
        const x = mouseX ? (mouseX - rect.left) : (cw / 2);
        const y = mouseY ? (mouseY - rect.top) : (ch / 2);

        const tw = cw / scale;
        const th = ch / scale;

        const fromX = (cw / 2 - tw / 2) * (x / (cw / 2));
        const toX = fromX + tw;
        const fromY = (ch / 2 - th / 2) * (y / (ch / 2));
        const toY = fromY + th;

        const fromXIndex = Math.floor(fromX / (cw / nth));
        const toXIndex = Math.floor(toX / (cw / nth));
        const fromYIndex = Math.floor(fromY / (ch / ntv));
        const toYIndex = Math.floor(toY / (ch / ntv));

        const horizontalRange = [
            clamp(fromXIndex, 0, nth - 1),
            clamp(toXIndex, 0, nth - 1)
        ];
        const verticalRange = [
            clamp(fromYIndex, 0, ntv - 1),
            clamp(toYIndex, 0, ntv - 1)
        ];

        for (let tileX = horizontalRange[0]; tileX <= horizontalRange[1]; tileX++) {
            for (let tileY = verticalRange[0]; tileY <= verticalRange[1]; tileY++) {
                const arrayIndex = tileY * ntv + tileX;

                if (!this.grid[arrayIndex].url) {
                    const url = this.getTileURL(tileX, tileY, cw, ch, scale);
                    this.grid[arrayIndex] = { url };
                }
            }
        }
    }

    /**
     * @param {Number} mouseX
     * @param {Number} mouseY
     * @param {Number} scale
     */
    updateGridPosition(mouseX, mouseY, scale) {

        const rect = this.ref('self').get()?.getBoundingClientRect() || { left: 0, top: 0, width: 0, height: 0 };

        let x, y;
        if (this.dragging || this.pinching) {
            x = rect.width - (mouseX - rect.left);
            y = rect.height - (mouseY - rect.top);
        } else {
            x = mouseX - rect.left;
            y = mouseY - rect.top;
        }

        this.gridX = -((scale - 1) * x);
        this.gridY = -((scale - 1) * y);
    }

    hoverZoomDisabled() {
        return (!this.dimensions || !window.hoverZoomEnabledOnPdp || (this.parentCarousel && this.parentCarousel.disableHoverVerticalZoom && this.isWithinVerticalCarousel));
    }

    /**
     * @param {Number} scale
     */
    renderGrid(scale) {
        this.render('template', {
            grid: this.grid,
            gridX: this.gridX,
            gridY: this.gridY,
            zoom: scale,
            numberOfTilesVertical: this.prefs().numberOfTilesVertical,
            numberOfTilesHorizontal: this.prefs().numberOfTilesHorizontal,
            lowResUrl: this.config.lowResUrl
        }, this.ref('zoom-grid'), '', ['{*', '*}']);
    }

    /**
     * @param {any} el RefElement
     * @param {MouseEvent} event event instance
     */
    onMouseEnter(el, event) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        this.hovering = true;

        el.addClass(this.prefs().classesShow);
    }

    /**
     * @param {any} el RefElement
     */
    onMouseLeave(el) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        this.hovering = false;

        el.removeClass(this.prefs().classesShow);
    }

    /**
     * @param {any} el RefElement
     * @param {MouseEvent} event event instance
     */
    onMouseMove(el, event) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        if (!this.hovering) {
            return;
        }

        this.updateDisplayedTiles(event.clientX, event.clientY, this.prefs().zoom);
        this.updateGridPosition(event.clientX, event.clientY, this.prefs().zoom);
        this.renderGrid(this.prefs().zoom);
    }

    /**
     * @param {any} el RefElement
     * @param {TouchEvent} event event instance
     */
    onTouchStart(el, event) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        if (this.startX === null || this.startY === null) {
            const rect = this.ref('self').get()?.getBoundingClientRect() || { width: 0, height: 0 };
            this.startX = rect.width / 2;
            this.startY = rect.height / 2;
        }

        if (event.touches.length === 2) {
            event.preventDefault();
            event.stopPropagation();
            this.pinching = true;

            this.initialDistance = calculateDistance(event.touches[0], event.touches[1]);

            this.lastX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
            this.lastY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
        } else if (event.touches.length === 1) {
            if (this.scale === 1) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();

            this.dragging = true;

            this.lastX = event.touches[0].clientX;
            this.lastY = event.touches[0].clientY;
        }

        el.addClass(this.prefs().classesShow);
    }

    /**
     * @param {any} el RefElement
     * @param {TouchEvent} event event instance
     */
    onTouchMove(el, event) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        if (event.touches.length === 2) {
            if (!this.pinching) {
                return;
            }

            const currentDistance = calculateDistance(event.touches[0], event.touches[1]);
            const deltaDistance = currentDistance - this.initialDistance;
            const scale = this.scale + deltaDistance * 0.005;

            this.scale = clamp(scale, 1, 2);
            this.initialDistance = currentDistance;

            const midpointDeltaX = (event.touches[0].clientX + event.touches[1].clientX) / 2 - this.lastX;
            const midpointDeltaY = (event.touches[0].clientY + event.touches[1].clientY) / 2 - this.lastY;

            this.updateDisplayedTiles(this.startX + midpointDeltaX, this.startY + midpointDeltaY, this.scale);
            this.updateGridPosition(this.startX + midpointDeltaX, this.startY + midpointDeltaY, this.scale);

            this.lastX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
            this.lastY = (event.touches[0].clientY + event.touches[1].clientY) / 2;

            this.startX += midpointDeltaX;
            this.startY += midpointDeltaY;
        } else if (event.touches.length === 1) {
            if (!this.dragging) {
                return;
            }

            const deltaX = event.touches[0].clientX - this.lastX;
            const deltaY = event.touches[0].clientY - this.lastY;

            const rect = this.ref('self').get()?.getBoundingClientRect() || { left: 0, top: 0, width: 0, height: 0 };

            const newX = clamp(this.startX + deltaX, rect.left, rect.width + rect.left);
            const newY = clamp(this.startY + deltaY, rect.top, rect.height + rect.top);

            this.updateDisplayedTiles(newX, newY, this.scale);
            this.updateGridPosition(newX, newY, this.scale);

            this.lastX = event.touches[0].clientX;
            this.lastY = event.touches[0].clientY;

            this.startX = newX;
            this.startY = newY;
        }

        this.renderGrid(this.scale);
    }

    /**
     * @param {any} el RefElement
     * @param {TouchEvent} event event instance
     */
    onTouchEnd(el, event) {
        if (this.hoverZoomDisabled()) {
            return;
        }

        event.preventDefault();

        this.pinching = false;
        this.dragging = false;
    }
}