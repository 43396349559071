import Widget from '../Widget';

/**
 * @description Home implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class Home extends Widget {
    constructor(el, config = {}) {
        super(el, config);
    }

    init() {
        this.emitPromoListing();
        window.addEventListener('scroll', this.emitPromoListing.bind(this));
    }

    emitPromoListing() {
        this.eventBus().emit('promotion.listing.init');
    }
}