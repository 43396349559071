import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';
import { timeout } from '../toolbox/util';
import ProductTile from './ProductTile';

/**
 * @description Product tile implementation
 */
export default class QuickBuy extends ProductTile {
    prefs() {
        return {
            classesUnavailable: 'unavailable',
            classesSelected: 'selected',
            ...super.prefs()
        };
    }

    init() {
        this.ajaxInProgress = false;
        this.resetVariationStatus();
    }

    // event for add to cart button
    tileAddToCartButton(el) {
        var pid = el.data('pid');
        if (pid) {
            this.ajaxInProgress = true;
            this.addToCart(pid);
        }
    }

    // event for select length in quick buy block
    selectVariation(el) {
        if (this.ajaxInProgress || !el.data('selectable')) {
            return;
        }

        this.selectedAttributes[el.data('variationId')] = el.data('variationValue');
        el.addClass(this.prefs().classesSelected);

        this.updateVariationStatus();
    }

    // get selected variation parameters
    getVariationParams() {
        var params = {
            pid: this.prefs().masterPid,
            quantity: 1
        };

        if (this.prefs().selectedColor) {
            params['dwvar_' + this.prefs().masterPid + '_color'] = this.prefs().selectedColor;
        }

        Object.entries(this.selectedAttributes).forEach(([attrId, attrValue]) => {
            params['dwvar_' + this.prefs().masterPid + '_' + attrId] = attrValue;
        });

        return params;
    }

    // reset variation selection in quick buy block
    resetVariationStatus() {
        this.selectedAttributes = {};

        this.ref('self').els[0].querySelectorAll('.b-variations__button').forEach(button => {
            button.classList.remove('selected');
        });
    }

    // update variations in quick buy block
    updateVariationStatus() {
        if (this.ajaxInProgress) {
            return;
        }

        if (this.prefs().productVariationUrl) {
            this.ajaxInProgress = true;
            getJSONByUrl(this.prefs().productVariationUrl, this.getVariationParams())
                .then((resp) => {

                    if (resp.product && resp.product.tileAttrsHtml) {
                        this.ref('self').get(0).innerHTML = resp.product.tileAttrsHtml;

                        // add to cart if all variations were selected
                        if (resp.product.allowAddToCart && resp.product.productType === 'variant' && resp.product.id && this.prefs().addToCartUrl) {
                            this.addToCart(resp.product);
                        } else {
                            this.ajaxInProgress = false;
                        }

                    } else {
                        this.ajaxInProgress = false;
                    }
                })
                .catch(() => {
                    this.ajaxInProgress = false;
                });
        }
    }

    // add product to cart
    addToCart(product) {
        var params = {
            pid: typeof product === 'object' ? product.id : product,
            quantity: 1
        };

        submitFormJson(this.prefs().addToCartUrl, params, 'POST')
            .then((response) => {
                this.ajaxInProgress = false;

                // clear selections
                this.resetVariationStatus();

                // update and show minicart
                if (response.cart) {
                    this.eventBus().emit('product.tile.quick.buy', response.cart);
                    this.eventBus().emit('cart.updated', response.cart);
                    this.eventBus().emit('emarsys.update');
                }

                // change button state
                this.ref('self').addClass(this.prefs().classesActive);
                timeout(() => {
                    this.ref('self').removeClass(this.prefs().classesActive);
                }, this.config.sitePreferences.QUICKBUY_TIME_TO_SHOW_ADDED);

                this.emit('added', response.cart, product);
            })
            .catch(() => {
                this.ajaxInProgress = false;
            });
    }
}