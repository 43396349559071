import Widget from '../Widget';

/**
 * @description Base button implementation
 */
export default class BaseLabel extends Widget {
    prefs() {
        return {
            /**
             * Events used to update template
             */
            listenEvents: '',
            classesError: 'm-error',
            target: 'self',
            clear: false,
            useHtml: false,
            ...super.prefs()
        };
    }
    init() {
        super.init();
        if (!this.id) {
            this.id = String(this.config.id);
        }
        this.shown = true;

        if (this.config.hidden) {
            this.hide();
        } else {
            this.show();
        }
        this.disabled = this.ref('self').attr('disabled') === 'disabled';

        const listenEvents = this.config.listenEvents;
        if (typeof listenEvents === 'string' && listenEvents) {
            listenEvents.split(',').forEach(eventName => {
                this.eventBus().on(eventName.trim(), 'updateTemplate');
            });
        }
    }
    /**
     * @param {object} data data to render
     */
    updateTemplate(data = {}) {
        if (this.prefs().clear) {
            this.ref('self').empty();
        }
        this.render(void 0, data);
    }
    getValue() {
        return this.ref(this.prefs().target).val();
    }
    setValue(val = '') {
        this.setError();
        this.ref(this.prefs().target).val(val);
    }
    getText() {
        return this.ref(this.prefs().target).getText();
    }
    setText(val = '') {
        //this.setError();
        const currentText = this.prefs().useHtml ? this.ref(this.prefs().target).get(0).innerHTML : this.getText();
        this.ref(this.prefs().target).removeClass(this.prefs().classesError);
        if (currentText !== val) {
            if (this.prefs().useHtml) {
                this.ref(this.prefs().target).get(0).innerHTML = val;
            } else {
                this.ref(this.prefs().target).setText(val);
            }
        }
        return this;
    }
    setError(err = '') {
        this.setText(err);
        if (err) {
            this.ref(this.prefs().target).addClass(this.prefs().classesError);
        }
        return this;
    }

    activate() {
        this.ref('self').addClass(this.prefs().classesActive);
    }

    deactivate() {
        this.ref('self').removeClass(this.prefs().classesActive);
    }
    disable() {
        this.disabled = true;
        this.ref('self').disable();
    }
    enable() {
        this.disabled = false;
        this.ref('self').enable();
    }
    handleClick() {
        this.emit('click');
    }
    handleReviewClick(data) {
    }
    handleScroll() {
        window.document.getElementById(this.prefs().targetRef).scrollIntoView();
    }
    handleReadMore() {
        this.ref('self').addClass('hide');
        const selfElement = this.ref('self').els[0];
        selfElement.parentNode.querySelector('#expandable-plp').classList.add('show-all');
        selfElement.parentNode.classList.add('active');
    }
}