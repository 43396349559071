import { isIE } from 'widgets/toolbox/util';
import Widget from '../Widget';

/** @type {number[]} */
var cachedPictures = [];

/**
 * @param {any} value
 */
function valueToHash(value) {
    var hash = 0;
    var string = JSON.stringify(value);
    if (string && string.length > 0) {
        for (let i = 0; i < string.length; i++) {
            hash = ((hash << 5) - hash) + string.charCodeAt(i);
            hash = hash & hash;
        }
    }
    return hash;
}

/**
 * @description Product tile implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class Picture extends Widget {
    prefs() {
        return {
            root: null,
            rootMargin: '500px',
            ...super.prefs()
        };
    }

    init() {
        this.isIE = isIE();

        /**
         * @type {Element|null}
         */
        this.root = this.prefs().root;
        if (typeof this.prefs().root === 'string' && this.prefs().root.length) {
            this.root = this.ref('self').get().closest(this.prefs().root);
        }

        this.initImage();

        if (this.isCached()) {
            this.renderInit();
        } else {
            this.ref('self').addClass(this.prefs().classesLoading);

            this.initLazyLoad();
            if (this.prefs().lazyLoadEvent) {
                this.eventBus().once(this.prefs().lazyLoadEvent, 'initLazyLoad');
            }
        }
    }

    onRefresh() {
        if (this.isCached()) {
            this.renderInit();
        } else {
            this.has('picture', picture => {
                if (!picture.get().hasChildNodes()) {
                    this.initLazyLoad();
                }
            });
        }
    }

    initImage() {
        this.has('image', image => {
            const imageEl = image.get();
            if (imageEl) {
                if ('naturalWidth' in imageEl) {
                    if (imageEl.naturalWidth > 0) {
                        this.stopLoading();
                    }
                } else {
                    this.stopLoading();
                }
            }
        });
    }

    initLazyLoad() {
        const lazyLoadImage = (IntersectionObserver) => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) {
                        return;
                    }
                    observer.disconnect();
                    this.renderInit();
                });
            }, {
                root: this.root,
                rootMargin: this.prefs().rootMargin,
                threshold: [0]
            });

            const el = this.ref('self').get(0);

            if (el) {
                observer.observe(el);
            }

            this.onDestroy(() => {
                if (observer) {
                    observer.disconnect();
                }
            });
        };

        if (typeof IntersectionObserver !== 'undefined') {
            lazyLoadImage(IntersectionObserver);
        } else {
            import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer').then(() => {
                lazyLoadImage(IntersectionObserver);
            });
        }
    }

    renderInit() {
        if (this.isIE && this.has('templateIE')) {
            this.renderTemplateIE();
        } else {
            this.renderTemplate();
        }
    }

    renderTemplate() {
        this.has('template', () => {
            this.render('template', {
                IE: this.isIE
            });
        });
    }

    renderTemplateIE() {
        this.has('templateIE', () => {
            this.render('templateIE');
        });
    }

    stopLoading() {
        this.ref('self').removeClass(this.prefs().classesLoading);
        if (this.getHash() && !this.isCached()) {
            cachedPictures.push(this.getHash());
        }
    }

    getValueToHash() {
        return this.ref('self').data('src');
    }

    getHash() {
        if (!this.hash) {
            this.hash = valueToHash(this.getValueToHash());
        }
        return this.hash;
    }

    isCached() {
        return this.getHash() && cachedPictures.indexOf(this.getHash()) !== -1;
    }
}