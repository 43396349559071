import { timeout } from 'widgets/toolbox/util';
import { getScrollTop } from 'widgets/toolbox/scroll';
import viewtype from 'widgets/toolbox/viewtype';
import Widget from '../Widget';

/**
 * @description Banner implementation
 */
export default class Banner extends Widget {
    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);
        this.delay = {
            mobile: 0,
            tablet: 0,
            desktop: 0
        };
        this.once = true;
        this.doAnimate = true;

        this.threshold = viewtype.isMobileView() ? 0.03 : 0.3;

        this.paralax = null;
        this.paralaxDiff = 0;
        this.paralaxStep = 0;
        this.paralaxTop = 0;
        this.paralaxDown = true;

        this.opacity = null;
        this.opacityStep = 0;
        this.opacityTop = 0;
    }

    prefs() {
        return {
            root: null,
            rootMargin: '0px',
            threshold: -1,
            delay: 0,
            useParent: false,
            classesAnimate: 'm-animate',
            ...super.prefs()
        };
    }

    init() {
        this.root = this.prefs().root;
        if (typeof this.prefs().root === 'string') {
            this.root = this.ref('self').get().closest(this.prefs().root);
        }

        if (this.prefs().threshold >= 0) {
            this.threshold = this.prefs().threshold;
        }

        if (typeof this.prefs().delay === 'string') {
            const delayArray = this.prefs().delay.split(',');
            this.delay = {
                mobile: delayArray[2],
                tablet: delayArray[1],
                desktop: delayArray[0]
            };
        } else {
            this.delay = {
                mobile: this.prefs().delay,
                tablet: this.prefs().delay,
                desktop: this.prefs().delay
            };
        }

        this.has('paralax', paralax => {
            this.paralax = paralax.get();
            this.threshold = 0;
            this.once = false;
        });

        this.has('opacity', opacity => {
            this.opacity = opacity.get();
            this.once = false;
        });

        const initAnimation = (IntersectionObserver) => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) {
                        if (this.paralax) {
                            this.disableParalax();
                        }
                        if (this.opacity) {
                            this.disableOpacity();
                        }
                        return;
                    }
                    if (this.paralax) {
                        this.enableParalax();
                    }
                    if (this.opacity) {
                        this.enableOpacity();
                    }
                    if (this.once) {
                        observer.disconnect();
                    }
                    if (this.doAnimate) {
                        this.animate();
                        this.doAnimate = false;
                    }
                });
            }, {
                root: this.root,
                rootMargin: this.prefs().rootMargin,
                threshold: [this.threshold]
            });

            let el = this.ref('self').get(0);

            if (el) {
                if (this.prefs().useParent) {
                    el = el.parentElement;
                }
                observer.observe(el);
            }

            this.onDestroy(() => {
                if (observer) {
                    observer.disconnect();
                }
            });

            this.checkVideo();
            this.eventBus().on('viewtype.change', 'checkVideo');
            el.addEventListener('click', this.promoClick.bind(this));
        };

        if (typeof IntersectionObserver !== 'undefined') {
            initAnimation(IntersectionObserver);
        } else {
            import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer').then(() => {
                initAnimation(IntersectionObserver);
            });
        }
    }

    promoClick() {
        const el = this.ref('self').get(0);
        this.eventBus().emit('promotion.banner.click', el);
    }

    animate() {
        timeout(() => {
            this.ref('self').addClass(this.prefs().classesAnimate);
        }, this.getDelay());
    }

    getDelay() {
        return this.delay[viewtype.currentDevice];
    }

    enableParalax() {
        const bannerHeight = this.ref('self').prop('clientHeight');
        const paralaxHeight = this.ref('paralax').prop('clientHeight');
        this.paralaxDiff = paralaxHeight - bannerHeight;

        if (this.paralaxDiff > 0) {
            this.paralaxTop = this.ref('self').offset().top - window.innerHeight;
            this.paralaxStep = this.paralaxDiff / window.innerHeight;
            this.paralaxCallback = this.scrollParalax.bind(this);
            this.paralaxCallback();
            window.addEventListener('scroll', this.paralaxCallback);
        }
    }

    disableParalax() {
        if (this.paralaxCallback) {
            window.removeEventListener('scroll', this.paralaxCallback);
            this.paralaxCallback = null;
        }
    }

    scrollParalax() {
        let transformValue = Math.min((this.paralaxTop - getScrollTop()) * this.paralaxStep, this.paralaxDiff);
        if (this.paralaxDown) {
            transformValue = -this.paralaxDiff - transformValue;
        }
        this.paralax.style.top = transformValue + 'px';
    }

    enableOpacity() {
        this.opacityTop = Math.max(this.ref('self').offset().top - window.innerHeight, 0);
        this.opacityStep = 1 / Math.min(this.ref('opacity').prop('clientHeight'), window.innerHeight);
        this.opacityCallback = this.scrollOpacity.bind(this);
        this.opacityCallback();
        window.addEventListener('scroll', this.opacityCallback);
    }

    disableOpacity() {
        if (this.opacityCallback) {
            window.removeEventListener('scroll', this.opacityCallback);
            this.opacityCallback = null;
        }
    }

    scrollOpacity() {
        const opacityValue = Math.max(Math.min((getScrollTop() - this.opacityTop) * this.opacityStep, 1), 0);
        this.opacity.style.opacity = opacityValue;
    }

    checkVideo() {
        this.has('video', videoFrame => {
            if (!videoFrame.get().offsetParent) {
                this.disableVideo();
            } else if (document.readyState !== 'complete') {
                window.addEventListener('load', () => {
                    this.enableVideo();
                }, { once: true });
            } else {
                this.enableVideo();
            }
        });
    }

    disableVideo() {
        this.has('video', videoFrame => {
            videoFrame.data('src', videoFrame.attr('src'));
            videoFrame.attr('src', '');
        });
    }

    enableVideo() {
        this.has('video', videoFrame => {
            if (videoFrame.data('src') && !videoFrame.attr('src')) {
                videoFrame.attr('src', videoFrame.data('src'));
            }
        });
    }

    stopLoading() {}
}