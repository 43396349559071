import Modal from '../global/Modal';
import { enableScroll } from 'widgets/toolbox/scroll';
import { getCookie, setCookie } from 'widgets/toolbox/cookie';

/**
 * @description Base ModalLink implementation
 * @param {typeof import('../global/Modal').default} modal Base widget for extending
 */
export default class CountrySelectorModal extends Modal {
    prefs() {
        return {
            ...super.prefs(),
            cookieTimeout: this.config.sitePreferences.GLOBALE_COOKIE_TIMEOUT
        };
    }

    init() {
        super.init();

        const popupShown = getCookie('country-selector-popup-closed') === 'true';
        const showOnLanding = this.data('showOnLanding');

        if (!popupShown && showOnLanding) {
            this.showModal();
        }

        this.eventBus().on('countrySelector.show', 'showModal');
    }

    closeModal() {
        super.closeModal();
        this.refs.modalContainer.addClass('hidden');
        this.setCookie();
    }

    showModal(templateData, preventDefault, callback) {
        super.showModal(templateData, preventDefault, callback);
        this.refs.modalContainer.removeClass('hidden');
    }

    goToSite(e, event) {
        const currentSite = this.config.currentSite;
        const selectedCountry = this.ref('selectedCountry').get().value;

        this.setCookie();

        if (currentSite === 'hush-UK' && selectedCountry === 'GB') {
            event.preventDefault();
            return this.closeModal();
        }

    }

    setCookie() {
        const cookieTimeout = this.prefs().cookieTimeout;
        setCookie('country-selector-popup-closed', 'true', cookieTimeout ? cookieTimeout / (60 * 24) : 1);
    }
}
