import { getJSONByUrl } from 'widgets/toolbox/ajax';
import BasicInput from './BasicInput';

/**
 * @description Implementation
 */
export default class InputCheckbox extends BasicInput {
    getValue() {
        if (this.ref('field').prop('checked')) {
            return super.getValue();
        }
        return '';

    }
    setValue() {}
    check() {
        this.ref('field').attr('aria-checked', 'true');
        this.ref('field').prop('checked', true);

        this.emit('checkboxChanged', this);
        this.emit('change');
    }
    uncheck() {
        this.ref('field').attr('aria-checked', 'false');
        this.ref('field').prop('checked', false);

        this.emit('checkboxChanged', this);
        this.emit('change');
    }
    updateAriaChecked() {
        if (this.ref('field').prop('checked')) {
            this.ref('field').attr('aria-checked', 'true');
        } else {
            this.ref('field').attr('aria-checked', 'false');
        }
    }
    handleChange() {
        this.updateAriaChecked();
        this.emit('checkboxChanged');
        this.emit('change');
    }
    handleCall() {
        var url = this.prefs().url;
        var value = this.getValue();
        if (url) {
            getJSONByUrl(url, {
                [this.prefs().value]: value
            }).then(response => {
                var isError = response && response.error;
                if (isError) {
                    if (value) {
                        this.uncheck();
                    } else {
                        this.check();
                    }
                }
                this.emit('inputcall', isError);
            });
        }
    }
}