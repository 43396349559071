import { RefElement } from 'widgets/toolbox/RefElement';

export function parseProductPrice(item) {
    let itemPrice = item.price;
    if (itemPrice.type === 'tiered') {
        const selectedQuantity = itemPrice.tiers.find((tier) => {
            return tier.quantity === item.quantity;
        });

        itemPrice = selectedQuantity ? selectedQuantity.price : itemPrice.tiers[itemPrice.tiers.length - 1].price;
    }

    return itemPrice;
};

/**
 * clear Loqate fields if country was changed (used for Shipping form, Billing form, Address form)
 *
 * @param {Object} addressFormWidget
 * @param {Boolean} skipQuickFinderValue
 */
export function clearLoqateFields(addressFormWidget, skipQuickFinderValue) {

    var quickFinderEl = new RefElement(
        Array.from(addressFormWidget.ref('self').get().querySelectorAll('#' + addressFormWidget.prefs().loqateFieldsID.finder))
    );

    var manuallyAddressFields = addressFormWidget.prefs().manuallyAddressFields.split(',')
        .map(a => addressFormWidget.getById(a.trim(), a => a)).filter(Boolean);

    if (quickFinderEl && (quickFinderEl.val() || skipQuickFinderValue) && manuallyAddressFields) {

        // clear manually address fields
        manuallyAddressFields.forEach(item => {
            item.clearError();
            item.setValue('', true);
        });

        // clear quick finder field
        quickFinderEl.val('');

        // hide manually address block
        addressFormWidget.has('toggleAddressFieldsButton', button => {
            button.close();
        });
    }
}

/**
 * Returns true if the given code is a gift voucher code
 *
 * @param {String} code
 * @returns {Boolean}
 */
export function isGiftCardCode(code) {
    let isGiftCard = false;

    if (!code) {
        return isGiftCard;
    }

    code = code.toLowerCase();

    if (code.startsWith('hush') || code.split('-').length === 4) {
        isGiftCard = true;
    }

    return isGiftCard;
}