import InputSelect from 'widgets/forms/InputSelect';

/**
 * @description Implementation
 */
export default class inputSelectCountry extends InputSelect {

    updatePostalField(el, event) {
        var selectedValue = this.getValue();
        var className;

        if (this.ref('field').hasClass('js-shipping-country')) {
            className = 'js-shipping-zip';
        } else if (this.ref('field').hasClass('js-billing-country')) {
            className = 'js-billing-zip';
        }

        if (selectedValue && className) {
            this.eventBus().emit('update.postal.field', selectedValue, className);
        }

        // call to update shipping method list
        this.onChange(el, event);
    }
    }