import AjaxForm from 'widgets/forms/AjaxForm';

/**
 * @description Email Subscribe footer implementation
 */
export default class EmailSubscribe extends AjaxForm {
    prefs() {
        return {
            signupEmail: 'signupEmail',
            formContent: 'formContent',
            successMessage: 'successMessage',
            submitButton: 'submitButton',
            sucsessMassegeClass: 'b-footer-sign-up__status_success',
            ...super.prefs()
        };
    }
    init() {
        this.ref(this.prefs().submitButton).disable();
        this.eventBus().on('signup.submitButtonState', 'submitButtonState');
    }

    /**
     * @param {Object} data submitted data
     */
    onSubmitted(data) {
        if (data.success) {
            this.eventBus().emit('signup.submitted', data);
            this.ref(this.prefs().successMessage).setText(data.msg);
            this.ref(this.prefs().successMessage).addClass(this.prefs().sucsessMassegeClass);
        }

        if (data.error) {
            this.ref(this.prefs().signupEmail).setError(data.msg);
        }
    }

    /**
     * @param {Boolean} isActiveState button state
     */
    submitButtonState(isActiveState) {

        if (isActiveState) {
            this.ref(this.prefs().submitButton).enable();
        } else {
            this.ref(this.prefs().submitButton).disable();
        }
        this.hideSuccessMessage();
    }
    handleSubmit() {

        // avoid request when submit button is disabled (e.g. press Enter on Safari Mac)
        if (this.ref(this.prefs().submitButton).isDisabled()) {
            return;
        }
        this.eventBus().emit('email.submitted', this.getFormFields());
        this.ref('reCaptchaV3').generateToken();
        super.handleSubmit();
    }
    hideSuccessMessage() {
        this.ref(this.prefs().successMessage).setText('');
        this.ref(this.prefs().successMessage).removeClass(this.prefs().sucsessMassegeClass);
    }
}