import BasicInput from 'widgets/forms/BasicInput';


/**
 * @description Implementation
 */
export default class InputText extends BasicInput {
    prefs() {
        return {
            patternMismatch: this.ref('field').attr('data-pattern-mismatch') ||
                this.config.Resources.msg.stirng.patternMismatch,
            rangeError: this.ref('field').attr('data-range-error') ||
                this.config.Resources.msg.stirng.rangeError,
            ...super.prefs()
        };
    }
}