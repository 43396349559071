import Glide from '@glidejs/glide';

function getCssVar(name) {
    return getComputedStyle(document.documentElement).getPropertyValue(name);
}
const smBreakpoint = Number(getCssVar('--sm').replace('px', ''));
const disableOnMobile = (glide) => {
    // disable glide on mobile
    if (window.innerWidth < smBreakpoint) {
        glide.index = 0;
        glide.update({perView: 1, gap: 0});
        glide.disable();
    } else {
        glide.update({perView: 2, gap: 20});
        glide.enable();
    }
};


function configureGlide(nextCarousel) {
    if (typeof Glide === 'undefined') {
        return;
    }

    const glide = new Glide(nextCarousel, {
        perView: 1,
        rewind: false,
        type: 'slider',
        arrows: 'body',
        gap: 0
    }).mount({
        DisableControls: function (Glide, Components, Events) {
            const PREV_CONTROL_SELECTOR = '[data-glide-dir="<"]';
            const NEXT_CONTROL_SELECTOR = '[data-glide-dir=">"]';
            const component = {
                buildAfter() {
                    this.prevBtn = Components.Html.root.querySelector(PREV_CONTROL_SELECTOR);
                    this.nextBtn = Components.Html.root.querySelector(NEXT_CONTROL_SELECTOR);
                },
                handleDisable() {
                    const perView = Glide.settings.perView;
                    const slidesCount = Components.Html.slides.length;
                    this.prevBtn.hidden = (Glide.index <= 0);
                    this.nextBtn.hidden = (Glide.index >= slidesCount - perView);
                }
            };

            Events.on('build.after', () => {
                component.buildAfter();
                component.handleDisable();
            });

            Events.on('run.after', () => {
                component.handleDisable();
            });

            return component;
        }
    });

    addEventListener('resize', () => disableOnMobile(glide));
    setTimeout(() => disableOnMobile(glide));
}

addEventListener('DOMContentLoaded', () => {
    const nextCarousels = document.querySelectorAll('[data-next-carousel]');
    for (const nextCarousel of nextCarousels) {
        setTimeout(() => configureGlide(nextCarousel));
    }
});
