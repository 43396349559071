import Picture from '../global/Picture';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { objectEquals } from 'widgets/toolbox/util';

/**
 * @description Product tile implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ProductPicture extends Picture {
    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);
        this.inited = false;
        this.source = {};
    }

    prefs() {
        const noImageDims = this.config.sitePreferences.NO_IMAGE_DIMS;
        return {
            useDoubled: false,
            classesNoImage: 'm-noimage',
            noImageDims: noImageDims ? noImageDims : {
                scaleWidth: 200,
                scaleHeight: 286
            },
            ...super.prefs()
        };
    }

    init() {
        super.init();

        if (this.prefs().reloadEvent) {
            this.eventBus().on(this.prefs().reloadEvent, 'reload');
        }

        this.initNoImage();
    }

    initNoImage() {
        this.srcNoImage = this.prefs().srcNoImage || this.config.Constants.URL_NO_IMAGE;
    }

    reload() {
        if (this.inited && !objectEquals(this.getSource(this.isDoubled()), this.source)) {
            this.inited = false;
            this.ref('self').addClass(this.prefs().classesLoading);
            this.data('initLazyLoad');
        }
    }

    isDoubled() {
        return getComputedStyle(this.ref('self').get()).order > 1;
    }

    renderInit() {
        if (this.has('template')) {
            this.renderTemplate();
        } else {
            this.renderImage();
        }
        this.inited = true;
    }

    renderTemplate() {
        this.initNoImage();
        const isDoubled = this.isDoubled();
        if (isDoubled && !window.doubledPreloaded) {
            const link = window.document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = this.ref('self').data('srcDoubled') ? this.ref('self').data('srcDoubled').url : '';
            window.document.head.appendChild(link);
            window.doubledPreloaded = true;
        }
        this.source = this.getSource(isDoubled) || {};

        this.render(
            'template',
            {
                ...this.source,
                'class': isDoubled ? 'gd-show' : 'gd-hide'
            },
            this.ref('picture').empty()
        );
        this.ref('self').toggleClass('m-doubled', isDoubled);
    }

    renderImage(src = '', isNoImage = false) {
        const imgSrc = src || this.ref('self').data('src');
        if (this.prefs().useLoader) {
            showPageLoader(this.ref('self').get(), 'inside');
        }
        if (imgSrc) {
            const img = new Image();
            img.className = 'b-product-picture__image';
            img.onload = this.stopLoading.bind(this);
            img.onerror = this.renderNoImage.bind(this);
            img.src = imgSrc;
            img.alt = this.ref('self').data('alt') || '';
            img.title = this.ref('self').data('title') || '';

            if (isNoImage) {
                const imageDims = this.prefs().noImageDims;
                img.width = imageDims.scaleWidth;
                img.height = imageDims.scaleHeight;
            }

            this.ref('picture').empty().get().appendChild(img);
        }
    }

    stopLoading() {
        this.eventBus().emit('imageLoaded', this.config.src);

        super.stopLoading();
        if (this.prefs().useLoader && this.ref('self').get()) {
            hidePageLoader(this.ref('self').get(), 'inside');
        }
    }

    renderNoImage() {
        if (this.srcNoImage) {
            this.ref('self').addClass(this.prefs().classesNoImage);
            this.renderImage(this.srcNoImage, true);
            this.srcNoImage = null;
        }
    }

    /**
     * @param {boolean} alternative
     * @return {object}
     */
    getSource(alternative) {
        return this.ref('self').data(alternative ? 'srcDoubled' : 'srcStandard');
    }
    

    /**
     * @param {object} data
     * @param {boolean} alternative
     * @param {boolean} render
     */
    setSource(data, alternative = false, render = false) {
        let renderingContext = this.ref('self').data('renderingContext') || 'default';
        if (!objectEquals(this.getSource(alternative), data)) {
            this.ref('self').data(
                alternative ? 'srcDoubled' : 'srcStandard',
                JSON.stringify(data)
            );
            if (render) {
                this.renderTemplate();
            }
        }
    }

    getValueToHash() {
        return this.getSource(this.isDoubled()) || super.getValueToHash();
    }
}