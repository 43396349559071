import { getCookie, setCookie } from 'widgets/toolbox/cookie';
import { showPageLoader } from 'widgets/toolbox/progress';
import { disableScroll } from 'widgets/toolbox/scroll';
import Widget from '../Widget';

/**
 * @description CountrySelector
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class CountrySelector extends Widget {
    prefs() {
        return {
            target: 'self',
            cookieName: 'currentSite',
            dropdownCountries: 'dropdownCountries',
            classesOpen: 'open',
            ...super.prefs()
        };
    }

    init() {

        // add cookie if it is needed
        // var currentSiteCookie = getCookie(this.prefs().cookieName);
        // var currentSite = this.ref('self').attr('data-current-site');
        // var currentLocationCountryCode = this.ref('self').attr('data-current-location-country-code');

        // if (!currentSiteCookie) {
        //     if (currentLocationCountryCode !== 'GB' && currentSite === 'hush-UK') {
        //         var rowRef = this._setupRedirectObject('ROW');
        //         return this.goToSite(rowRef);
        //     } else if (currentLocationCountryCode === 'GB' && currentSite !== 'hush-UK') {
        //         var ukRef = this._setupRedirectObject('GB');
        //         return this.goToSite(ukRef);
        //     }
        // }
    }

    _setupRedirectObject(refCode) {
        var ref = this.ref(refCode);
        var refSiteID = ref.attr('data-site-id');

        setCookie(this.prefs().cookieName, refSiteID);
        return ref;
    }

    showList() {
        this.ref(this.prefs().dropdownCountries).toggle();
        this.ref('self').toggleClass(this.prefs().classesOpen);
    }

    goToSite(el, event) {
        if (event) {
            event.preventDefault();
        }

        var siteUrl = el.attr('data-locale-url');
        var siteId = el.attr('data-site-id');

        // avoid request for current country
        if (window.location.href === siteUrl) {
            return;
        }

        if (siteUrl && siteId) {
            disableScroll();
            showPageLoader();
            setCookie(this.prefs().cookieName, siteId);
            window.location.href = siteUrl;
        }
    }

    showCountrySelectorModal() {
        this.eventBus().emit('countrySelector.show');
        
        const mobileNavBar = this.parents.find((el) => el.config.widget === 'navigation');

        if (mobileNavBar) {
            mobileNavBar.closeNavBar();
        }
    }
}