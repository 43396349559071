import Widget from '../Widget';

export default class Narvar extends Widget {
    init() {
        this.loadScript().then(() => {
            this.getQueryParameters();
        }).catch(err => {
            console.error("Failed to load Narvar script:", err);
        });
    }

    loadScript() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://js.narvar.com/track.js';
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }

    async getQueryParameters() {
        try {
            var queryParameters = window
                .location
                .search
                .replace(/^\?/g, '')
                .split('&')
                .reduce(function(acc, param) {
                var keyAndValue = param.split('=');
                acc[keyAndValue[0]] = keyAndValue[1];
                return acc;
                }, {});
            window.narvar('track', {
                retailer: 'hush',
                locale_language: queryParameters.locale_language,
                locale_country: queryParameters.locale_country,
                tracking_numbers: queryParameters.tracking_numbers,
                carrier: queryParameters.carrier,
                order_number: queryParameters.order_number,
                t: queryParameters.t,
                ...queryParameters
            });
        } catch (err) {
            console.error(err);
        }
    }
}