import Widget from '../Widget';

/**
 * @description toggle review modal
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ReviewToggle extends Widget {
    reviewShow(el, event) {
        event.preventDefault();
        this.eventBus().emit('reviews.show', {});
    }
}