import BasicInput from 'widgets/forms/BasicInput';

/**
 * @description Implementation
 */
export default class InputEmail extends BasicInput {
    prefs() {
        return {
            ...super.prefs(),
            patternMismatch: this.ref('field').attr('data-pattern-mismatch') ||
                this.config.Resources.msg.email.patternMismatch,
            typeMismatch: this.ref('field').attr('data-type-mismatch') ||
                this.config.Resources.msg.email.patternMismatch,
            rangeError: this.ref('field').attr('data-range-error') ||
                this.config.Resources.msg.email.rangeError,
            missingError: this.ref('field').attr('data-missing-error') ||
                this.config.Resources.msg.email.missingError
        };
    }
}