import Widget from '../Widget';

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class Concealable extends Widget {
    hide() {
        this.ref('self').hide();
    }
}