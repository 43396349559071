function getLoqateConfigs() {
    var configs = {
        enabled: window.SitePreferences.LOQATE_CONFIGS_ENABLED,
        key: window.SitePreferences.LOQATE_CONFIGS_KEY,
        timeout: window.SitePreferences.LOQATE_CONFIGS_TIMEOUT,
        url: window.SitePreferences.LOQATE_CONFIGS_URL,
        validResponses: window.SitePreferences.LOQATE_CONFIGS_RESPONSES.split(';')
    };
    return configs;
}
/**
 * @param {object} emailField email field to validate
 */
export function validateEmail(emailField) {
    var configs = getLoqateConfigs();
    var result = false;
    if (!configs.enabled) {
        return true;
    } else if (emailField && emailField.ref('field') && emailField.getValue() && (emailField.getValue() !== emailField.loqateCheckedValue) && emailField.firstChecked) {
        var email = emailField.getValue();
        emailField.loqateCheckedValue = email;
        var oReq = new XMLHttpRequest();
        oReq.onreadystatechange = function () {
            if (oReq.readyState === 4) {
                var valid = (configs.validResponses.indexOf(JSON.parse(oReq.response).Items[0].ResponseCode) >= 0 || JSON.parse(oReq.response).Items[0].ResponseCode === 'Valid');
                if (!valid) {
                    emailField.prevResult = false;
                    emailField.error = window.Resources.msg.email.invalidError;
                    emailField.setError(emailField.error);
                    emailField.eventBus().emit('registration.validate');
                } else {
                    emailField.prevResult = true;
                    emailField.error = '';
                    emailField.setError();
                    emailField.eventBus().emit('registration.validate', true);
                }
                emailField.prevResult = valid;
                result = valid;
            }
        };
        oReq.open('GET', configs.url + '?Email=' + email + '&Key=' + configs.key, true);
        oReq.send('');
    }
    return result;
}