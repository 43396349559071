import { log } from './toolbox/util';
import { DiffDOM } from 'diff-dom/dist/index';
import Mustache from 'mustache';
import { extend } from 'widgets/toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';
import EventBusWrapper from 'widgets/toolbox/EventBusWrapper';

//import eventMgr from './EventMgr';

const noop = () => { };

export default class Widget {
    /**
     * @param {HTMLElement} el DOM element
     * @param {{[x: string]: string|number|boolean|undefined}} config widget config
     */
    constructor(el, config = {}) {
        this.isInViewport = false;

        this.parents = [];
        /**
         * @type {{[key : string] : RefElement | undefined} | undefined}
         */
        this.refs = Object.create(null);
        if (this.refs) { // for type check
            this.refs.self = new RefElement([el]);
        }

        this.config = extend(config, {
            sitePreferences: window.SitePreferences,
            Constants: window.Constants,
            Resources: window.Resources
        });
        /**
         * @type {Array<Function>|undefined}
         */
        this.disposables = void 0;
        /**
         * @type {(eventName: string, ...args: any) => void}
         */
        this.parentHandler = noop;

        /**
         * @type {Widget[]|undefined}
         */
        this.items = [];


        if (this.ref('self').attr('id')) {
            this.id = this.ref('self').attr('id');
        }
        if (!this.id && this.config.id) {
            this.id = this.config.id;
        }

        this.shown = !this.config.hidden && !this.ref('self').hasAttr('hidden');

        if (typeof this.config.passEvents === 'string') {
            this.config.passEvents.split(':').forEach(pair => {
                const [methodName, emitEvent] = pair.split('-');
                const self = this;
                this[methodName] = function (...args) {
                    self.parentHandler(emitEvent, ...args);
                };
            });
        }
    }
    get length() {
        return 1;
    }
    /**
 * @param {string} name
 * @param {any} [value]
 * @param {Array<any>} args
 */
    data(name, value, ...args) {
        if (typeof this[name] === 'function') {
            return this[name].call(this, value, ...args);
        }
        return this.ref('self').data(name, value);
    }
    /**
     * @param {string} eventName name of event
     * @param  {...any} args argument to pass
     */
    emit(eventName, ...args) {
        this.parentHandler(eventName, this, ...args);
    }
    /**
     * @param {string} eventName name of event
     * @param  {...any} args argument to pass
     */
    emitWithoutContext(eventName, ...args) {
        this.parentHandler(eventName, ...args);
    }
    /**
     * @returns {EventBusWrapper}
     */
    eventBus() {
        if (!this._eventBus) {
            this._eventBus = new EventBusWrapper(this);
            this.onDestroy(() => {
                this._eventBus = void 0;
            });
        }
        return this._eventBus;
    }
    prefs() {
        return {
            /** is component hidden */
            hidden: false,
            /** class of component during loading */
            classesLoading: 'm-widget-loading',
            /** class of component once component loaded and inited */
            /** class of component when it's active */
            classesActive: 'm-active',
            /** id of component */
            id: '',
            // configs form data attributes
            ...this.config
        };
    }

    get() {
        return this.ref('self').get();
    }

    /**
     * 
     * @param {boolean} isIntersecting 
     */
    updateIntersection(isIntersecting) {
        if (isIntersecting) {
            this.onIsInViewport();
        } else if (!isIntersecting) {
            this.onIsOutViewport();
        }
    }

    onIsInViewport() {}

    onIsOutViewport () {}

    init() {
        this.ref('self')
            .removeClass(this.prefs().classesLoading);
    }

    /**
     *
     * @param {string} name name of referenced element
     */
    ref(name) {
        const ref = this.refs && this.refs[name];
        /**
         * @type {Widget[]|undefined}
         */
        if (ref) {
            return ref;
        }
        if (!PRODUCTION) {
            log.warn(`Reference "${name}" is not found in widget "${this.constructor.name}" `, this);
        }
        return new RefElement([]);
    }
    /**
     * check if reference exists
     *
     * @param {string} name
     * @param {(arg: Widget | RefElement) => void} [cb]
     */
    has(name, cb) {
        const ref = this.refs && this.refs[name];
        /**
         * @type {Widget[]|undefined}
         */
        if (ref) {
            if (cb) {
                cb(ref);
            }
            return true;
        }
        return false;
    }

    /**
     * Destroys widgets.
     * Only for internal usage
     *
     * @private
     */

    destroy() {
        if (this.disposables) {
            this.disposables.forEach(disposable => disposable());
            this.disposables = void 0;
        }

        if (this.items && this.items.length) {
            for (var i = 0; i < this.items.length; ++i) {
                var item = this.items[i];
                if (item) {
                    if (typeof item.destroy === 'function') {
                        item.destroy();
                    }
                }
            }
        }

        this.items = void 0;
        this.refs = void 0;
    }
    /**
     * @param {string} eventName ex: 'click', 'change'
     * @param {(this: this, element: HTMLElement, event: Event) => any} cb callback
     * @param {string|EventTarget|null} selector CSS selector
     * @param {boolean} passive is handler passive?
     *
     * @private
     */
    ev(eventName, cb, selector = '', passive = true) {
        /**
         * @type EventTarget[]
         */
        var elements = [];
        var self = this;

        if (selector instanceof Element || selector === window) {
            elements = [selector];
        } else if (typeof selector === 'string' && this.refs && this.refs.self) {
            const el = this.refs.self.get();
            if (el) {
                elements = Array.from(el.querySelectorAll(selector));
            }
        } else if (this.refs && this.refs.self) {
            const el = this.refs.self.get();
            if (el) {
                elements = [el];
            }
        }

        return elements.map(element => {
            let fn = function (...args) {
                return cb.apply(self, [this, ...args]);
            };
            if(cb){
                element.addEventListener(eventName, fn, passive ? { passive: true } : { passive: false });
            }
            const dispose = () => {
                if (fn) {
                    element.removeEventListener(eventName, fn);
                    fn = void 0;
                }
            };
            this.onDestroy(dispose);
            return dispose;
        });
    }
    /**
     * @param {Function} fn function to be called during destroy
     */
    onDestroy(fn) {
        if (!this.disposables) {
            this.disposables = [];
        }
        this.disposables.push(fn);
    }

    onRefresh() {

    }

    // eventMgr(event, handler) {
    //     handler = handler.bind(this);
    //     eventMgr.on(event, handler);

    //     this.regDisposable(() => {
    //         if (handler) {
    //             eventMgr.off(event, handler);
    //             handler = void 0;
    //         }
    //     });
    // }


    /**
     * Search for child component instance which is returned in callback
     *
     * @template T
     * @param {string} id of component
     * @param {function(Widget) : T} cb callback with widget
     * @returns {T|undefined}
     */
    getById(id, cb) {
        if (id && this.items && this.items.length) {
            for (var c = 0; c < this.items.length; ++c) {
                const item = this.items[c];

                if (item) {
                    if (item.id === id) {
                        return cb.call(this, item);
                    }
                    //item.getById(id, cb);
                }
            }
        }
    }
    /**
     * Travels over nearest/next level child components
     *
     * @template T
     * @param {(arg0: any)=>T} fn callback
     * @returns {T[]} arrays of callback results
     */
    eachChild(fn) {
        if (this.items && this.items.length) {
            return this.items.map(item => {
                return fn(item);
            });
        }
        return [];
    }
    /**
     * Hide widget on the page
     *
     * @param {Function} [cb] callback when element is fully hidden
     */
    hide(cb) {
        if (this.shown) {
            this.ref('self').hide(cb);
            this.shown = false;
        }
        return this;
    }
    /**
     * Show widget on the page
     *
     * @param {Function} [cb] callback when element is fully shown
     */
    show(cb) {
        if (!this.shown) {
            this.ref('self').show(cb);
            this.shown = true;
        }
        return this;
    }
    /**
     * @param {boolean} [initialState]  true - show else false hide
     * @param {Function} [cb] callback when element is fully shown
     */
    toggle(initialState, cb) {
        const state = typeof initialState !== 'undefined' ? initialState : !this.shown;

        this[state ? 'show' : 'hide'](cb);
        return this;
    }
    isHidden() {
        return !this.shown;
    }
    isShown() {
        return this.shown;
    }
    /**
     * @param {string} templateRefId id of template
     * @param {object} data data to render
     * @param {RefElement} [renderTo] render into element
     * @param {string} [strToRender] pre-rendered template
     *
     * @private
     */
    // eslint-disable-next-line complexity
    render(templateRefId = 'template', data = {}, renderTo = this.ref('self'), strToRender = '', tags = undefined) {
        // eslint-disable-next-line complexity
        if (!this.cachedTemplates) {
            /** @type {{[x: string]: string|undefined}} */
            this.cachedTemplates = {};
        }

        let template = this.cachedTemplates && this.cachedTemplates[templateRefId];

        if (!strToRender && !template) {
            const templateElement = this.ref(templateRefId).get();

            if (templateElement) {
                template = templateElement.innerHTML;
                Mustache.parse(template, tags);
                this.cachedTemplates[templateRefId] = template;

                if (!PRODUCTION) { // save template in element for hot reload
                    const tmpEl = renderTo.get();
                    if (tmpEl) {
                        tmpEl['@@@_template'] = template;
                    }
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (!PRODUCTION) {
                    const tmpEl = renderTo.get();
                    if (tmpEl && tmpEl['@@@_template']) {
                        template = tmpEl['@@@_template'];
                    } else {
                        log.error(`Unable find template ${templateRefId}`, this);
                        return;
                    }
                } else {
                    log.error(`Unable find template ${templateRefId}`, this);
                    return;
                }
            }
        }

        function generateUrl(spec, ratio, url) {
            return url.split('?')[0] + '?' + [(spec.sw && 'sw=' + (spec.sw * ratio)), (spec.sh && 'sh=' + (spec.sh * ratio)), (spec.q && 'q=' + spec.q)]
                .filter(Boolean).join('&');
        }

        data.resize = function(args) {
            return function (dimString, render) {
                let [renderingContext, url] = dimString.split('|').map(x => x.trim());
                url = render(url);
                let mediaQueriesSpec = window.mediaQueriesSpec;
                let result = [];
                if (!mediaQueriesSpec) {
                    return '';
                }
                renderingContext = (renderingContext.length && mediaQueriesSpec[renderingContext]) ? renderingContext : 'default';
                for (let i = 0; i < (mediaQueriesSpec[renderingContext] || []).length; i += 1) {
                    let spec = mediaQueriesSpec[renderingContext][i]; 
                    if (spec.media && spec.media.length) {
                        result.push('<source srcset="' + spec.pxRatio.map((ratio) =>
                            generateUrl(spec, ratio, url) + ' ' + ratio + 'x'
                        ).join(', ') + '" media="' + spec.media + '" />');
                    }
                }
                return result.join('\n');
            };
        };

        data.resizemain = function(args) {
            return function (dimString, render) {
                let [renderingContext, url] = dimString.split('|').map(x => x.trim());
                url = render(url);
                let mediaQueriesSpec = window.mediaQueriesSpec;
                if (mediaQueriesSpec) {
                    renderingContext = (renderingContext.length && mediaQueriesSpec[renderingContext]) ? renderingContext : 'default';
                    for (let i = 0; i < (mediaQueriesSpec[renderingContext] || []).length; i += 1) {
                        let spec = mediaQueriesSpec[renderingContext][i]; 
                        if (!spec.media || !spec.media.length) {
                            return generateUrl(spec, 1, url);
                        }
                    }
                }
                return url;
            };
        };

        const renderedStr = strToRender || Mustache.render(template, data, undefined, tags);
        const el = renderTo.get();

        if (el && el.parentNode) {
            // use new document to avoid loading images when diffing
            const newHTMLDocument = document.implementation.createHTMLDocument('diffDOM');
            const diffNode = /**@type {HTMLElement} */(newHTMLDocument.createElement('div'));

            diffNode.innerHTML = renderedStr.replace(/<!--.*?-->/ig, '');

            const dd = new DiffDOM({
                maxChildCount: false,
                filterOuterDiff: function (t1) {
                    if (t1.attributes && t1.attributes['data-skip-render']) {
                        // will not diff childNodes
                        t1.innerDone = true;
                    }
                }
            });
            const diff = dd.diff(
                el,
                diffNode.firstElementChild
            );

            if (diff && diff.length) {
                //console.log(diff);
                dd.apply(el, diff);
            }
        } else {
            log.error(`Missing el to render ${templateRefId}`, this);
        }
    }
}

/**
 * @typedef IEvent
 * @property {string} [events.childID]
 * @property {Function} [events.childClass]
 * @property {string} events.eventName
 * @property {Function} events.fn
 * @property {function} [cb]
 */
