import viewtype from 'widgets/toolbox/viewtype';
import { timeout } from 'widgets/toolbox/util';
import Widget from '../Widget';


const KEY_DOWN = 40;
const KEY_UP = 38;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;
const KEY_ESCAPE = 27;
const KEY_SPACE = 32;

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class MenuItem extends Widget {
    prefs() {
        return {
            classesShow: 'show',
            classesShowNext: 'show-next',
            classesSubMenu: 'menu-fade',
            parent: $('[data-ref="mobileMenu"]'),
            ...super.prefs()
        };
    }
    init() {
        this.smallView = viewtype.isMobileView();
        this.mediumView = viewtype.isTabletView();

        this.timerCleaner = void 0;
        this.timerCleanerClose = void 0;
        this.eventBus().on('viewtype.change', 'viewTypeChange');

        this.eventBus().on('nav.item.close', 'closeMenu');

    }
    viewTypeChange() {
        this.smallView = viewtype.isMobileView();
        this.mediumView = viewtype.isTabletView();
    }
    preventNext() {
        this.isTouch = true;
    }
    globalClose() {
        this.eventBus().emit('nav.item.close');
    }
    isOpened() {
        return this.ref('self').hasClass(this.prefs().classesShow);
    }
    /**
     *
     * @param {any} el RefElement
     * @param {Event} event descriptive comment
     */
    handleClick(el, event) {
        var headerNav = this.ref('self').attr('data-headernavelement');
        if (headerNav) {
            this.eventBus().emit('navbar.click', 'Header', headerNav);
        } else {
            this.eventBus().emit('navbar.click', 'Footer', this.ref('self').attr('data-footernavelement'));
        }
        // skip first click on touch devices and leave menu opened
        if (this.isTouch && !this.isOpened()) {
            event.preventDefault();
            this.openMenu();
        }
    }

    mouseEnter() {
        if (!this.isTouch) {
            if (this.timerCleanerClose) {
                this.timerCleanerClose();
            }
            this.timerCleaner = timeout(() => {
                this.openMenu();
            }, this.config.Constants.FLYOUT_TIMEOUT_DELAY);
        }
    }
    mouseLeave() {
        if (!this.isTouch) {
            if (this.timerCleaner) {
                this.timerCleaner();
            }
            this.timerCleanerClose = timeout(() => {
                this.closeMenu();
            }, this.config.Constants.FLYOUT_TIMEOUT_DELAY);
        }
    }
    openMenu() {
        this.has('subMenu', subMenu => {
            this.ref('self').addClass(this.prefs().classesShow);
            this.has('menuLink', menuLink => menuLink.attr('aria-expanded', 'true'));            
            this.ref('self').addClass(this.prefs().classesShowNext);
            this.prefs().parent.addClass(this.prefs().classesShowNext);

            subMenu.show(() => {
                if (this.smallView || this.mediumView) {
                    this.ref('self').get().scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                }
            });

            subMenu.addClass(this.prefs().classesSubMenu);
        });

    }
    closeMenu() {
        this.ref('self').removeClass(this.prefs().classesShow);
        this.has('menuLink', menuLink => menuLink.attr('aria-expanded', 'false'));
        this.has('subMenu', subMenu => {
            subMenu.removeClass(this.prefs().classesSubMenu);
            subMenu.hide();
        });
        this.ref('self').removeClass(this.prefs().classesShowNext);
        this.prefs().parent.removeClass(this.prefs().classesShowNext);
    }
    toggleMenu(e) {
        if (this.isOpened()) {
            this.closeMenu();
        } else {
            this.globalClose();
            this.openMenu();
        }
    }
    /**
     * @param {any} _
     * @param {Event} event
     */
    handleKey(_, event) {
        if (this.smallView) {
            if (event.which === KEY_RIGHT || event.which === KEY_SPACE) {
                event.preventDefault();
                this.openMenu();
            } else if ([KEY_ESCAPE, KEY_UP, KEY_ESCAPE, KEY_DOWN, KEY_LEFT].includes(event.which)) {
                event.preventDefault();
                if (event.which === KEY_UP) {
                    this.emit('prev');
                } else if (event.which === KEY_DOWN) {
                    this.emit('next');
                }
                this.closeMenu();
            }
        } else if (event.which === KEY_DOWN || event.which === KEY_SPACE) {
            event.preventDefault();
            this.openMenu();
        } else if ([KEY_ESCAPE, KEY_UP, KEY_ESCAPE, KEY_RIGHT, KEY_LEFT].includes(event.which)) {
            event.preventDefault();
            if (event.which === KEY_LEFT) {
                this.emit('prev');
            } else if (event.which === KEY_RIGHT) {
                this.emit('next');
            }
            this.closeMenu();
        }

    }
}