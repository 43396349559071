import Widget from '../Widget';

/**
 * @description Product visible implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class ProductVisible extends Widget {
    prefs() {
        return {
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.ref('self').els[0].addEventListener('productAlternate', () => {
            this.load();
        });
    }

    load() {
        var source = this.ref('self').els[0].querySelector('[data-placeholder]');

        if (source) {
            source.remove();
        }
    }
}