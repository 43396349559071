
import { RefElement } from 'widgets/toolbox/RefElement';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import Widget from '../Widget';

var toggleStates = {};

const UK_Site_Countries = ['GB', 'IM', 'JE', 'GG'];

/**
 * @param {Object} widgetInstance Base widget for extending
 */
function getToggleElement(widgetInstance) {
    var content = widgetInstance.ref('self').get(0).parentNode.querySelectorAll(widgetInstance.prefs().toggleContent);
    if (!content.length) {
        content = document.querySelectorAll(widgetInstance.prefs().toggleContent);
    }

    return new RefElement(Array.from(content));
}

/**
 * @description Base dropdown implementation
 */
export default class ToggleButton extends Widget {
    prefs() {
        return {
            scrollIntoView: true,
            scrollParent: true,
            classesHtml: '',
            ...super.prefs()
        };
    }
    init() {
        this.content = getToggleElement(this);
        if (this.prefs().widgetPrefix) {
            this.eventBus().on(this.prefs().widgetPrefix + '.open', 'openEvent');
            this.eventBus().on(this.prefs().widgetPrefix + '.close', 'closeEvent');
        }
        if (this.data('auto-expand')) {
            const autoExpand = () => {
                const { hash } = document.location;
                if (hash && hash.endsWith(this.id)) {
                    this.open();
                }
            }

            autoExpand(); // call first time
            addEventListener('hashchange', () => {
                this.close();
                setTimeout(autoExpand);
            }); // call each hash change
            this.onDestroy(() => removeEventListener('hashchange', autoExpand)); // remove listener on destroy
        }
        this.checkState();

        if (this.prefs().widgetPrefix === 'refinement.section') {
            addEventListener('click', this.closeFromClickOutside.bind(this));
        }
    }
    onRefresh() {
        this.checkState();
    }
    setState(status = false) {
        if (this.id) {
            if (!toggleStates) {
                toggleStates = {};
            }
            toggleStates[this.id] = status;
        }
    }
    /**
     * @return {boolean}
     */
    getState() {
        if (this.id && typeof toggleStates[this.id] === 'boolean') {
            return toggleStates[this.id];
        }
        return this.content.length > 0 && !this.content.hasAttr('hidden');
    }
    checkState() {
        this[this.getState() ? 'open' : 'close'](false);
    }
    toggleContent() {
        if (this.getState()) {
            this.close();
        } else {
            this.open();
        }
    }
    // toggle content to the end of the session
    toggleContentSession() {
        this.toggleContent();
        getJSONByUrl(this.prefs().setSessionUrl);
    }
    open(animate = true, modifyClass = true) {
        if (modifyClass) {
            this.ref('self').addClass(this.prefs().classesActive);
        }
        this.eventBus().emit('ua-event.grid-switcher', this);

        const sizeGuideRedesignModal = document.querySelector('.size-guide-redesign');

        if (this.content) {
            this.content.show(() => {
                if (sizeGuideRedesignModal) {
                    if (this.prefs().widgetPrefix) {
                        this.eventBus().emit(this.prefs().widgetPrefix + '.opened', this);
                    }
                    if (this.prefs().scrollIntoView) {
                        if (this.content.els[0].parentElement) {
                            this.content.els[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                        } else {
                            this.content.els[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                        }
                    }
                } else {
                    if (this.prefs().scrollIntoView) {
                        this.content.scrollIntoView(this.prefs().scrollParent);
                    }
                    if (this.prefs().widgetPrefix) {
                        this.eventBus().emit(this.prefs().widgetPrefix + '.opened', this);
                    }
                }
            }, animate);
        }
        if (this.prefs().classesHtml) {
            this.ref('html').addClass(this.prefs().classesHtml);
        }
        if (this.prefs().triggerOpen) {
            this.eventBus().emit(this.prefs().triggerOpen, this);
        }
        if (this.prefs().buttonCloseText) {
            this.ref('self').setText(this.prefs().buttonCloseText);
        }
        this.setState(true);
    }
    close(animate = true, modifyClass = true) {
        if (modifyClass) {
            this.ref('self').removeClass(this.prefs().classesActive);
        }
        this.eventBus().emit('ua-event.grid-switcher', this);
        if (this.content) {
            this.content.hide(() => {
                if (this.prefs().widgetPrefix) {
                    this.eventBus().emit(this.prefs().widgetPrefix + '.closed', this);
                }
            }, animate);
        }
        if (this.prefs().classesHtml) {
            this.ref('html').removeClass(this.prefs().classesHtml);
        }
        if (this.prefs().triggerClose) {
            this.eventBus().emit(this.prefs().triggerClose, this);
        }
        if (this.prefs().buttonOpenText) {
            this.ref('self').setText(this.prefs().buttonOpenText);
        }
        this.setState(false);
    }
    openEvent(widget) {
        if (!(widget && this.id && (this.id === widget.id)) && !this.getState()) {
            this.open();
        }
    }
    closeEvent(widget) {
        if (!(widget && this.id && (this.id === widget.id)) && this.getState()) {
            this.close();
        }
    }
    openCountrySwitcher() {
        if (typeof(gle) !== 'undefined') {
            gle('ShippingSwitcher', 'show');

            const popupWrapper = document.querySelector('.glPopupContent');
            const countryDropdown = popupWrapper?.querySelector('#gle_selectedCountry');
            const currentCountry = countryDropdown.value;

            for (let country of UK_Site_Countries) {
                const countryOption = countryDropdown?.querySelector(`[value="${country}"]`);

                if (countryOption) {
                    countryDropdown?.removeChild(countryOption);
                }
            }

            if (UK_Site_Countries.indexOf(currentCountry) !== -1) {
                countryDropdown.selectedIndex = '0';
                GlobalE.ShippingSwitcher.OnCountryChanged(countryDropdown);
            }
        }
    }
    closeFromClickOutside(e) {
        if (document.documentElement.classList.contains('plp-layout-test-2')) {
            if (!this.ref('self')) {
                return;
            }

            const clickedOutside = !this.ref('self').get()?.contains(e.target);
            const isOpen = this.ref('self').hasClass(this.prefs().classesActive);

            if (clickedOutside && isOpen) {
                this.close();
            }
        }
    }
}
