export function getImage(name) {
    return {
        '@id': '-',
        _meta: {
            schema: 'http://bigcontent.io/cms/schema/v1/core#/definitions/image-link'
        },
        id: '-',
        name,
        endpoint: 'hush',
        defaultHost: 'cdn.media.amplience.net',
        mediaType: 'image'
    };
}