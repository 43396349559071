import viewtype from 'widgets/toolbox/viewtype';
import { RefElement } from './RefElement';

/* eslint-disable no-use-before-define */

export const log = window.console;

if (!log.table) {
    log.table = function() {};
}

/**
 * @param {string} message message with placeholders i.e. {0}
 * @param {...string} params values for placeholders
 * @returns {string}
 */
export function format(message, ...params) {
    return params.reduce((msg, param, idx) => {
        const reg = new RegExp('\\{' + idx + '\\}', 'gm');
        return msg.replace(reg, param);
    }, message);
}


/**
 * @param {Function} fn function to be called after specified time
 * @param {number} [time] time before call callback
 */
export function timeout(fn, time = 0) {
    var timer = setTimeout(() => {
        fn();
        timer = void 0;
        fn = void 0;
    }, time);

    return function () {
        if (timer) {
            clearTimeout(timer);
            timer = void 0;
            fn = void 0;
        }
    };
}

/**
 * @param {string} url initial url
 * @param {string} name name of params
 * @param {string} value value of param
 */
export function appendParamToURL(url, name, value) {
    // quit if the param already exists
    if (url.includes(name + '=')) {
        return url;
    }
    const [urlWithoutHash, hash] = url.split('#');

    const separator = urlWithoutHash.includes('?') ? '&' : '?';
    return urlWithoutHash + separator + name + '=' + encodeURIComponent(value) + (hash ? '#' + hash : '');
}

/**
 * @param {string} url initial url
 * @param {{[keys: string]: string}} params  parmas as key value-object
 */
export function appendParamsToUrl(url, params) {
    return Object.entries(params).reduce((acc, [name, value]) => {
        return appendParamToURL(acc, name, value);
    }, url);
}

/**
 * @function
 * @description remove the parameter and its value from the given url and returns the changed url
 * @param {string} url the url from which the parameter will be removed
 * @param {string} name the name of parameter that will be removed from url
 * @returns {string} url without parameter
 */
export function removeParamFromURL(url, name) {
    if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
        return url;
    }
    var hash;
    var params;
    var domain = url.split('?')[0];
    var paramUrl = url.split('?')[1];
    var newParams = [];
    // if there is a hash at the end, store the hash
    if (paramUrl.indexOf('#') > -1) {
        hash = paramUrl.split('#')[1] || '';
        paramUrl = paramUrl.split('#')[0];
    }
    params = paramUrl.split('&');
    for (var i = 0; i < params.length; i++) {
        // put back param to newParams array if it is not the one to be removed
        if (params[i].split('=')[0] !== name) {
            newParams.push(params[i]);
        }
    }

    var paramsString = newParams.length > 0 ? '?' + newParams.join('&') : '';

    return domain + paramsString + (hash ? '#' + hash : '');
}

/**
 * @function
 * @description replace the parameter with the given name by new value and returns the changed url
 * @param {string} url the url to which the parameter will be added
 * @param {string} name the name of the parameter
 * @param {string} value the new value of the parameter
 * @returns {string} url with parameter
 */
export function replaceParameterInURL(url, name, value) {
    url = removeParamFromURL(url, name);
    return appendParamToURL(url, name, value);
}

/**
 *
 * @param {string|undefined} [url] url
 */
export function getUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    /**
     * @type {{[x: string]: string|number|boolean}}
     */
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) {
                    obj[key] = [];
                }

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else if (!obj[paramName]) {
                // if it doesn't exist, create property
                obj[paramName] = decodeURIComponent(paramValue);
            } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                // if property does exist and it's a string, convert it to an array
                obj[paramName] = [obj[paramName]];
                obj[paramName].push(paramValue);
            } else {
                // otherwise add the property
                obj[paramName].push(paramValue);
            }
        }

    }

    return obj;
}

/**
 * @type {string[]}
 */
let errors = [];
/**
 *
 * @param {string|Error} message to show
 */
export function showErrorLayout(message) {
    const errorLayout = document.querySelector('#errorLayout');
    if (errorLayout) {

        if (message instanceof Error) {
            if (message.stack) {
                errors.unshift(message.stack);
            }
            errors.unshift(message.message);
        } else {
            errors.unshift(message);
        }

        log.error(message);
        errorLayout.addEventListener('click', () => {
            errorLayout.innerHTML = ''; errors = [];
        }, { once: true });

        errorLayout.innerHTML = `<div class="danger" style="
            bottom: 0;
            right: 0;
            position: fixed;
            background-color: #ff0000c7;
            border: black;
            padding: 5px;
            z-index: 9999999;
            border-radius: 10px;
        ">
                Error: <br/>
                ${errors.join('<hr/>')}
            </div>`;
    }
}

/**
 *
 * @param {Event} event DOM event
 * @param {HTMLElement} el element to track click on
 */
export function isEventTriggeredOutsideElement(event, el) {
    if (event.target && event.target instanceof Element) {
        let currElement = event.target;
        while (currElement.parentElement) {
            if (currElement === el) {
                return false;
            }
            currElement = currElement.parentElement;
        }
        return true;
    }
}

/**
 * @param {import('./RefElement').RefElement} el element to track click on
 * @param {*} cb callback
 * @param {boolean} [preventDefault] optional to prevent the default event
 */
export function clickOutside(el, cb, preventDefault = true) {
    //need for support desktop emulation
    const eventName = 'click';
    const eventNameTouch = 'touchend';
    const isTouchDevice = viewtype.isTouchDevice();

    let domEls = [];
    const norm = (Array.isArray(el) ? el : [el]);
    for (let i = 0; i < norm.length; i += 1) {
        const curr = norm[i];
        if (typeof curr === 'string') {
            domEls = domEls.concat([...(document.querySelectorAll(curr))]);
        } else {
            domEls.push(curr.get());
        }
    }

    /**
     * @type {EventListener|undefined}
     */
    var listener;
    function expose() {
        if (listener) {
            document.body.removeEventListener(eventName, listener);
            if (isTouchDevice) {
                document.body.removeEventListener(eventNameTouch, listener);
            }
            listener = void 0;
        }
    }

    if (domEls && domEls.length) {
        listener = function (event) {
            if (domEls.every(domEl => isEventTriggeredOutsideElement(event, domEl))) {
                if (cb(event) === false) {
                    expose();
                }
                if (preventDefault === true) {
                    event.preventDefault();
                }
            }
        };
        setTimeout(() => {
            if (listener) {
                document.body.addEventListener(eventName, listener);
                if (isTouchDevice) {
                    document.body.addEventListener(eventNameTouch, listener);
                }
            }
        }, 0);

        return expose;
    }
    throw new Error('Missing required el');
}

/**
 * @param {import('./RefElement').RefElement} el element to track swipe on
 * @param {number} distanceX required min horizonatal distance traveled to be considered swipe
 * @param {number} distanceY required min vertical distance traveled to be considered swipe
 */
export function swipeEvent(el, distanceX = 50, distanceY = 100) {
    const domEl = el.get();

    if (domEl) {
        let startX = 0;
        let startY = 0;

        domEl.addEventListener('touchstart', (e) => {
            var touchObj = e.changedTouches[0];
            startX = touchObj.pageX;
            startY = touchObj.pageY;
        }, { passive: true });

        domEl.addEventListener('touchend', (e) => {
            var touchObj = e.changedTouches[0];
            const swipeDistanceX = touchObj.pageX - startX;
            const swipeDistanceY = touchObj.pageY - startY;

            const isSwipeX = Math.abs(swipeDistanceX) >= distanceX &&
                        Math.abs(swipeDistanceY) <= distanceY;
            const isSwipeY = Math.abs(swipeDistanceX) <= distanceX &&
                        Math.abs(swipeDistanceY) >= distanceY;
            if (isSwipeX) {
                domEl.dispatchEvent(new CustomEvent(swipeDistanceX > 0 ? 'swiperight' : 'swipeleft', {
                    detail: e
                }));
            }  else if (isSwipeY) {
                domEl.dispatchEvent(new CustomEvent(swipeDistanceY > 0 ? 'swipedown' : 'swipeup', {
                    detail: e
                }));
            } 
        }, { passive: true });
    }
}

/**
 *
 * @param {any} x
 * @param {any} y
 * @returns {boolean}
 */
// eslint-disable-next-line complexity
export function objectEquals(x, y) {
    'use strict';

    if (x === null || x === void 0 || y === null || y === void 0) {
        return x === y;
    }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) {
        return x === y;
    }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) {
        return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) {
        return false;
    }
    if (!(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    var p = Object.keys(x);
    return Object.keys(y).every((i) => p.indexOf(i) !== -1) &&
        p.every((i) => objectEquals(x[i], y[i]));
}

/**
 * Generate an integer Array containing an arithmetic progression
 *
 * @param {number} start start from
 * @param {number|null} [stop] end on
 * @param {number} [step] step
 */
export function range(start, stop, step) {
    if (stop === null) {
        stop = start || 0;
        start = 0;
    }
    if (!step) {
        step = stop < start ? -1 : 1;
    }

    var length = Math.max(Math.ceil((stop - start) / step), 0);
    var range = Array(length);

    for (var idx = 0; idx < length; idx++, start += step) {
        range[idx] = start;
    }

    return range;
}

const rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;
/**
 * @param {string} data
 */
export function getData(data) {
    if (data === 'true') {
        return true;
    }

    if (data === 'false') {
        return false;
    }

    if (data === 'null') {
        return null;
    }

    // Only convert to a number if it doesn't change the string
    if (data === +data + '') {
        return +data;
    }

    if (rbrace.test(data)) {
        return JSON.parse(data);
    }

    return data;
}


export function memoize (func, hasher) {
    var memoizeInner = function (key) {
        var cache = memoizeInner.cache;
        var address = '' + (hasher ? hasher.apply(this, arguments) : key);
        if (typeof cache[address] === 'undefined') {
            cache[address] = func.apply(this, arguments);
        }
        return cache[address];
    };
    memoizeInner.cache = {};
    return memoizeInner;
}

/**
 * Add script tag on page
 *
 * @param {string} source url of script
 * @param {string} [globalObject]
 * @returns {Promise<any>}
 */
export const loadScript = memoize((source, globalObject) => {
    return new Promise((resolve, reject) => {
        var script = document.createElement('script'),
            prior = document.getElementsByTagName('script')[0];

        if (!prior || !prior.parentNode) {
            throw Error('No document');
        }

        script.async = true;
        script.type = 'text/javascript';
        prior.parentNode.insertBefore(script, prior);

        script.onload = (_, isAbort) => {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = void 0;

                if (isAbort) {
                    reject();
                } else if (globalObject) {
                    if (window[globalObject]) {
                        resolve(window[globalObject]);
                    } else {
                        reject();
                    }
                } else {
                    resolve();
                }
            }
        };
        script.onabort = () => {
            reject();
        };
        script.onerror = () => {
            reject();
        };
        script.onreadystatechange = script.onload;
        script.src = source;
    });
});
/**
 * @param {any} target
 * @param {string} path
 * @param {any} [defaults]
 */
export function get(target, path, defaults) {
    const parts = (path + '').split('.');
    let part;

    while (parts.length) {
        part = parts.shift();
        if (typeof target === 'object' && target !== null && part in target) {
            target = target[part];
        } else if (typeof target === 'string') {
            target = target[part];
            break;
        } else {
            target = defaults;
            break;
        }
    }
    return target;
}

/**
 * @param {object} target
 */
export function extend(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

/**
 * @param {string} URL
 */
export function replaceHistoryState(URL) {
    if (URL) {
        var regEx = /(?=\?).*/;
        var urlParams = URL.match(regEx)[0];
        var currentURL = window.location.href.replace(regEx, urlParams);
        window.history.replaceState(void 0, '', currentURL);
    }
}

/**
 * @param {string} URL
 */
export function pushHistoryState(URL) {
    if (URL) {
        window.history.pushState(void 0, '', URL);
    }
}

/**
 * detects if it is Safari on MacBook
 */
export function isSafari() {
    return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
}

/**
 * detects if it is Internet Explorer
 */
export function isIE() {
    return navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
}

/**
 * change the Submit Button State if form is valid
 * @param {Object} submitButton
 * @param {Boolean} isValidForm
 */

export function changeSubmitButtonState(submitButton, isValidForm) {
    if (isValidForm) {
        submitButton.enable();
    } else {
        submitButton.disable();
    }
}

/**
 * Simulation JQuery behavior for code,
 * which was taken from PayPal and Adyen integration cartridges
 *
 * @param {HTMLElement|String} element
 * @param {HTMLElement} parentNode
 * @returns {RefElement} RefElement
 */
export function $(element, parentNode = document) {
    let refElement = null;

    if (typeof element === 'string') {
        element = parentNode.querySelectorAll(element);
        refElement = new RefElement(Array.from(element));
    } else {
        refElement = new RefElement([element]);
    }

    return refElement;
}
