import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { appendParamToURL, loadScript } from 'widgets/toolbox/util';
import Widget from '../Widget';

/**
 * @param {string} productID
 * @param {string} carouselSource
 */
function getQuickViewURL(productID, carouselSource) {
    var url = appendParamToURL(window.Constants.URL_QUICKVIEW, window.Constants.PID, productID);
    url = appendParamToURL(url, 'carouselSource', carouselSource);
    if (window.GlobalE) {
        url = appendParamToURL(url, 'geCountry', window.GlobalE.Country);
        url = appendParamToURL(url, 'geCurrency', window.GlobalE.Currency);
    }
    return url;
}

export function withQuickView(ClassToExtend) {
    return class extends ClassToExtend {
        prefs() {
            return {
                quickViewClick: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.prefs().quickViewClick) {
                this.ev('click', (_, event) => {
                    event.preventDefault();
                    this.showQuickView(event);
                }, null, false);
            }
        }

        showQuickView(event) {
            disableScroll(true);
            showPageLoader(document.body, 'quickview');
            const isReccPopup = this.isReccomendationPopup(event);
            const reccData = isReccPopup ? this.getReccData(event) : {};
            const url = this.prefs().quickViewUrl || getQuickViewURL(this.prefs().pid, this.config.carouselSource);
            getJSONByUrl(url)
                .then(response => {
                    if (response.renderedTemplate) {
                        const reccoCssClass = isReccPopup ? ' modal-reccomendation' : '';
                        const modalCssClass = response.product && response.product.productType === 'set' ? 'modal-quickview-set' : 'modal-quickview';
                        if (response.bvScout) {
                            loadScript(response.bvScout);
                        }
                        this.eventBus().emit('openGlobalModal', {
                            content: response.renderedTemplate,
                            title: response.productName,
                            reccData: reccData,
                            'class': modalCssClass + reccoCssClass
                        }, true, (widget) => {
                            this.eventBus().emit('product.tile.quickview.open', this, widget, response.product.analytics);
                        });
                    }
                })
                .catch(() => enableScroll())
                .finally(() => hidePageLoader(document.body, 'quickview'));
        }

        getReccData(event) {
            const currElem = $(event.els[0]).closest('.b-carousel__slide');
            const totalElems = $(currElem).siblings().length + 1;
            const currentPos = $(currElem).prevAll().length + 1;
            const prevElem = currentPos !== 1 ? $(currElem).prev().children('div.b-product-tile').attr('data-pid') : '';
            const nextElem = currentPos !== totalElems ? $(currElem).next().children('div.b-product-tile').attr('data-pid') : '';
            const popupHeader = $(currElem).closest('.b-carousel__container').siblings('.b-carousel__recc-title').text();
            return {
                currentPos: currentPos,
                totalElems: totalElems,
                prevElem: prevElem,
                nextElem: nextElem,
                popupHeader: popupHeader
            };
        }

        isReccomendationPopup(event) {
            return event.els && $(event.els[0]).closest('div[data-ref="recomendationCarousel"]').length > 0;
        }
    };
}


/**
 * @description Product tile implementation
 */
const QuickView = withQuickView(Widget);
export default QuickView;