import preSnippet from './gtm/preSnippet';
import postSnippet from './gtm/postSnippet';
import { getGTMData } from './gtm/util';

function init() {
    if (window.gtmData) {
        const gtmData = getGTMData();
        preSnippet(gtmData);
        postSnippet(gtmData);
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    }, { once: true });
} else {
    init();
}