import InputText from "../forms/InputText";

export default class reCaptchaV3 extends InputText {
    prefs() {
        return {
            reCaptchaV3Object: grecaptcha,
            RECAPTHCA_SITE_KEY: this.config.sitePreferences.RECAPTHCA_SITE_KEY,
            defaultContext: 'contactus',
            ...super.prefs()
        };
    }

    /**
     * @description Initialize reCaptchaV3
     * @returns {void}
     */
    init() {
        var { reCaptchaV3Object, RECAPTHCA_SITE_KEY } = this.prefs();
        if (typeof reCaptchaV3Object !== 'undefined' && RECAPTHCA_SITE_KEY) {
            reCaptchaV3Object.ready(this.generateToken.bind(this));
        }
    }

    /**
     * @description Generate and enject reCaptchaV3 token to the form
     * @returns {void}
     */
    generateToken() {
        var { reCaptchaV3Object, RECAPTHCA_SITE_KEY, defaultContext } = this.prefs();
        var context = this.prefs().context || defaultContext;

        reCaptchaV3Object.execute(RECAPTHCA_SITE_KEY, {
            action: context
        }).then((token) => this.ref('field').val(token)).catch((err) => {
            console.error(err);
        });
    }
}