export class ContentSquareVars {
    constructor (gtmData, gtmPageData) {
        this.gtmData = gtmData;
        this.gtmPageData = gtmPageData;
    }

    init = () => {
        if (!this.isHushEnabled()) {
            return;
        }

        this.setManually();
        this.setPageDynamicVars();

        if (typeof window.CS_CONF === 'undefined') {
            this.appendScript();
        } else {
            window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
        }
    }

    isHushEnabled = () => {
        return typeof window.HUSH_CS_ENABLED !== 'undefined' && window.HUSH_CS_ENABLED;
    }

    appendScript = () => {
        var mt = document.createElement('script');
        mt.type = 'text/javascript';
        mt.async = true;
        mt.src = '//t.contentsquare.net/uxa/0829f597ffcf7.js';
        document.getElementsByTagName('head')[0].appendChild(mt);
    }

    setManually = () => {
        if (typeof this.gtmData !== 'undefined' && typeof this.gtmPageData !== 'undefined') {
            window._uxa.push(['setCustomVariable', 1, "type", this.gtmData.type]);
            window._uxa.push(['setCustomVariable', 2, "category1", this.gtmData.categories.category1]);
            window._uxa.push(['setCustomVariable', 3, "category2", this.gtmData.categories.category2]);
            window._uxa.push(['setCustomVariable', 4, "category3", this.gtmData.categories.category3]);
            window._uxa.push(['setCustomVariable', 5, "customerLoggedin", this.gtmData.customer.loggedin]);
            window._uxa.push(['setCustomVariable', 6, "checkoutstep", this.getCheckoutStep()]);

            const cartTotal = this.gtmPageData.cart && this.gtmPageData.cart.total || this.gtmData.cart.total;
            const newCustomer = this.gtmPageData.customer && this.gtmPageData.customer.newCustomer || this.gtmData.customer.newCustomer;
            const numProducts = this.gtmPageData.cart && this.gtmPageData.cart.numProducts || this.gtmData.cart.numProducts;

            window._uxa.push(['setCustomVariable', 7, "cartTotal", cartTotal]);
            window._uxa.push(['setCustomVariable', 8, "cartnum", numProducts]);
            window._uxa.push(['setCustomVariable', 9, "newcustomer", newCustomer]);
            window._uxa.push(['setCustomVariable', 10, "country", this.gtmData.country]);
            window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);

            const customerID = this.gtmPageData.customer ? this.gtmPageData.customer.sessionId : '';
            const email = this.gtmPageData.customer ? this.gtmPageData.customer.encryptedEmail : '';
            if (customerID) {
                window._uxa.push(['trackPageEvent', '@user-identifier@' + customerID]);
            }
            if (email) {
                window._uxa.push(['trackPageEvent', '@user-identifier@' + email]);
            }
        }
    }

    getCheckoutStep = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.has('step') ? searchParams.get('step') : '';
    }

    setPageDynamicVars = () => {
        let page = this.gtmData.type;
        ['category1', 'category2', 'category3'].forEach(category => {
            if (this.gtmData.categories[category] !== '') {
                page += '|' + this.gtmData.categories[category];
            }
        });
        window._uxa.push(['trackDynamicVariable', {key: 'sfcc_page', value: page}]);

        const value = `; ${document.cookie}`;
        const parts = value.split(`; crownpeakAISegment=`);
        if (parts.length === 2) {
            const segmentId = parts.pop().split(';').shift();
            window._uxa.push(['trackDynamicVariable', {key: 'sfcc_crowpeak_ai_segment', value: segmentId}]);
        }
    }
}
