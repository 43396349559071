import Widget from 'widgets/Widget';

/**
 * @description Base button implementation
 */
export default class TabPanel extends Widget {
    activate() {
        this.ref('self').show();
    }
    deactivate() {
        this.ref('self').hide();
    }
}