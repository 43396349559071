import Modal from './Modal';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @description Base ModalLink implementation
 * @param {typeof import('Modal').default} modal Base widget for extending
 */
export default class ModalLink extends Modal {
    prefs() {
        return {
            url: '',
            contentType: 'html', // html | json
            clickOutSide: true,
            classesModal: '',
            ...super.prefs()
        };
    }

    showModal() {
        if (!this.prefs().url.length) {
            return false;
        }

        if (this.prefs().contentType === 'json') {
            getJSONByUrl(this.prefs().url).then(res => {
                if (Object.keys(res).length) {
                    if (!this.prefs().useGlobalModal) {
                        super.showModal(res);
                    } else {
                        const isRedesignedSizeGuide = res.product?.isSizeGuideRedesign && res.product?.isSizeGuideRedesignTemplate;
                        this.eventBus().emit('openGlobalModal', {
                            ...res,
                            content: isRedesignedSizeGuide ? res.renderedTemplate : res.content,
                            title: isRedesignedSizeGuide ? 'Size Guide' : res.title,
                            'class': this.prefs().classesModal
                        });
                    }
                }
            });
        } else {
            getContentByUrl(this.prefs().url).then(res => {
                if (!this.prefs().useGlobalModal) {
                    super.showModal({ content: res });
                } else {
                    this.eventBus().emit('openGlobalModal', {
                        content: res,
                        'class': this.prefs().classesModal
                    });
                }
            });
        }
    }

    hide() {
        this.ref(this.prefs().refContainer).attr('hidden', true);
    }
}