import Carousel from 'widgets/global/Carousel';
import { objectEquals, extend, removeParamFromURL, timeout } from 'widgets/toolbox/util';

/**
 * @description image carousel implementation
 */
export default class ImageCarousel extends Carousel {
    prefs() {
        return {
            slides: [],
            slidesToShow: this.hasVerticalSlides ? 1 : (this.data('slidesToShow') || 2),
            slidesToShowMobile: 1,
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.slides = this.prepareSlidesData({
            images: this.prefs().slides
        });

        this.onDestroy(() => {
            this.carousel.off('move', this.onMove);
        });

        const verticalCarousel = this.ref('vertical-carousel-wrapper');
        this.hasVerticalSlides = verticalCarousel.els[0]?.style?.display !== 'none';
        const activatePDPVariant = window.activatePDPVariantCarousel;

        window.activatePDPVariantCarousel = (variant) => {
            variant = +variant;
            if (activatePDPVariant) {
                activatePDPVariant(variant);
            }
            this.hasVerticalSlides = true;
            this.initOptions();
            this.carousel.update(this.options);

            if (variant === 1) {
                this.disableHoverVerticalZoom = true;
            } else {
                this.disableHoverVerticalZoom = false;
            }

            /**
             * This looks silly since we passing breakpoints in options above
             * But sadly Glide.js uses them only in constructor so we need to fix it 
             * every time the carousel changes on resize
             */
            this.carousel.on(['resize'], () => {
                this.initOptions();
                this.carousel.update(this.options);
            });
        };
    }

    initEvents() {
        super.initEvents();
        this.carousel.on('move', this.onMove);
    }

    initOptions() {
        super.initOptions();
        extend(this.options, {
            loop: true,
            perView: this.prefs().slidesToShow,
            breakpoints: {
                1199: {
                    perView: this.prefs().slidesToShowMobile
                },
                9999: {
                    perView: this.prefs().slidesToShow
                }
            }
        }, this.prefs().carouselOptions || {});
    }

    changeSlide(ref) {
        if (document.querySelector('.pdp-layout-test-2')) {
            this.showZoom(ref);
            return;
        }

        let index = ref.els[0].dataset.index;
        if (index !== 0 && !index) {
            index = Array.from(ref.els[0].parentNode.parentNode.children).indexOf(ref.els[0].parentElement);
        }
        this.goto(index);
    }


    onMove = () => {
        const swipedElementIndex = this.carousel?._i;
        const slides = this.ref('vertical-carousel-wrapper').els[0].querySelectorAll('.b-carousel__slide');
        
        slides.forEach((el, idx) => {
            if (idx === swipedElementIndex) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    }

    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} ref
     */
    showZoom(ref) {
        if (window.hoverZoomEnabledOnPdp) {
            return;
        }

        if (this.has('productZoomModal')) {
            /** @type {any[]} */
            const zoomUrls = [];

            if (this.slides && this.slides.images && this.slides.images.length) {
                this.slides.images.map((imageData, imageIndex) => {
                    zoomUrls.push({
                        index: imageIndex,
                        url: removeParamFromURL(removeParamFromURL(imageData.url, 'sw'), 'sh'),
                        alt: imageData.alt || '',
                        title: imageData.title || ''
                    });
                });
            } else if (this.items && this.items.length) {
                this.items.map((widget, widgetIndex) => {
                    if (widgetIndex < this.slides.length) {
                        const pictureSouces = widget.ref('self').data('srcStandard');
                        if (pictureSouces && pictureSouces.url) {
                            zoomUrls.push({
                                index: widgetIndex,
                                url: removeParamFromURL(removeParamFromURL(pictureSouces.url, 'sw'), 'sh'),
                                alt: pictureSouces.alt || '',
                                title: pictureSouces.title || ''
                            });
                        }
                    }
                });
            }

            this.ref('productZoomModal').data('showModal', {
                images: zoomUrls,
                controls: true,
                carouselOptions: JSON.stringify({
                    loop: true,
                    startAt: ref.data('index')
                })
            });
        }
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
    }

    /**
     * @param {object} slides
     */
    prepareSlidesData(slides) {
        const updatedSlides = slides;
        if (updatedSlides.images) {
            updatedSlides.images = updatedSlides.images.map(image => {
                if (image.url) {
                    image.title = image.title.trim();
                }
                if (image.alt) {
                    image.alt = image.alt.trim();
                }
                return image;
            });
        }
        return updatedSlides;
    }

    /**
     *
     * @param {any} slides data for slide rendering
     */
    updateSlides(slides) {
        const newSlides = this.prepareSlidesData(slides);
        if (!objectEquals(this.slides, newSlides)) {
            this.slides = newSlides;
            this.deactivate();
            timeout(() => {
                this.render('templateSlides', this.slides, this.ref('slides').empty());
                this.render('templateVerticalSlides', this.slides, this.ref('vertical-slides').empty());
                this.render('templateBullets', this.slides, this.ref('bullets').empty());
                this.activate();
            });
        }
    }

    handleNavClick() {
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
    }
}