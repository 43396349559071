import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 *
 * @param {HTMLFormElement} el
 * @param {Event} event
 * @param {object} ctx
 * @param {function} responseHandler
 */
export default function(el, event, ctx, responseHandler) {
    // don't prevent default behavior if 'check availability' link doesn't exist
    var checkAvailabilityLink = el.data('av-check-href');
    if (!checkAvailabilityLink) {
        return;
    }

    event.preventDefault();
    if (el.hasClass('m-disabled')) {
        return;
    }

    ctx.showProgressBar();
    submitFormJson(checkAvailabilityLink, {}, 'GET')
        .then((resp) => {
            if (resp.error && resp.redirectUrl) {
                window.location.assign(resp.redirectUrl);
            } else if (resp.hasCartProductErrors || (ctx.prefs().isCart && resp.hasLimitExceeded)) {
                responseHandler.call(ctx, resp);
                ctx.hideProgressBar();
            } else {
                var href = el.attr('href');
                if (href) {
                    window.location.assign(href);
                } else {
                    ctx.hideProgressBar();
                }
            }
        })
        .catch((error) => {
            if (error && error.error && error.errorMessage) {
                ctx.render('errorTemplate', { message: error.errorMessage }, ctx.ref('errorMsgs'));
            }
            ctx.hideProgressBar();
        });
}