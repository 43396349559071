import BasicInput from '../forms/BasicInput';
import { timeout } from '../toolbox/util';

export default class InputNumber extends BasicInput {
    prefs() {
        return {
            maxValue: this.ref('field').attr('data-max-value') || 99,
            minValue: this.ref('field').attr('data-min-value') || 0,
            allowEmpty: false,
            parseError: '',
            requiredtext: '',
            classesDisabled: 'm-disabled',
            informAttemptDecreaseMinVal: false,
            patternMismatch: this.ref('field').attr('data-pattern-mismatch') ||
                this.config.Resources.msg.number.patternMismatch,
            ...super.prefs()
        };
    }
    init() {
        super.init();

        this.maxLengthAttr = this.ref('field').attr('maxlength');
        this.step = parseFloat(this.ref('field').attr('step')) || 1;

        this.initValue = this.getValue();
    }
    /**
     *
     * @param {HTMLInputElement} el
     * @param {Event} event
     */
    handleKeyDown(el, event) {
        if (!event && window.event) {
            event = window.event;
        }
        const keyCode = event.keyCode || event.which;

        // as a rule, each service key has key length more than 1, e.g. Enter, Control, ArrowUp, Tab etc.
        const isServiceKey = key => key.length > 1;

        const intValue = parseInt(event.key);
        if (!isServiceKey(event.key) && isNaN(intValue)) {
            event.preventDefault();
            return false;
        }

        // special handling for old devices
        if (this.maxLengthAttr && (el.value.toString().length + 1) >= this.maxLengthAttr) {
            this.onDestroy(timeout(() => {
                this.setValue(this.getValue(), true);
            }, 0));
        } else if (keyCode === 13) {
            // Enter pressed
            this.changeValue();
            event.preventDefault();
        }
    }
    changeValue() {
        if (Number(this.getValue()) < this.prefs().minValue && this.prefs().informAttemptDecreaseMinVal) {
            this.onDestroy(timeout(() => {
                this.emit('attemptdecreaseminval');
                this.setValue(String(this.prevVal || this.initValue), true);
            }, 100));

        } else {
            this.setValue(this.getValue());
            this.validate();
        }
    }
    decrement() {
        if (!this.isDisabled()) {
            const newVal = parseFloat(this.getValue()) - this.step;

            if (newVal >= this.prefs().minValue) {
                this.setValue(newVal.toString());
            } else if (this.prefs().informAttemptDecreaseMinVal) {
                this.emit('attemptdecreaseminval');
            }
        }
    }
    increment() {
        if (!this.isDisabled()) {
            const newVal = parseFloat(this.getValue()) + this.step;

            if (newVal <= this.prefs().maxValue) {
                this.setValue(newVal.toString());
            } else {
                this.emit('quantityNotAllowed');
            }
        }
    }
    /**
     * @param {string} val
     * @param {boolean} silent
     */
    setValue(val, silent = false) {
        if (val === '' && this.prefs().allowEmpty) {
            super.setValue('');
        } else {
            const value = typeof val !== 'number' ? parseFloat(val) : val;

            if (value >= this.prefs().minValue && value <= this.prefs().maxValue) {
                super.setValue(value.toString(), silent);
                this.prevVal = value;
            } else if (value > this.prefs().maxValue) {
                super.setValue(String(this.prefs().maxValue), silent);
            } else if (value < this.prefs().minValue || isNaN(value)) {
                super.setValue(String(this.prevVal || this.prefs().minValue), silent);
            } else {
                super.setValue('', silent);
            }
        }

        const value = parseFloat(this.getValue());

        if (this.has('moreBtn') && this.has('lessBtn')) {
            this.ref('moreBtn').enable();
            this.ref('lessBtn').enable();

            if (this.prefs().minValue > (value - this.step)) {
                if (!this.prefs().informAttemptDecreaseMinVal) {
                    this.ref('lessBtn').disable();
                }
            }
            if (this.prefs().maxValue === 1 || this.prefs().maxValue < (value + this.step)) {
                this.ref('moreBtn').disable();
            }
        }
    }
    onRefresh() {
        this.setValue(this.ref('field').attr('value'), true);
        this.ref('field').attr('maxlength', this.prefs().maxValue.toString().length.toFixed());
    }
    isValid() {

        const isSuperValid = super.isValid();

        if (isSuperValid) {
            const val = parseFloat(this.getValue());

            if (val < this.prefs().minValue && val > this.prefs().maxValue) {
                this.error = this.getValue() ? this.prefs().parseError : this.prefs().requiredtext;
                return false;
            }
            return true;
        }
        return isSuperValid;
    }
    validate() {
        if (this.disabled) {
            return true;
        }
        const isValid = this.isValid();
        if (isValid) {
            this.clearError();
        } else {
            this.setError(this.error);

        }
        return isValid;
    }
    onChange() {
        this.setValue(this.getValue());
    }
}