import Widget from '../Widget';

/**
 * @description Widget which handles search suggestion events
 */
export default class searchSuggestions extends Widget {
    init () {
        this.type = this.getType();
        this.eventBus().on('sync.menu.products', 'syncProducts');
    }

    getType () {
        if (this.ref('self').els[0].classList.contains('default-product-suggestions')) {
            return 'preloaded';
        }

        return 'current';
    }

    syncProducts (data) {
        if (this.type === data.type) {
            this.ref('self').show();

            if (this.type === 'current') {
                this.ref('self').els[0].innerHTML = data.markup;
            } else {
                this.eventBus().emit('search.carousel.init');
            }
        } else {
            this.ref('self').hide();
        }
    }

    showSuggestions (response) {
        const suggestionWrapper = this.ref('self').els[0];
        const suggestionElement = suggestionWrapper.querySelector('suggestions') || suggestionWrapper;
        suggestionElement.innerHTML = response;
    }
}