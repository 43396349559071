import InputEmail from './InputEmail';

/**
 * @description Input Email Notify Implementation
 */
export default class NotifyMeEmail extends InputEmail {
    prefs() {
        return {
            field: 'field',
            ...super.prefs()
        };
    }
    init() {
        this.eventBus().on('notifyMe.submitted', 'submittedNotifyMeForm');
    }
    notifyMeFieldFocus() {
        // clear errors if email field is empty
        if (!this.ref(this.prefs().field).val()) {
            super.clearError();
        }

        // event for hiding of success message
        this.emit('focusin');
    }
    submittedNotifyMeForm() {
        this.ref(this.prefs().field).val('');
    }
    focusOut() {
        this.activateSubmitButton();
    }
    checkboxState() {
        this.activateSubmitButton();
    }
    activateSubmitButton() {
        this.eventBus().emit('notifyMe.submitButtonState', super.validate());
    }
}