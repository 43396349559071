import { timeout } from 'widgets/toolbox/util';
import NavigationBar from './NavigationBar';

const toggled_class = 'search-toggled';
const mobileMenuRef = 'mobileMenu';

/**
 * @description Main navigation implementation
 */
export default class Navigation extends NavigationBar {
    prefs() {
        return {
            isPlp: false,
            isPdp: false,
            webViewNavCtnr: 'webViewNavCtnr',
            webViewCtgCtnr: 'webViewCtgCtnr',
            webViewCtgTmpl: 'webViewCtgTmpl',
            webViewNavTmpl: 'webViewNavTmpl',
            categoryRefinement: 'categoryRefinement',
            ...super.prefs()
        };
    }

    init() {
        this.checkABTest();
        super.init();

        this.eventBus().on('nav.menu.close', 'closeNavBar');
        this.eventBus().on('pagedata.send', 'initWebViewNav');
        this.eventBus().on('webview.show.categories', 'showWebViewCategories');
        this.eventBus().on('plp.refbar.refvalue.click', 'updateWebViewNav');
        this.eventBus().on('mobile.search.show', 'showMobileSearch');
        this.eventBus().on('mobile.search.hide', 'hideMobileSearch');
    }

    showMobileSearch () {
        this.ref(mobileMenuRef).addClass(toggled_class);
    }

    hideMobileSearch () {
        this.ref(mobileMenuRef).removeClass(toggled_class);
    }

    goBack() {
        window.history.back();
    }

    initWebViewNav(data) {
        this.isWebView = !!data.isWebView;
        if (this.isWebView && !this.prefs().isPlp && !this.prefs().isPdp) {
            this.render(this.prefs().webViewCtgTmpl, { }, this.ref(this.prefs().webViewCtgCtnr));
        }
    }

    updateWebViewNav(element, widget = null) {
        if (!this.isWebView || widget === null || widget.prefs().id !== this.prefs().categoryRefinement) {
            return true;
        }

        var viewData = {
            title: element.getText(),
            url: widget.prefs().topCategoryUrl
        };

        if (viewData.url) {
            this.ref(this.prefs().webViewNavCtnr).setText();
            this.render(this.prefs().webViewNavTmpl, viewData, this.ref(this.prefs().webViewNavCtnr));
        }
    }

    showWebViewCategories() {
        if (!this.isWebView) {
            return true;
        }

        this.ref(this.prefs().webViewNavCtnr).hide();
        this.render(this.prefs().webViewCtgTmpl, { }, this.ref(this.prefs().webViewCtgCtnr));

        timeout(() => {
            this.has('webviewCategories', webviewcategories => webviewcategories.toggle());
        }, 0);
    }

    hideWebViewCategories() {
        if (this.prefs().isPlp) {
            this.ref(this.prefs().webViewCtgCtnr).hide();
            this.ref(this.prefs().webViewNavCtnr).show();
        }
    }

    checkABTest() {
        if (this.data('displayIcon')) {
            try {
                var mobileNav = this.ref('self').els[0].querySelector('ul.b-hamburger');
                if (mobileNav) {
                    mobileNav.querySelectorAll('.b-hamburger__item').forEach((item) => {
                        if (item.querySelector('a[hidden="hidden"]')) {
                            item.querySelectorAll('a').forEach((link) => {
                                if (link.hidden) {
                                    link.hidden = false;
                                } else {
                                    link.hidden = true;
                                }
                            });
                        }
                    });
                }

            } catch (e) {
                window.console.log('a/b test error ' + e);
            }
        }
    }
}