import InfiniteScrollLoadedContent from '../global/InfiniteScrollLoadedContent';
import { submitFormJson } from '../toolbox/ajax';
import { showPageLoader, hidePageLoader } from '../toolbox/progress';

export default class AddressesMgr extends InfiniteScrollLoadedContent {
    setDefault(button) {
        this.showProgressBar();

        submitFormJson(button.attr('href'))
            .then((response) => {
                this.render('template', response);
            })
            .finally(() => {
                this.hideProgressBar();
            });
    }

    removeAddress(removeButton) {
        this.removeButton = removeButton;
        this.ref('deleteAddressModal')
            .showDialog({
                removeUrl: removeButton.attr('data-url')
            });
    }

    confirmedRemoveAddress() {
        if (this.removeButton) {
            this.showProgressBar();

            submitFormJson(this.removeButton.attr('data-url'), {}, 'GET')
                .then((response) => {
                    this.render('template', response);
                })
                .finally(() => {
                    this.hideProgressBar();
                });

            delete this.removeButton;
        }
    }

    /**
     * @param {object[]} items
     */
    renderItems(items) {
        this.render('template', {
            addressBook: items,
            defaultAddresses: this.prefs().defaultAddresses,
            isDefaultAddresses: !!Object.keys(this.prefs().defaultAddresses).length
        });
    }

    showProgressBar() {
        showPageLoader();
    }

    hideProgressBar() {
        hidePageLoader();
    }
}