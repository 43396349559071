import Widget from "../Widget";

/**
 * @description Base button implementation
 */
export default class Tooltip extends Widget {
    prefs() {
        return {
            content: 'content',
            ...super.prefs()
        };
    }
    showTip() {
        this.ref(this.prefs().content).show();
    }
    hideTip() {
        this.ref(this.prefs().content).hide();
    }
}