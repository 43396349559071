import { timeout } from 'widgets/toolbox/util';
import Widget from '../Widget';

/**
 * @description Base dropdown implementation
 */
export default class StickyElement extends Widget {
    init() {
        if (!this.stickyTop) {
            this.stickyTop = this.ref('self').offset().top;
        }
        this.allowAnimation = true;

        if (this.prefs().classesHtml) {
            this.check();
            window.addEventListener('scroll', this.check.bind(this));
            this.ref('self').reflow();
        }

        let bannersWrapper = document.querySelectorAll('.b-navigation-flyout__banners');
        if (bannersWrapper && bannersWrapper.length) {
            for (let ban = 0; ban < bannersWrapper.length; ban++) {
                bannersWrapper[ban].parentElement.parentElement.classList.add('flyout--banners');
            }
        }
    }

    check() {
        if (this.allowAnimation) {
            window.requestAnimationFrame(() => {
                if (this.ref('self').els[0].querySelector('.l-header__modal-background')) {
                    this.ref('self').els[0].querySelector('.l-header__modal-background').classList.add('l-header__modal-minicart');
                }
                if ((window.pageYOffset || document.documentElement.scrollTop) > this.stickyTop) {
                    if (!this.sticky) {
                        this.ref('html').addClass(this.prefs().classesHtml);
                        this.sticky = true;
                        this.preventGlitches();
                    }
                } else if (this.sticky) {
                    this.ref('html').removeClass(this.prefs().classesHtml);
                    this.sticky = false;
                    this.preventGlitches();
                }
            });
        }
    }

    preventGlitches() {
        this.allowAnimation = false;
        timeout(() => {
            this.allowAnimation = true;
        }, this.config.Constants.FLYOUT_TIMEOUT_DELAY);
    }

    mouseEnter() {
        if (this.timerCleaner) {
            this.timerCleaner();
        }
        this.timerCleaner = timeout(() => {
            this.ref('html').addClass(this.prefs().classesHtmlHover);
        }, this.config.Constants.FLYOUT_TIMEOUT_DELAY);
    }

    mouseLeave() {
        if (this.timerCleaner) {
            this.timerCleaner();
        }
        this.timerCleaner = timeout(() => {
            this.ref('html').removeClass(this.prefs().classesHtmlHover);
        }, this.config.Constants.FLYOUT_TIMEOUT_DELAY);
    }
}