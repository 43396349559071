import QuickView from 'widgets/product/QuickView';
import { enableScroll } from 'widgets/toolbox/scroll';

export default class QuickViewUrlObserver extends QuickView {
  prefs() {
    return {
      ...super.prefs(),
      quickViewClick: false,
      pid: this.pid,
      linkTarget: '?quickview=true',
    };
  }

  init() {
    if (this.initialised) {
      return;
    }

    super.init();

    const content = this.ref('self').get(0);
    if (!content) {
      console.warn(`Content element with data-widget="${this.dataWidgetSelector}" not found.`);
      return;
    }

    this.eventBus().on('closeModal', 'handleCloseQuickView');
    content.addEventListener('click', this.handleQuickViewClick);
    this.initialised = true;
    this.onDestroy(() => {
      content.removeEventListener('click', this.handleQuickViewClick);
    });
  }

  handleQuickViewClick = (event) => {
    const { target } = event;
    const linkTarget = this.prefs()?.linkTarget;

    if (target.tagName !== 'A' || !target.href || !target.href.includes(linkTarget)) {
      return false;
    }

    event.preventDefault();

    const pid = this.getPidFromUrl(event.target.getAttribute('href'));

    if (pid) {
      this.pid = pid;
      this.showQuickView(event);
    }
  };

  handleCloseQuickView() {
    document.documentElement.classList.remove('disable-page-scroll');
  }

  getPidFromUrl(url) {
    const pattern = /\/(\d{6}-\d{4})\.html/;
    const match = url.match(pattern);

    return match ? match[1] : false;
  }
}
