/** @type {any} */
window.customerWishlist = {
    items: [],
    length: 0
};

const CustomerWishlist = {
    set(wishlist) {
        window.customerWishlist = wishlist;
    },

    get() {
        return window.customerWishlist;
    },

    add(productID = '') {
        if (productID) {
            if (!window.customerWishlist.items) {
                window.customerWishlist = {
                    items: [],
                    length: 0
                };
            }
            window.customerWishlist.items.push({
                pid: productID
            });
            window.customerWishlist.length++;
        }
    }
};

export default CustomerWishlist;
