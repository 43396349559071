import { RefElement } from 'widgets/toolbox/RefElement';

const DISSABLE_SCROLL_CLASS = 'disable-page-scroll';
const MINIMIZED_HEADER_DIFF = 20;

var currentScrollTop = 0;
var scrollDisabled = false;
var scrollDisabledCounter = 0;

/**
 * @return {Number} page scroll position
 */
export function getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

/**
 * @return {Number} height of site header
 */
export function getHeaderHeight() {
    const pageHeader = document.getElementById('pageHeader');
    return pageHeader ? pageHeader.clientHeight : 0;
}

/**
 * @param {Number} topValue top value
 * @param {Number} leftValue left value
 * @param {'auto'|'smooth'|undefined} behaviorVal  auto | smooth
 */
export function windowScrollTo(topValue = 0, leftValue = 0, behaviorVal = 'smooth') {
    window.scrollTo({ top: topValue - getHeaderHeight(), left: leftValue, behavior: behaviorVal });
    if (currentScrollTop > 0) {
        currentScrollTop = topValue;
        document.getElementById('page').scrollTop = currentScrollTop;
    }
}

/**
 * @param {HTMLElement|undefined} element - HTML Element
 */
export function scrollTo(element) {
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
}

/**
 * Scrolling to top.
 *
 * @param {'auto'|'smooth'|undefined} behaviorVal  auto | smooth value
 */
export function scrollToTop(behaviorVal = 'smooth') {
    windowScrollTo(-MINIMIZED_HEADER_DIFF, 0, behaviorVal);
}

/**
 * Check if element is in viewport
 *
 * @param {HTMLElement} element - HTML Element
 * @param {Boolean} fullyVisible - true if element should be fully visible
 */
export function elementInViewport(element, fullyVisible = false) {
    var $element = new RefElement([element]);
    var offset = $element.offset();
    var top = offset.top;
    var left = offset.left;
    var width = element.clientWidth;
    var height = element.clientHeight;

    var wHeight = window.innerHeight;
    var wWidth = window.innerWidth;
    var wYOffset = window.pageYOffset;
    var wXOffset = window.pageXOffset;

    if (wXOffset === null) {
        wHeight = window.document.documentElement.clientHeight;
        wWidth = window.document.documentElement.clientWidth;
        wYOffset = window.document.documentElement.scrollTop;
        wXOffset = window.document.documentElement.scrollLeft;
    }

    var headerHeight = getHeaderHeight();
    top -= headerHeight;
    wHeight -= headerHeight;

    if (fullyVisible) {
        return (
            top > wYOffset &&
            left > wXOffset &&
            (top + height) < (wYOffset + wHeight) &&
            (left + width) < (wXOffset + wWidth)
        );
    }

    return (
        top < (wYOffset + wHeight) &&
        left < (wXOffset + wWidth) &&
        (top + height) > wYOffset &&
        (left + width) > wXOffset
    );
}

/**
 * Returns object with element offset
 *
 * @param {HTMLElement|null} element
 */
export function elementOffset(element) {
    const offset = { top: 0, left: 0 };

    if (element) {
        const docElem = document.documentElement;
        const elemBox = element.getBoundingClientRect();

        offset.top = elemBox.top + window.pageYOffset - docElem.clientTop;
        offset.left = elemBox.left + window.pageXOffset - docElem.clientLeft;
    }

    return offset;
}

/**
 * Scroll window to keep element in viewport
 *
 * @param {HTMLElement} element
 */
export function scrollIntoView(element) {
    if (element && !elementInViewport(element, true)) {
        const headerHeight = getHeaderHeight();
        const windowHeight = window.innerHeight - headerHeight;
        const elOffset = elementOffset(element);
        let elScrollTop = elOffset.top;
        if (elOffset.top > getScrollTop() + headerHeight && element.clientHeight < windowHeight) {
            elScrollTop = elOffset.top + element.clientHeight - windowHeight;
        }
        windowScrollTo(elScrollTop);
    }
}

/**
 * Event for disabling document scrolling
 */
var disableScrollEvent = () => {
    document.body.scrollTop = 0;
};

/**
 * Disable page scrolling.
 *
 * @param {Boolean} skipCounter - skip increasing of counter
 */
export function disableScroll(skipCounter = false) {
    if (!skipCounter) {
        scrollDisabledCounter++;
    }

    if (scrollDisabled) {
        return;
    }

    const oldBodyWidth = document.body.clientWidth;

    currentScrollTop = getScrollTop();
    document.documentElement.classList.add(DISSABLE_SCROLL_CLASS);
    document.getElementById('page').scrollTop = currentScrollTop;
    document.addEventListener('touchmove', disableScrollEvent);

    // compensate scrollbar width if it was present
    if (document.body.clientWidth > oldBodyWidth) {
        document.body.style.borderRightWidth = document.body.clientWidth - oldBodyWidth + 'px';
    }

    scrollDisabled = true;
}

/**
 * Enable page scrolling.
 */
export function enableScroll() {
    scrollDisabledCounter--;
    if (scrollDisabledCounter > 0) {
        return;
    }

    document.removeEventListener('touchmove', disableScrollEvent);
    document.documentElement.classList.remove(DISSABLE_SCROLL_CLASS);
    window.scrollTo(0, currentScrollTop);
    currentScrollTop = 0;

    document.body.style.borderRightWidth = '';

    scrollDisabled = false;
}
