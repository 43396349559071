const smBreakpoint = Number(getComputedStyle(document.documentElement).getPropertyValue('--sm').replace('px', ''));

function setSrc(element) {
    if (element.hasAttribute('data-src')) {
        element.setAttribute('src', element.getAttribute('data-src'));
        element.removeAttribute('data-src');
    }
}

function handleDataSrc(video) {
    let videosToEnable;
    if (window.innerWidth < smBreakpoint) {
        // enable mobile videos
        videosToEnable = video.querySelectorAll('[data-mobile]');
    } else {
        // enable desktop videos
        videosToEnable = video.querySelectorAll('[data-desktop]');
    }
    videosToEnable.forEach(setSrc);
    video.setAttribute('src', videosToEnable[0].getAttribute('src'));
}

function autoplay(video, isIntersecting) {
    if (!video.hasAttribute('data-autoplay')) {
        return;
    }
    if (isIntersecting) {
        video.play();
    } else {
        video.pause();
    }
}

const videoObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            handleDataSrc(entry.target);
            autoplay(entry.target, true);
        } else {
            autoplay(entry.target, false);
        }
    });
});

addEventListener('DOMContentLoaded', () => document.querySelectorAll('[data-next-video]').forEach(v => videoObserver.observe(v)));

