import InputText from "./InputText";

/**
 * @description Implementation
 */
export default class inputTextPostal extends InputText {
    prefs() {
        return {
            classesRequired: 'required',
            ...super.prefs()
        };
    }
    init() {
        super.init();
        this.eventBus().on('update.postal.field', 'updatePostalField');
    }

    // update postal field in shipping OR billing form
    updatePostalField(selectedValue, className) {
        var field = this.ref('field');
        if (field.hasClass(className)) {
            this.changePostalFieldAttr(field, selectedValue);
        } else if (field.hasClass('js-billing-zip')) {
            this.changePostalFieldAttr(field, selectedValue);
        } else if (this.ref('self').hasClass(className)) {
            this.changePostalFieldAttr(field, selectedValue);
        }
    }

    changePostalFieldAttr(field, selectedValue) {
        if (selectedValue === this.config.Resources.preferences.defaultCountryCode) {
            field.attr('required', true);
            field.attr('pattern', this.config.Resources.pattern.zipUK);
            this.ref('self').addClass(this.prefs().classesRequired);
        } else {
            field.attr('required', false);
            field.attr('pattern', this.config.Resources.pattern.zipROW);
            this.ref('self').removeClass(this.prefs().classesRequired);
        }
    }
}