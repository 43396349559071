import { clickOutside } from 'widgets/toolbox/util';
import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import Widget from '../Widget';
const ESCAPE_CODE = 27;
/**
 * @description Base button implementation
 */
export default class ConfirmDialog extends Widget {
    prefs() {
        return {
            classesGlobalOpened: 'modal-open',
            classesShow: 'show',
            ...super.prefs()
        };
    }
    /**
     *
     * @param {any} templateData data to be rendered in template
     */
    showDialog(templateData) {
        const container = this.ref('container');
        this.render(void 0, templateData, container);
        container.addClass(this.prefs().classesShow);

        this.cleanUpListener();
        this.listener = clickOutside(container, () => {
            this.cancel();
            return false;
        });
        this.onDestroy(this.listener);

        this.escHandler = this.ev('keydown', (_, event) => {
            if (event.keyCode === ESCAPE_CODE) {
                event.preventDefault();
                this.cancel();
            }
        }, window, false).pop();

        this.ref('self').show();
        disableScroll();
        this.ref('html').addClass(this.prefs().classesGlobalOpened);
    }
    hideDialog() {
        this.ref('container').removeClass(this.prefs().classesShow);
        this.ref('self').hide();
        enableScroll();
        this.ref('html').removeClass(this.prefs().classesGlobalOpened);
        this.cleanUpListener();
    }
    cleanUpListener() {
        if (this.listener) {
            this.listener();
            this.listener = void 0;
        }
        if (this.escHandler) {
            this.escHandler();
            this.escHandler = void 0;
        }
    }
    confirm() {
        this.hideDialog();
        this.emit('confirm');
    }
    cancel() {
        this.hideDialog();
        this.emit('cancel');
    }
}