import ToggleButton from '../global/ToggleButton';
import { getJSONByUrl } from '../toolbox/ajax';

/**
 * @description Emarsys event handlers
 * @param {ReturnType<typeof import('widgets/global/toggleButton').default>} baseWidget Base widget for extending
 */
export default class submitButton extends ToggleButton {
    sendRequest (url, requestData) {
        return getJSONByUrl(url, requestData);
    }

    sendAllRequests (requestData) {
        var promises = [];
        for (var url in requestData) {
            promises.push(this.sendRequest(url, requestData[url]));
        }
        return Promise.all(promises);
    }

    enableButton () {
        this.ref('self').attr('disabled', false);
    }

    disableButton () {
        this.ref('self').attr('disabled', true);
    }

    submitPreferences () {
        this.disableButton();

        var formGroups = document.querySelectorAll('#dashboard-marketing-subscriptions .form-group');

        var requestData = {};

        for (var i = 0; i < formGroups.length; i++) {
            var currentFormGroup = formGroups[i];
            var destinationURL = currentFormGroup.getAttribute('data-url');

            if (!destinationURL) {
                continue;
            }

            if (!requestData[destinationURL]) {
                requestData[destinationURL] = {};
            }

            var groupValue = currentFormGroup.getAttribute('data-value');

            var currentInputField = currentFormGroup.querySelector('input[type="checkbox"]');

            requestData[destinationURL][groupValue] = currentInputField.checked;
        }

        this.sendAllRequests(requestData).finally(this.enableButton.bind(this));
    }
}