import customerWishlist from 'widgets/toolbox/wishlist';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import Widget from '../Widget';
import viewtype from 'widgets/toolbox/viewtype';

export default class AccountMgr extends Widget {
    init() {
        if ($('.loyalty-top').length === 0) {
            $('.b-account-title').css({flexBasis: 'unset'});
        }
        this.viewTypeChange();
    }
    viewTypeChange() {
        this.isMobileView = viewtype.isMobileView();
        var orderHistoryEl = this.ref('orderHistory');
        var viewAllOrdersEl = this.ref('viewAllOrders');
        orderHistoryEl.attr('target', this.isMobileView ? '_blank' : '_self');
        viewAllOrdersEl.attr('target', this.isMobileView ? '_blank' : '_self');
    }
    removeWishlistItem(button) {
        getJSONByUrl(button.config.url).then(response => {
            if (!response.error) {
                this.renderWishlistItems(response);
                this.eventBus().emit('wishlist.removed', {
                    pid: response.removedProductID,
                    count: response.wishlist && response.wishlist.length,
                    productName: response.productName
                });
                if (response.wishlist) {
                    customerWishlist.set(response.wishlist);
                }
            } else if (response.errorMsg) {
                this.ref(button.config.errorRef).setText(response.errorMsg);
                this.ref(button.config.errorRef).show();
            }
        });
    }

    /**
     * show subscription error at the top of my account page
     * @param {object} el
     * @param {string} isError
     */

    showTopError (el, isError) {
        var errorFeedback = this.ref('errorFeedbackMyAccountTop');
        if (isError) {
            errorFeedback.setText(isError);
            errorFeedback.show();
        } else {
            errorFeedback.hide();
        }
    }

    /**
     * Render wishlist items
     * @param {object} response
     */

    renderWishlistItems(response) {
        this.has('wishlistContainer', wishlistContainer => {
            if (this.has('wishlistItemsTemplate')) {
                this.render('wishlistItemsTemplate', response, wishlistContainer);
            }
        });
    }
}