import Widget from '../Widget';

/**
 * @description Emit event for eventBus implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class EmitBusEvent extends Widget {
    prefs() {
        return {
            busEventType: '',
            ...super.prefs()
        };
    }

    emitBusEvent() {
        if (!this.prefs().busEventType.length) {
            return false;
        }

        this.eventBus().emit(this.prefs().busEventType, this);
    }
}