import Modal from './Modal';
import { getContentByUrl } from 'widgets/toolbox/ajax';

/**
 * @description Base Modalduel implementation
 * @param {typeof import('Modal').default} modal Base widget for extending
 */
export default class ModalDuel extends Modal {
    prefs() {
        return {
            url: '',
            contentType: 'html',
            clickOutSide: true,
            classesModal: 'modal-duel-recommendation',
            intersectEl: 'intersection-mobile',
            itemTotal: 0,
            ...super.prefs()
        };
    }

    showModal() {
        if (!this.prefs().url.length) {
            return false;
        }

        getContentByUrl(this.prefs().url).then(res => {
            this.eventBus().emit('openGlobalModal', {
                content: res,
                'class': this.prefs().classesModal
            });
            this.mobileStickyListener();
        });
    }

    hide() {
        this.ref(this.prefs().refContainer).attr('hidden', true);
    }

    next(event) {
        const total = this.prefs().itemTotal - 1;
        const current = parseInt(event.els[0].getAttribute('data-current'));
        const next = current === total ? 0 : current + 1;
        this.reloadModal(next);
    }

    prev(event) {
        const total = this.prefs().itemTotal - 1;
        const current = parseInt(event.els[0].getAttribute('data-current'));
        const prev = current === 0 ? total : current - 1;
        this.reloadModal(prev);
    }

    reloadModal(item) {
        const element = document.getElementById('duel-item-' + item);
        if (element && element.parentElement) {
            const dataUrl = element.parentElement.getAttribute('data-url');
            getContentByUrl(dataUrl).then(res => {
                this.eventBus().emit('openGlobalModal', {
                    content: res,
                    'class': this.prefs().classesModal
                });
            });

        }
    }

    mobileStickyListener() {
        const container = document.querySelector('.' + this.prefs().classesModal);
        const needle = document.querySelector('.' + this.prefs().intersectEl);
        if (!container || !needle) {
            return;
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                container.classList.toggle('fixed', !entry.isIntersecting);
            });
        }, {
            root: null,
            threshold: [0, 1]
        });
        observer.observe(needle);
    }
}