import AjaxForm from '../forms/AjaxForm';
import { changeSubmitButtonState } from '../toolbox/util';

export default class RegistationForm extends AjaxForm {
    init() {
        this.eventBus().on('registration.validate', 'handleSubmitBtn');
        super.init();
    }
    handleCaptchaSubmit() {
        if (this.ref('recaptcha-field').shown) {
            this.ref('recaptcha-field').ref('field').val(grecaptcha.getResponse(1)); // eslint-disable-line
        }
        this.handleSubmit();
    }

    onSubmitted(data) {
        if (data.rateLimiterExceeded) {
            this.ref('rateLimiterError').els[0].innerHTML = this.config.Resources.msg.ratelimiterregister;
        }
        if (!data.error) {
            this.eventBus().emit('account.registered', data);
        }

        if (data.success && data.redirectUrl) {
            this.eventBus().emit('account.login', data);
        }

        super.onSubmitted(data);
    }
    handleSubmitBtn(enforce) {
        if (enforce) {
            super.handleSubmitBtn();
        } else {
            changeSubmitButtonState(this.ref('submitButton'), false);
        }
    }
}