import customerWishlist from 'widgets/toolbox/wishlist';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import Widget from '../Widget';

/**
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class WishListItemCounter extends Widget {
    prefs() {
        return {
            listUrl: '',
            counterUrl: '',
            ...super.prefs()
        };
    }

    init() {
        super.init();
        this.getWishlist();

        this.eventBus().on('wishlist.added', 'getCount');
        this.eventBus().on('wishlist.removed', 'getCount');
    }

    getWishlist() {
        if (this.prefs().listUrl) {
            getJSONByUrl(this.prefs().listUrl).then((response) => {
                if (response.success && response.list) {
                    customerWishlist.set(response.list);
                    this.updateCount(response.list.length);
                    this.eventBus().emit('wishlist.inited', response.list);
                }
            });
        }
    }

    getCount(data) {
        if (data && data.count) {
            this.updateCount(data.count);
        } else if (this.prefs().counterUrl) {
            getJSONByUrl(this.prefs().counterUrl).then((data) => {
                this.updateCount(data.count);
            });
        }
    }

    updateCount(count = 0) {
        this.ref('counter').setText(count);
        this.ref('self').toggleClass(this.prefs().classesActive, count);
    }

    handleClick(el, event) {
        var headerNav = this.ref('self').attr('data-headernavelement');
        if (headerNav) {
            this.eventBus().emit('navbar.click', 'Header', headerNav);
        } else {
            this.eventBus().emit('navbar.click', 'Footer', this.ref('self').attr('data-footernavelement'));
        }
    }
}