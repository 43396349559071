import BasicInput from 'widgets/forms/BasicInput';

/**
 * @description Implementation
 */
export default class InputPassword extends BasicInput {
    prefs() {
        return {
            upperCaseAmount: 1,
            upperCaseAmountMsg: this.config.Resources.msg.password.upperCaseAmountMsg,
            lowerCaseAmount: 1,
            lowerCaseAmountMsg: this.config.Resources.msg.password.lowerCaseAmountMsg,
            numbersAmount: 1,
            numbersAmountMsg: this.config.Resources.msg.password.numbersAmountMsg,
            specialCharsAmount: 1,
            specialCharsAmountMsg: this.config.Resources.msg.password.specialCharsAmountMsg,
            specialCharsList: '~`!@#$%^&*()_+-=[]{}|;:\'"<>,.',
            minimumLength: 8,
            rangeError: this.config.Resources.msg.password.rangeError,
            ...super.prefs()
        };
    }
    /**
     *
     * @param {string} val
     */
    isNumber(val) {
        return '0123456789'.includes(val);
    }
    /**
     *
     * @param {string} val
     */
    isSimpleChar(val) {
        return !this.isNumber(val) && !this.prefs().specialCharsList.includes(val);
    }
    isValid() {
        const val = this.getValue();

        if (typeof val === 'string' && val.length >= this.prefs().minimumLength) {
            const valChars = val.split('');
            if (this.prefs().upperCaseAmount) {
                const upperCaseLetters = valChars.filter(char =>
                    char === char.toUpperCase() && this.isSimpleChar(char)
                );
                if (upperCaseLetters.length < this.prefs().upperCaseAmount) {
                    this.error = this.prefs().upperCaseAmountMsg;
                    return false;
                }
            }
            if (this.prefs().lowerCaseAmount) {
                const lowerCaseLetters = valChars.filter(char =>
                    char === char.toLowerCase() && this.isSimpleChar(char)
                );
                if (lowerCaseLetters.length < this.prefs().lowerCaseAmount) {
                    this.error = this.prefs().lowerCaseAmountMsg;
                    return false;
                }
            }
            if (this.prefs().numbersAmount) {
                const numberLetters = valChars.filter(char => this.isNumber(char));
                if (numberLetters.length < this.prefs().numbersAmount) {
                    this.error = this.prefs().numbersAmountMsg;
                    return false;
                }
            }
            if (this.prefs().specialCharsAmount) {
                const specialCharsList = this.prefs().specialCharsList;
                const specialCharsLetters = valChars.filter(char => specialCharsList.includes(char));
                if (specialCharsLetters.length < this.prefs().specialCharsAmount) {
                    this.error = this.prefs().specialCharsAmountMsg;
                    return false;
                }
            }
            if (this.widgetToMatch && this.widgetToMatchOpts && this.widgetToMatch.data('getValue') !== val) {
                this.error = this.widgetToMatchOpts.msg;
                return false;
            }
        }
        return super.isValid();
    }
    togglePasswordVisibility() {
        const field = this.ref('field');
        const showBtn = this.ref('showBtn');

        if (field.attr('type') === 'password') {
            field.attr('type', 'text');
            showBtn.setText(this.config.Resources.btn.hide);
        } else {
            field.attr('type', 'password');
            showBtn.setText(this.config.Resources.btn.show);
        }
    }

    /**
    * used on confirmation page (registration form)
    */
    continueButton() {
        if (this.validate()) {
            this.emit('continue');
        }
    }
}