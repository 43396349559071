import EventBus from 'widgets/toolbox/eventBus';


export default class EventBusWrapper {
    /**
     * @param {import('widgets/Widget').default} widgetInstance usually this of widget
     */
    constructor(widgetInstance) {
        this.widgetInstance = widgetInstance;
    }
    /**
     * @param {string} eventName name of event
     * @param {string} methodName name of method to call if/when event happen
     */
    on(eventName, methodName) {
        let fn = (...args) => {
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
        };
        EventBus.addListener(eventName, fn);
        this.widgetInstance.onDestroy(() => {
            if (fn) {
                EventBus.removeListener(eventName, fn);
                fn = void 0;
            }
        });
    }
    /**
     * @param {string} eventName name of event
     * @param {string} methodName name of method to call if/when event happen
     */
    once(eventName, methodName) {
        let fn = (...args) => {
            EventBus.removeListener(eventName, fn);
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
            fn = void 0;
        };
        EventBus.addListener(eventName, fn);
        this.widgetInstance.onDestroy(() => {
            if (fn) {
                EventBus.removeListener(eventName, fn);
                fn = void 0;
            }
        });
    }
    emit(...args) {
        EventBus.emit(...args);
    }
}
