import Widget from '../Widget';

/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class BaseButton extends Widget {
    prefs() {
        return {
            refText: 'self',
            classesError: 'm-error',
            ...super.prefs()
        };
    }
    init() {
        super.init();
        if (!this.id) {
            this.id = String(this.config.id);
        }
        this.shown = true;
        this.active = this.ref('self').hasClass(this.prefs().classesActive);

        if (this.config.hidden) {
            this.hide();
        } else {
            this.show();
        }
        this.disabled = this.ref('self').attr('disabled') === 'disabled';
    }
    handleClick() {
        this.emit('click', this);
    }
    handleTouch() {
        // touchstart event works for disabled buttons unlike click event
        if (!this.disabled) {
            this.handleClick();
        }
    }
    getValue() {
        return this.ref('self').val();
    }
    setValue(val = '') {
        this.setError();
        return this.ref('self').val(val);
    }
    getText() {
        return this.ref(this.prefs().refText).getText();
    }
    setText(val = '') {
        //this.setError();
        return this.ref(this.prefs().refText).setText(val);
    }
    setError(err = '') {
        this.setText(err);
        if (err) {
            this.ref('self').addClass(this.prefs().classesError);
        }
    }

    activate() {
        this.active = true;
        this.ref('self').addClass(this.prefs().classesActive);
    }

    deactivate() {
        this.active = false;
        this.ref('self').removeClass(this.prefs().classesActive);
    }
    disable() {
        this.disabled = true;
        this.ref('self').disable();
    }
    enable() {
        this.disabled = false;
        this.ref('self').enable();
    }
    getName() {
        return this.ref('self').attr('name');
    }
}
