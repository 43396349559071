import viewtype from 'widgets/toolbox/viewtype';
import { clickOutside, timeout } from 'widgets/toolbox/util';
import { withQuickView } from './QuickView';
import SwipeEvent from '../global/swipeEvent';

const QuickViewWithSwipeEvent = withQuickView(SwipeEvent);

/**
 * @description Product tile implementation
 */
export default class ProductTile extends QuickViewWithSwipeEvent {
    prefs() {
        return {
            ...super.prefs(),
            classesAlternative: 'm-alternative',
            classesQuickBuy: 'm-quickbuy',
            isQuickView: this.data('isQuickView'),
            quickViewClick: false
        };
    }

    init() {
        super.init();

        this.impressionSent = false;
        this.alternative = false;
        this.quickbuy = false;
        this.isTouch = viewtype.isTouchDevice();

        if (this.isTouch) {
            this.has('quickBuyButton', quickBuyButton => quickBuyButton.show());
            this.has('addToCartButton', addToCartButton => addToCartButton.show());
        } else {
            this.ev('mouseenter', this.mouseEnter, null);
            this.ev('mouseleave', this.mouseLeave, null);
        }

        this.eventBus().emit('product.tile.init', this);
    }

    onIsInViewport() {
        if (this.impressionSent) {
            return;
        }
        this.impressionSent = true;

        let analytics = this.has('analytics') && this.ref('analytics').data('analytics');
        if (analytics) {
            this.eventBus().emit('product.listing.itemvisible', {
                analytics: analytics,
                widget: this
            }, this);
        }
    }

    toggleAlternativeImage() {
        this[this.alternative ? 'hideAlternativeImage' : 'showAlternativeImage']();
    }

    showAlternativeImage() {
        this.ref('self').addClass(this.prefs().classesAlternative);
        this.alternative = true;
        this.eventBus().emit('ua-event.show-alt-img', this);

        const myEvent = new CustomEvent('productAlternate', {
            detail: {},
            bubbles: true,
            cancelable: true,
            composed: false
        });

        this.ref('self').els[0].querySelector('[data-widget="productVisible"]').dispatchEvent(myEvent);
    }

    hideAlternativeImage() {
        this.alternative = false;
        this.ref('self').removeClass(this.prefs().classesAlternative);
    }

    toggleQuickBuy() {
        this[this.quickbuy ? 'hideQuickBuy' : 'showQuickBuy']();
    }

    showQuickBuy() {
        if (this.has('quickBuy')) {
            if (this.isTouch) {
                this.clearClickOutside();
                this.exposeClickOutside = clickOutside(this.ref('quickBuy'), () => {
                    if (this.quickbuy) {
                        this.hideQuickBuy();
                    }
                    return false;
                }, false);
            }

            this.ref('self').addClass(this.prefs().classesQuickBuy);
            this.quickbuy = true;
        }
    }

    hideQuickBuy() {
        if (this.has('quickBuy')) {
            this.clearClickOutside();
            this.ref('self').removeClass(this.prefs().classesQuickBuy);
            this.quickbuy = false;
        }
    }

    clearClickOutside() {
        if (this.exposeClickOutside) {
            this.exposeClickOutside();
            this.exposeClickOutside = void 0;
        }
    }

    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} el source of event
     * @param {Event} event event instance of DOM event
     */
    mouseEnter(el, event) {
        if (this.preventMouseEnter || // prevent mouseenter after added to Cart
            event.currentTarget !== event.target) {
            return;
        }

        if (this.timerQuickBuy) {
            this.timerQuickBuy();
        }

        this.showQuickBuy();
    }

    /**
     * @param {import('widgets/toolbox/RefElement').RefElement} el source of event
     * @param {Event} event event instance of DOM event
     */
    mouseLeave(el, event) {
        if (event.currentTarget !== event.target) {
            return;
        }

        if (this.preventMouseEnter) {
            this.preventMouseEnter = false;
        }

        this.timerQuickBuy = timeout(() => {
            this.hideQuickBuy();
        }, this.config.sitePreferences.QUICKBUY_TIME_TO_SHOW || 100); // 100 to correct working of quickbuy when site preference is set to 0
    }

    initStateAdded(widget, cart, product) {
        this.has('quickBuyButton', quickBuyButton => {
            quickBuyButton.addClass(this.prefs().classesActive);
            timeout(() => {
                quickBuyButton.removeClass(this.prefs().classesActive);
            }, this.config.sitePreferences.QUICKBUY_TIME_TO_SHOW_ADDED);
        });
        this.hideQuickBuy();
        this.preventMouseEnter = true;

        this.analytics = product && product.analytics;
        this.eventBus().emit('product.tile.added.to.cart', cart, this);
    }

    preventQuickView(el, event) {
        event.stopPropagation(); // go to PDP instead of showing QuickView popup (when click on Color count)
    }

    handleTileClick(el) {
        try {
            var pageEl = window.document.querySelector('#page');
            var cgid = '';
            if (pageEl) {
                cgid = pageEl.dataset.category ? pageEl.dataset.category : '';
            }
            if (cgid) {
                var oReq = new XMLHttpRequest();
                oReq.open('POST', this.config.Constants.URL_STORE_BC, false);
                oReq.send('cgid=' + cgid);
            }
        } catch (e) {
            window.console.log('breadcrumbs dynamic population error ' + e);
        }
        window.history.replaceState({scroll: window.scrollY, stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
        this.eventBus().emit('product.tile.product.click', this, el);
    }
}