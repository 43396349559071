import QuickViewUrlObserver from 'widgets/editorial/QuickViewUrlObserver';
import WidgetsMgr from 'widgets/WidgetsMgr';
import Button from 'widgets/global/Button';
import CookiesAcceptance from 'widgets/global/CookiesAcceptance';
import CookiesNotice from 'widgets/global/CookiesNotice';
import StickyElement from 'widgets/global/StickyElement';
import NavigationBar from 'widgets/global/NavigationBar';
import Navigation from 'widgets/global/Navigation';
import HeaderSearch from 'widgets/global/HeaderSearch';
import Banner from 'widgets/global/Banner';
import Concealable from 'widgets/global/Concealable';
import Form from 'widgets/forms/Form';
import BasicForm from 'widgets/forms/BasicForm';
import AjaxForm from 'widgets/forms/AjaxForm';
import Token from 'widgets/global/Token';
import CrownpeakDebug from 'widgets/util/CrownpeakDebug';
import BasicInput from 'widgets/forms/BasicInput';
import InputEmail from 'widgets/forms/InputEmail';
import InputEmailSignUp from 'widgets/forms/InputEmailSignUp';
import NotifyMeEmail from 'widgets/forms/NotifyMeEmail';
import InputPassword from 'widgets/forms/InputPassword';
import InputPasswordLogin from 'widgets/forms/InputPasswordLogin';
import InputCheckbox from 'widgets/forms/InputCheckbox';
import InputText from 'widgets/forms/InputText';
import InputTextarea from 'widgets/forms/InputTextarea';
import InputNumber from 'widgets/forms/InputNumber';
import InputGiftCertificate from 'widgets/forms/InputGiftCertificate';
import Tabs from 'widgets/global/Tabs';
import TabPanel from 'widgets/global/TabPanel';
import InputSelect from 'widgets/forms/InputSelect';
import SendingDateInputSelect from 'widgets/forms/SendingDateInputSelect';
import Label from 'widgets/global/Label';
import Text from 'widgets/global/Text';
import Minicart from 'widgets/cart/Minicart';
import ConfirmDialog from 'widgets/global/ConfirmDialog';
import Carousel from 'widgets/global/Carousel';
import ImageCarousel from 'widgets/global/ImageCarousel';
import ContentCarousel from 'widgets/global/ContentCarousel';
import SearchCarousel from 'widgets/global/SearchCarousel';
import ImageZoom from 'widgets/global/ImageZoom';
import MenuItem from 'widgets/global/MenuItem';
import BackToTop from 'widgets/global/BackToTop';
import ToggleButton from 'widgets/global/ToggleButton';
import Modal from 'widgets/global/Modal';
import Picture from 'widgets/global/Picture';
import CouponForm from 'widgets/forms/CouponForm';
import InputRadio from 'widgets/forms/InputRadio';
import InputSearchBox from 'widgets/forms/InputSearchBox';
import searchSuggestions from 'widgets/search/searchSuggestions';
import generalSearchSuggestions from 'widgets/search/generalSearchSuggestions';
import SearchToggleButton from 'widgets/search/SearchToggleButton';
import ConsentTracking from 'widgets/global/ConsentTracking';
import Tooltip from 'widgets/global/Tooltip';
import ProductListingMgr from 'widgets/search/ProductListingMgr';
import ModalLink from 'widgets/global/ModalLink';
import ModalDuel from 'widgets/global/ModalDuel';
import EmitBusEvent from 'widgets/global/EmitBusEvent';
import ZmagsAnalytics from 'widgets/global/ZmagsAnalytics';
import PasswordResetForm from 'widgets/account/PasswordResetForm';
import RegistrationForm from 'widgets/account/RegistrationForm';
import PasswordResetModal from 'widgets/account/PasswordResetModal';
import WishListItemCounter from 'widgets/account/WishListItemCounter';
import AccountMgr from 'widgets/account/AccountMgr';
import PaymentMgr from 'widgets/account/PaymentMgr';
import WishlistMgr from 'widgets/account/WishlistMgr';
import AddressesMgr from 'widgets/account/AddressesMgr';
import CountrySelector from 'widgets/global/CountrySelector';
import FilterPrices from 'widgets/search/FilterPrices';
import SwipeEvent from 'widgets/global/swipeEvent';
import ProductTile from 'widgets/product/ProductTile';
import ProductVisible from 'widgets/product/ProductVisible';
import ProductPicture from 'widgets/product/ProductPicture';
import ProductPictureHoverZoom from 'widgets/product/ProductPictureHoverZoom';
import InfiniteScroll from 'widgets/global/InfiniteScroll';
import InfiniteScrollLoadedContent from 'widgets/global/InfiniteScrollLoadedContent';
import QuickBuy from 'widgets/product/QuickBuy';
import QuickView from 'widgets/product/QuickView';
import SearchMgr from 'widgets/search/SearchMgr';
import TemplateRenderer from 'widgets/global/TemplateRenderer';
import AddToWishlistButton from 'widgets/global/AddToWishlistButton';
import submitButton from 'widgets/forms/submitButton';
import Store from 'widgets/storeLocator/Store';
import inputRadioShipping from 'widgets/forms/inputRadioShipping';
import inputSelectCountry from 'widgets/forms/inputSelectCountry';
import inputTextPostal from 'widgets/forms/inputTextPostal';
import firstLastNameForm from 'widgets/forms/firstLastNameForm';
import reCaptchaV3 from 'widgets/reCaptchaV3/reCaptchaV3';
import inputSubscriptionCheckbox from 'widgets/forms/inputSubscriptionCheckbox';
import Home from 'widgets/global/Home';
import CountryBanner from 'widgets/global/CountryBanner';
import Bambuser from 'widgets/bambuser/Bambuser';
import LazyLoad from 'widgets/LazyLoad/LazyLoad';
import WidgetDeferred from './widgetDeferred';
import EmarsysCart from 'widgets/global/EmarsysCart';
import CartMgr from 'widgets/cart/CartMgr';
import emailSubscribe from 'widgets/forms/ajax/EmailSubscribe';
import orderSurvey from 'widgets/forms/ajax/OrderSurvey';
import ReviewShow from 'widgets/product/ReviewShow';
import ReviewToggle from 'widgets/product/ReviewToggle';
import EditorialHomepage from 'widgets/editorial/EditorialHomepage';
import CountrySelectorModal from 'widgets/global/CountrySelectorModal';
import RetailAI from 'widgets/retailAI/retailAI';
import Narvar from 'widgets/tracking/narvar';

const orderTrack = new WidgetDeferred('forms/OrderTrack');
const registerConfirmation = new WidgetDeferred('forms/registerConfirmation');
const checkoutLogin = new WidgetDeferred('checkout/CheckoutLogin');
const signInCheckoutLoginForm = new WidgetDeferred('forms/signInCheckoutLoginForm');
const guestCheckoutForm = new WidgetDeferred('forms/guestCheckoutForm');
const paymentGiftCertificate = new WidgetDeferred('forms/PaymentGiftCertificate');
const clickAndCollect = new WidgetDeferred('checkout/clickAndCollect');
const billingAddress = new WidgetDeferred('checkout/billingAddress');
const confirmationMgr = new WidgetDeferred('checkout/confirmationMgr');
const storeList = new WidgetDeferred('checkout/storeList');
const StoreLocator = new WidgetDeferred('storeLocator/StoreLocator');
const GoogleMapClickCollect = new WidgetDeferred('checkout/GoogleMapClickCollect');
const CheckoutToggleButton = new WidgetDeferred('checkout/CheckoutToggleButton');
const ShippingFrom = new WidgetDeferred('checkout/ShippingForm');
const BillingForm = new WidgetDeferred('checkout/BillingForm');
const PaymentTabs = new WidgetDeferred('checkout/PaymentTabs');
const AdyenPaymentCreditCardComponent = new WidgetDeferred('checkout/AdyenPaymentCreditCardComponent');
const PaymentTabPanelPayPal = new WidgetDeferred('checkout/PaymentTabPanelPayPal');
const PaymentTabPanelApplePay = new WidgetDeferred('checkout/PaymentTabPanelApplePay');
const InputCreditCardNumber = new WidgetDeferred('checkout/InputCreditCardNumber');
const AddressCheckoutForm = new WidgetDeferred('checkout/AddressCheckoutForm');
const marketingPreferences = new WidgetDeferred('emarsys/marketingPreferences');
const GiftCertificateForm = new WidgetDeferred('forms/GiftCertificateForm');
const ProductDetail = new WidgetDeferred('product/ProductDetail');
const GiftCertificateDetail = new WidgetDeferred('product/GiftCertificateDetail');
const NotifyMe = new WidgetDeferred('forms/ajax/NotifyMe');
const ColorSwatch = new WidgetDeferred('product/ColorSwatch');
const ColorSwatchTile = new WidgetDeferred('product/ColorSwatchTile');
const ProductCarousel = new WidgetDeferred('global/ProductCarousel');
const EditorialCarousel = new WidgetDeferred('global/EditorialCarousel');
const StickyCenter = new WidgetDeferred('global/StickyCenter');

const CheckoutMgr = new WidgetDeferred('checkout/CheckoutMgr');
const AccountPaymentForm = new WidgetDeferred('account/AccountPaymentForm');
const OrderHistoryMgr = new WidgetDeferred('account/OrderHistoryMgr');
const Loqate = new WidgetDeferred('global/Loqate');
const GoogleMap = new WidgetDeferred('storeLocator/GoogleMap');
const CartCountryBanner = new WidgetDeferred('global/CartCountryBanner');
const BonusProducts = new WidgetDeferred('cart/BonusProducts');
const BonusProductDetail = new WidgetDeferred('cart/BonusProductDetail');
const AddressForm = new WidgetDeferred('forms/AddressForm');


if (!PRODUCTION) {
    // eslint-disable-next-line no-console
    console.groupCollapsed('Main widgets registration');
    // console.profile('Registration widgets');
}

[
    ['cookiesAcceptance', CookiesAcceptance],
    ['cookiesNotice', CookiesNotice],
    ['zmagsAnalytics', ZmagsAnalytics],
    ['basicInput', BasicInput],
    ['basicForm', BasicForm],
    ['inputEmail', InputEmail],
    ['inputEmailSignUp', InputEmailSignUp],
    ['notifyMe', NotifyMeEmail],
    ['inputPassword', InputPassword],
    ['inputPasswordLogin', InputPasswordLogin],
    ['inputCheckbox', InputCheckbox],
    ['inputSelect', InputSelect],
    ['sendingDateInputSelect', SendingDateInputSelect],
    ['inputText', InputText],
    ['inputTextarea', InputTextarea],
    ['inputRadio', InputRadio],
    ['inputSearchBox', InputSearchBox],
    ['searchSuggestions', searchSuggestions],
    ['generalSearchSuggestions', generalSearchSuggestions],
    ['searchToggleButton', SearchToggleButton],
    ['inputNumber', InputNumber],
    ['token', Token],
    ['label', Label],
    ['text', Text],
    ['tabs', Tabs],
    ['tooltip', Tooltip],
    ['carousel', Carousel],
    ['imageCarousel', ImageCarousel],
    ['productCarousel', ProductCarousel],
    ['contentCarousel', ContentCarousel],
    ['searchCarousel', SearchCarousel],
    ['editorialCarousel', EditorialCarousel],
    ['imageZoom', ImageZoom],
    ['tabPanel', TabPanel],
    ['productDetail', ProductDetail],
    ['colorSwatch', ColorSwatch],
    ['colorSwatchTile', ColorSwatchTile],
    ['button', Button],
    ['menuItem', MenuItem],
    ['wishListItemCounter', WishListItemCounter],
    ['toggleButton', ToggleButton],
    ['swipeEvent', SwipeEvent],
    ['modal', Modal],
    ['picture', Picture],
    ['confirmDialog', ConfirmDialog],
    ['concealable', Concealable],
    ['ajaxform', AjaxForm],
    ['couponform', CouponForm],
    ['giftcertform', GiftCertificateForm],
    ['form', Form],
    ['stickyElement', StickyElement],
    ['stickyCenter', StickyCenter],
    ['navigationBar', NavigationBar],
    ['navigation', Navigation],
    ['headerSearch', HeaderSearch],
    ['banner', Banner],
    ['home', Home],
    ['emailSubscribe', emailSubscribe],
    ['orderSurvey', orderSurvey],
    ['notifyMeSubscribe', NotifyMe],
    ['backToTop', BackToTop],
    ['consentTracking', ConsentTracking],
    ['modalLink', ModalLink],
    ['modalDuel', ModalDuel],
    ['emitBusEvent', EmitBusEvent],
    ['countrySelector', CountrySelector],
    ['productTile', ProductTile],
    ['reviewToggle', ReviewToggle],
    ['reviewShow', ReviewShow],
    ['productVisible', ProductVisible],
    ['productPicture', ProductPicture],
    ['productPictureHoverZoom', ProductPictureHoverZoom],
    ['templateRenderer', TemplateRenderer],
    ['infiniteScroll', InfiniteScroll],
    ['infiniteScrollLoadedContent', InfiniteScrollLoadedContent],
    ['addToWishlistButton', AddToWishlistButton],
    ['loqate', Loqate],
    ['reCaptchaV3', reCaptchaV3],
    ['countryBanner', CountryBanner],
    ['cartCountryBanner', CartCountryBanner],
    ['orderTrack', orderTrack],
    ['submitButton', submitButton],
    ['emarsysCart', EmarsysCart],
    ['countrySelectorModal', CountrySelectorModal],

    // store locator
    ['storeLocator', StoreLocator],
    ['store', Store],
    ['googleMap', GoogleMap],

    // account
    ['accountPaymentForm', AccountPaymentForm],
    ['registrationForm', RegistrationForm],
    ['passwordResetForm', PasswordResetForm],
    ['addressForm', AddressForm],
    ['passwordResetModal', PasswordResetModal],
    ['accountMgr', AccountMgr],
    ['wishlistMgr', WishlistMgr],
    ['paymentMgr', PaymentMgr],
    ['addressesMgr', AddressesMgr],
    ['orderHistoryMgr', OrderHistoryMgr],

    //search
    ['productListingMgr', ProductListingMgr],
    ['filterPrices', FilterPrices],
    ['quickBuy', QuickBuy],
    ['quickView', QuickView],
    ['searchMgr', SearchMgr],
    ['retailAI', RetailAI],

    // cart
    ['minicart', Minicart],
    ['addressCheckoutForm', AddressCheckoutForm],
    ['addressCheckoutForm', Loqate],
    ['shippingForm', ShippingFrom],
    ['billingForm', BillingForm],
    ['inputCreditCardNumber', InputCreditCardNumber],
    ['paymentTabs', PaymentTabs],
    ['paymentTabPanelAdyenComponent', AdyenPaymentCreditCardComponent],
    ['paymentTabPanelPayPal', PaymentTabPanelPayPal],
    ['paymentTabPanelDW_APPLE_PAY', PaymentTabPanelApplePay],
    ['cartMgr', CartMgr],
    ['bonusProducts', BonusProducts],
    ['bonusProductDetail', BonusProductDetail],
    ['giftCertificateDetail', GiftCertificateDetail],

    //checkout Login
    ['signInCheckoutLoginForm', signInCheckoutLoginForm],

    //checkout
    ['checkoutMgr', CheckoutMgr],
    ['checkoutToggleButton', CheckoutToggleButton],
    ['inputRadioShipping', inputRadioShipping],
    ['inputSelectCountry', inputSelectCountry],
    ['inputTextPostal', inputTextPostal],
    ['inputGiftCertificate', InputGiftCertificate],
    ['clickAndCollect', clickAndCollect],
    ['storeList', storeList],
    ['firstLastNameForm', firstLastNameForm],
    ['googleMapClickCollect', GoogleMapClickCollect],
    ['billingAddress', billingAddress],
    ['paymentGiftCertificate', paymentGiftCertificate],
    ['confirmationMgr', confirmationMgr],
    ['registerConfirmation', registerConfirmation],
    ['inputSubscriptionCheckbox', inputSubscriptionCheckbox],
    ['guestCheckoutForm', guestCheckoutForm],
    ['checkoutLogin', checkoutLogin],

    // Emarsys

    ['marketingPreferences', marketingPreferences],

    ['bambuser', Bambuser],

    // narvar
    ['narvar', Narvar],

    // editorial
    ['quickViewUrlObserver', QuickViewUrlObserver],
    ['editorialHomepage', EditorialHomepage],
    ['crownpeakDebug', CrownpeakDebug],

    // Image lazy loading
    ['lazyLoad', LazyLoad]
].forEach(([widgetName, classFn, baseWidget]) => {
    WidgetsMgr.register(widgetName, classFn, baseWidget);
});

if (!PRODUCTION) {
    //  console.profileEnd('Registration widgets');
    // eslint-disable-next-line no-console
    console.groupEnd();
}
export default WidgetsMgr;
