import { getContentByUrl, submitFormJson } from 'widgets/toolbox/ajax';
import { disableScroll, enableScroll } from 'widgets/toolbox/scroll';
import { showPageLoader, hidePageLoader } from 'widgets/toolbox/progress';
import { clickOutside, timeout } from 'widgets/toolbox/util';
import viewtype from 'widgets/toolbox/viewtype';
import checkProductsAvailability from 'widgets/util/checkProductsAvailability';
import Widget from '../Widget';

/**
 * @description Base button implementation
 */
export default class Minicart extends Widget {
    prefs() {
        return {
            delayBeforeClose: 0, // ms
            actionUrl: '',
            confirmDialog: false,
            elPopover: 'popover',
            elPopoverContent: 'popoverContent',
            elPopoverFlyout: 'popoverFlyout',
            elMinicartContent: 'minicartContent',
            classesShow: 'show',
            classesOpened: 'opened-minicart',
            ...super.prefs()
        };
    }

    init() {
        this.needUpdate = true;
        this.viewTypeChange();
        this.eventBus().on('product.detail.added.to.cart', 'showMinicart');
        this.eventBus().on('product.tile.quick.buy', 'showMinicart');
        this.eventBus().on('cart.updated', 'reRenderMinicartWrapper');
        this.eventBus().on('product.updated', 'reRenderMinicartWrapper');
        this.eventBus().on('viewtype.change', 'viewTypeChange');
    }

    viewTypeChange() {
        this.isMobileView = viewtype.isMobileView();
        this.isDesktopView = viewtype.isDesktopView();
    }

    /**
     * @param {object} cart cartModel
     * @param {string} cart.numItems amount of products
     */
    reRenderMinicartWrapper(cart) {
        this.needUpdate = true;
        this.allowedCheckout = cart.valid && cart.valid.allowedCheckout;

        return this.render('template', {
            quantityTotal: cart.numItems
        }, this.ref(this.prefs().elMinicartContent));
    }

    /**
    * @description redirect the user to cart page
    * when basket session has expired
    * and basket has zero items
    */
    redirectToCart() {
        timeout(() => {
            if (+this.ref(this.prefs().elPopoverContent).data('num-items') === 0 && this.prefs().cartUrl) {
                window.location.href = this.prefs().cartUrl;
            }
        });
    }

    reRenderMinicartContent() {
        this.showProgressBar();
        return getContentByUrl(this.prefs().actionUrl).then((response) => {
            this.needUpdate = false;
            this.render(
                void 0,
                {},
                this.ref(this.prefs().elPopoverContent),
                response
            );
            this.redirectToCart();
        }).catch(() => {
            this.hidePopover();
        }).finally(() => {
            this.hideProgressBar();
        });
    }

    /**
     * @param {object} cart cartModel
     * @param {string} cart.numItems amount of products
     */
    productAdded(cart) {
        this.reRenderMinicartWrapper(cart);
    }

    /**
     * @param {object} cart cartModel
     * @param {boolean} showPopover true if need to show popover
     */
    showMinicart(cart, showPopover = true) {
        this.reRenderMinicartWrapper(cart);
        if ((this.isDesktopView || this.isMobileView) && showPopover) {
            this.showPopover();
            if (this.prefs().autoclose) {
                this.hidePopoverDelayed();
            }
        }
    }

    /**
     *
     * @param {InstanceType<ReturnType<import('widgets/forms/InputNumber').default>>} InputNumber
     */
    updateQty(InputNumber) {
        this.showProgressBar();
        submitFormJson(InputNumber.ref('self').data('action'), {
            pid: InputNumber.ref('self').data('pid'),
            uuid: InputNumber.ref('self').data('uuid'),
            quantity: InputNumber.getValue()
        }, 'GET')
            .then((response) => {
                this.reRenderMinicartWrapper(response);
                this.reRenderMinicartContent();
                this.eventBus().emit('minicart.updated', response);
                this.eventBus().emit('minicart.item.updated', response, InputNumber);
            })
            .catch(() => {
                this.hideProgressBar();
            });
    }

    confirmedRemoveProduct() {
        if (this.removeProductLink) {
            this.showProgressBar();
            this.eventBus().emit('minicart.item.remove', this);
            submitFormJson(this.removeProductLink.data('removeAction') || this.removeProductLink.data('action'), {
                pid: this.removeProductLink.data('pid'),
                uuid: this.removeProductLink.data('uuid')
            }, 'GET')
                .then((response) => {
                    this.reRenderMinicartWrapper(response.basket);
                    this.eventBus().emit('minicart.updated', response.basket);
                    this.eventBus().emit('minicart.item.removed', response.basket, this.removeProductLink);
                    this.removeProductLink = void 0;
                    if (!response.basket.numItems) {
                        this.hideProgressBar();
                        this.hidePopover();
                    } else {
                        this.reRenderMinicartContent();
                    }
                })
                .catch(() => {
                    this.removeProductLink = void 0;
                    this.hideProgressBar();
                });
        }
    }

    /**
     * @param {*} link link which is clicked by user
     */
    removeProduct(link) {
        this.removeProductLink = link;
        if (this.prefs().confirmDialog) {
            this.ref('confirmDialog')
                .showDialog({ productName: link.data('name') });
        } else {
            this.confirmedRemoveProduct();
        }
    }

    cleanUpListener() {
        if (this.listener) {
            this.listener();
            this.listener = void 0;
        }
    }

    showPopover() {
        disableScroll();
        this.isPopoverShow = true;
        this.ref('html').addClass(this.prefs().classesOpened);

        this.cleanUpDelayTimer();
        this.cleanUpListener();

        this.listener = clickOutside(this.ref('self'), () => {
            this.hidePopover();
            return false;
        });
        this.onDestroy(this.listener);

        if (this.needUpdate) {
            this.reRenderMinicartContent();
        }
    }

    cleanUpDelayTimer() {
        if (this.delayTimer) {
            this.delayTimer();
            this.delayTimer = void 0;
        }
    }

    hidePopoverDelayed() {
        if (this.prefs().delayBeforeClose) {
            this.cleanUpDelayTimer();

            this.delayTimer = timeout(() => {
                if (this.ref('html').hasClass(this.prefs().classesOpened)) {
                    this.hidePopover();
                }
                this.delayTimer = void 0;
            }, this.prefs().delayBeforeClose);

            this.onDestroy(this.delayTimer);
        } else {
            this.hidePopover();
        }
    }

    hidePopover() {
        this.isPopoverShow = false;

        this.cleanUpDelayTimer();
        this.cleanUpListener();

        this.ref('html').removeClass(this.prefs().classesOpened);
        enableScroll();
    }

    showProgressBar() {
        showPageLoader(this.ref(this.prefs().elPopoverFlyout).get(), 'inside');
    }

    hideProgressBar() {
        hidePageLoader(this.ref(this.prefs().elPopoverFlyout).get(), 'inside');
    }

    /**
     * @param {any} el RefElement
     * @param {Event} event event instance
     */
    handleClick(el, event) {
        if (
            (this.isDesktopView || this.isMobileView) &&
            !this.isPopoverShow &&
            +this.ref(this.prefs().elMinicartContent).data('count') > 0
        ) {
            event.preventDefault();
            this.showPopover();
        }
    }

    /**
     * @param {HTMLFormElement} el
     * @param {Event} event
     */
    checkAvailabilityBeforeCheckout(el, event) {
        this.eventBus().emit('miniCartCheckoutClicked');
        checkProductsAvailability(el, event, this, this.reRenderMinicartContent);
    }
}