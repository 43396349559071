import eventBus from 'widgets/toolbox/eventBus';
import { timeout } from 'widgets/toolbox/util';
import ScreenDetector from 'widgets/toolbox/viewtype';
import {
    report,
    getListNameFromWidget,
    getCartItemByUUID,
    getOrderPayment,
    reportPromoView,
    reportPromoClick,
    getPromoList,
    getPromoObject,
    getGTMData,
    getPaymentNameByID,
    getCheckoutType,
    getDeliveryType,
    getDeliveryMethod,
    getAddToCartType,
    getAnalyticsData,
    transformProductAnalyticsToEcommerce,
    transformProductAnalyticsToItems,
    getImpressionPosition
} from './util';


// TODO list is item_list_name in items

const gtmPostSnippet = function(gtmData) {
    if (window.gtmDelayedEvents) {
        window.gtmDelayedEvents.forEach(event => {
            report(event);
        });
        delete window.gtmDelayedEvents;
    }

    // ------------------------------------------
    // UA-event PDP open on tab element
    // ------------------------------------------
    eventBus.on('ua-event.pdp-open-tab.click', () => {
        const UAEvent = { event: 'PDPAccordionOpen'};
        report(UAEvent);
    });

    // ------------------------------------------
    // UA-event sizechart open
    // ------------------------------------------
    eventBus.on('openGlobalModal', (queryString) => {
        if (queryString && queryString.action === 'Product-SizeChart') {
            report({
                event: 'open_size_guide'
            });
        }
    });

    // ------------------------------------------
    // UA-event add to wishlist
    // ------------------------------------------
    eventBus.on('wishlist.added', ({ pid, productName, addToWishlistType, analytics}) => {
        report({
            event: 'add_to_wishlist',
            _clear: true,
            productId: analytics.variantSku,
            productName: productName,
            addToWishlistType: addToWishlistType || window.Constants.TYPE_PDP,
            items: transformProductAnalyticsToItems([analytics])
        });
    });

    // ------------------------------------------
    // UA-event notify me
    // ------------------------------------------
    eventBus.on('notifyMe.submitButtonState', () => {
        report({
            event: 'notifyMe',
            productId: gtmData.productDetail.id, 
            encryptedEmail: gtmData.visitorType === "guest" ? document.querySelectorAll("#notifyMeInput")[0].value : gtmData.customer.encryptedEmail,
            visitorType: gtmData.visitorType
        });
    });

    // ------------------------------------------
    // UA-event remove from wishlist
    // ------------------------------------------
    eventBus.on('wishlist.removed', ({ pid, productName }) => {
        report({
            event: 'remove_from_wishlist',
            productId: pid,
            productName: productName
        });
    });

    // ------------------------------------------
    // UA- event grid view
    // ------------------------------------------

    eventBus.on('search.view.grid', () => {
        var currentViewType = ScreenDetector.currentDevice;
        if (currentViewType === 'Mobile') {
            report({
                event: 'view_change',
                view_selected: 'Grid'
            });
        }
    });

    // ------------------------------------------
    // UA- event list view
    // ------------------------------------------

    eventBus.on('search.view.list', () => {
        var currentViewType = ScreenDetector.currentDevice;
        if (currentViewType === 'Mobile') {
            report({
                event: 'view_change',
                view_selected: 'List'
            });
        }
    });

    // ------------------------------------------
    // Tile show alternative image
    // ------------------------------------------
    eventBus.on('ua-event.show-alt-img', (widget) => {
        const UAEvent = {
            event: 'PLPAlternativeImgClick',
            eventLabel: widget.config.pid
        };
        report(UAEvent);
    });

    // ------------------------------------------
    // Cart Promo Error
    // ------------------------------------------
    eventBus.on('coupon.error', () => {
        report({event: 'Cart Promo Error'});
    });

    // ------------------------------------------
    // Cart Promo Apply
    // ------------------------------------------
    eventBus.on('coupon.updated', () => {
        report({event: 'Cart Promo Apply'});
    });

    // ------------------------------------------
    // Account Login - When a user successfully logs in
    // ------------------------------------------
    eventBus.on('account.login', (data) => {
        report({
            event: 'login',
            customer: {
                id: data.userId,
                encryptedEmail: data.encryptedEmail
            }
        });
    });

    // ------------------------------------------
    // Account Registered (Account Creation) - When a user successfully creates an account
    // ------------------------------------------
    eventBus.on('account.registered', (data) => {
        report({
            event: 'sign_up',
            customer: {
                id: data.userId,
                encryptedEmail: data.encryptedEmail
            }
        });
    });

    // ------------------------------------------
    // Newsletter Subscribe - When a user has successfully signed up to a newsletter
    // ------------------------------------------
    eventBus.on('signup.submitted', (data) => {
        report({
            event: 'generate_lead',
            encryptedEmail: data.encryptedEmail
        });
    });

    // ------------------------------------------
    // Review submissions - When a user has successfully submits a product review on the PDP
    // ------------------------------------------
    eventBus.on('review.confirmed', () => {
        report({
            event: 'review_submission',
            productId: gtmData.productDetail.id,
            productName: gtmData.productDetail.name
        });
    });

    // ------------------------------------------
    // Carousel Run
    // ------------------------------------------
    eventBus.on('carousel.run', (sliderIndex) => {
        const UAEvent = {
            event: 'Carousel Run',
            eventLabel: sliderIndex + 1
        };
        report(UAEvent);
    });

    // ------------------------------------------
    // Promotion View
    // ------------------------------------------
    eventBus.on('promotion.listing.init', () => {
        const promoList = getPromoList();
        if (promoList.length) {
            reportPromoView(promoList);
        }
    });

    // ------------------------------------------
    // Promotion Click
    // ------------------------------------------
    eventBus.on('promotion.banner.click', (bannerElement) => {
        const promoObject = getPromoObject(bannerElement);
        reportPromoClick(promoObject);
    });

    // ------------------------------------------
    // Product Impression
    // ------------------------------------------

    let impressionDebouncer = null;
    let debouncedImpressions = {};
    let parentWidgets = [];
    eventBus.on('product.listing.itemvisible', ({ analytics, widget }) => {
        const parentWidget = widget.parents.find(x => typeof x.items === 'object' && Array.isArray(x.items));
        let parentWidgetIdx = parentWidgets.indexOf(parentWidget);
        if (parentWidgetIdx === -1) {
            parentWidgets.push(parentWidget);
            parentWidgetIdx = parentWidgets.length - 1;
        }
        if (!debouncedImpressions[parentWidgetIdx]) {
            debouncedImpressions[parentWidgetIdx] = [];
        }

        debouncedImpressions[parentWidgetIdx].push([analytics, widget]);
        window.clearTimeout(impressionDebouncer);

        impressionDebouncer = setTimeout(() => {
            const impressionsToSend = debouncedImpressions;
            debouncedImpressions = {};

            for (let parentWidgetIdx in impressionsToSend) {
                let impressions = impressionsToSend[parentWidgetIdx].map(x => x[0]);
                let widget = impressionsToSend[parentWidgetIdx][0];
                let parentWidget = parentWidgets[parentWidgetIdx];
                let listingTitle = (parentWidget.data('title') || '').trim() || getListNameFromWidget(parentWidget);

                const productsMaxCount = 10;
                const searchAttributes = parentWidget.data('search-attributes');
                for (let i = 0; i < impressions.length; i += productsMaxCount) {
                    const productImpression = {
                        event: 'view_item_list',
                        _clear: true,
                        filtersApplied: searchAttributes ? searchAttributes.filtersApplied : '',
                        sortApplied: searchAttributes ? searchAttributes.sortApplied : '',
                        ecommerce: {
                            currencyCode: 'GBP',
                            impressions: transformProductAnalyticsToEcommerce(impressions.slice(i, i + productsMaxCount))
                        },
                        impressions: transformProductAnalyticsToItems(
                            impressions.slice(i, i + productsMaxCount),
                            (x) => Object.assign({}, x, ({
                                list: listingTitle,
                                position: getImpressionPosition(widget)
                            })))
                    };
                    report(productImpression);

                    if (productImpression.filtersApplied) {
                        eventBus.emit('spa.page.meta.data', {gtmData: getGTMData(), filtersApplied: productImpression.filtersApplied});
                    }
                }
            }
        }, 100);
    });

    // ------------------------------------------
    // Product Click
    // ------------------------------------------

    eventBus.on('product.tile.product.click', (widget, link) => {
        var analyticsData = getAnalyticsData(widget);
        var productList = getListNameFromWidget(widget);
        var dataCarouselValue = widget.config.carouselSource;
        var itemListName = dataCarouselValue ? `${productList} - ${dataCarouselValue}` : '';

        if (analyticsData && analyticsData.length) {
            report({
                event: 'select_item',
                _clear: true,
                ecommerce: {
                    click: {
                        actionField: {
                            list: productList
                        },
                        products: transformProductAnalyticsToEcommerce(analyticsData, (x) => Object.assign({}, x, {
                            position: getImpressionPosition(widget)
                        }), itemListName)
                    }
                },
                items: transformProductAnalyticsToItems(analyticsData, (x) => Object.assign({}, x, {
                    position: getImpressionPosition(widget)
                }), itemListName)
            });
        }
        if (productList && link) {
            link.attr('href', link.attr('href') + '#list=' + productList);
        }
    });


    eventBus.on('product.tile.quickview.open', (widget, modal, analytics) => {
        const productList = getListNameFromWidget(widget);

        report({
            event: 'view_item',
            _clear: true,
            productViewType: 'Quick View',
            ecommerce: {
                currencyCode: 'GBP',
                detail: {
                    products: transformProductAnalyticsToEcommerce([analytics], (x) => Object.assign({}, x, {
                        displayType: 'Quick View'
                    }))
                }
            },
            items: transformProductAnalyticsToItems([analytics])
        });

        if (productList) {
            gtmData.quickviewList = productList;
            timeout(() => {
                modal.has('productQuickView', quickView => {
                    quickView.has('productDetailsLink', link => {
                        link.on('click', () => {
                            link.attr('href', link.attr('href') + '#list=' + productList);
                        });
                    });
                });
            });
        }
    });

    // ------------------------------------------
    // Product View
    // ------------------------------------------

    if (gtmData.productDetail) {
        report({
            event: 'view_item',
            _clear: true,
            productViewType: gtmData.productDetail.displayType,
            ecommerce: {
                currencyCode: 'GBP',
                detail: {
                    products: transformProductAnalyticsToEcommerce([gtmData.productDetail])
                }
            },
            items: transformProductAnalyticsToItems([gtmData.productDetail])
        });
    }

    eventBus.on('product.history.updated', (product) => {
        if (!gtmData.productDetail) {
            return;
        }
        report({
            event: 'view_item',
            _clear: true,
            ecommerce: {
                currencyCode: 'GBP',
                detail: {
                    products: transformProductAnalyticsToEcommerce([product.analytics])
                }
            },
            items: transformProductAnalyticsToItems([product.analytics])
        });
    });

    eventBus.on('product.quickview.updated', (product) => {
        report({
            event: 'view_item',
            _clear: true,
            productViewType: 'Quick View',
            ecommerce: {
                currencyCode: 'GBP',
                detail: {
                    products: transformProductAnalyticsToEcommerce([product.analytics], (x) => ({
                        displayType: 'Quick View'
                    }))
                }
            },
            items: transformProductAnalyticsToItems([product.analytics])
        });
    });

    // ------------------------------------------
    // Add To Cart / Remove From Cart
    // ------------------------------------------

    const handleAddToCart = (cart, widget, quantity, addToCartType) => {
        var analyticsData = getAnalyticsData(widget);
        var dataCarouselValue = widget.config.carouselSource;

        var productList = getListNameFromWidget(widget, gtmData.quickviewList);
        var itemListName = dataCarouselValue ? `${productList} - ${dataCarouselValue}` : '';
        var add_to_cart_type;
        switch (productList) {
            case 'Wishlist':
                add_to_cart_type = 'Wishlist';
                break;
            case 'Search results':
                add_to_cart_type = 'Search';
                break;
            case 'PLP':
                add_to_cart_type = 'PLP';
                break;
            case 'PDP':
                add_to_cart_type = 'PDP';
                break;
            case 'Minicart':
                add_to_cart_type = 'Minicart';
                break;
            case 'Complete the outfit':
                add_to_cart_type = 'PDP Cross-sell';
                break;
            case 'Cart':
                add_to_cart_type = 'Cart';
                break;
            default:
                add_to_cart_type = addToCartType;
                break;
        }
        if (analyticsData && analyticsData.length) {
            var data = {
                event: 'add_to_cart',
                _clear: true,
                ecommerce: {
                    currencyCode: 'GBP',
                    add: {
                        actionField: {
                            list: productList
                        },
                        products: transformProductAnalyticsToEcommerce(analyticsData, (x) => Object.assign({}, x, {
                            quantity: x.quantity ? x.quantity : 1,
                            addToCartType: getAddToCartType(productList || add_to_cart_type, x)
                        }))
                    }
                },
                items: transformProductAnalyticsToItems(analyticsData, (x) => Object.assign({}, x, {
                    quantity: x.quantity ? x.quantity : 1,
                }), itemListName),
                add_to_cart_type: add_to_cart_type
            };
            if (!productList) {
                delete data.ecommerce.add.actionField;
            }
            report(data);
        }
    };

    const handleRemoveToCart = (cart, widget, quantity) => {
        const analyticsData = getAnalyticsData(widget);
        if (analyticsData && analyticsData.length) {
            report({
                event: 'remove_from_cart',
                _clear: true,
                ecommerce: {
                    currencyCode: 'GBP',
                    remove: {
                        products: transformProductAnalyticsToEcommerce(analyticsData, (x) => Object.assign({}, x, {
                            quantity: quantity ? quantity : 1
                        }))
                    }
                },
                items: transformProductAnalyticsToItems(analyticsData, (x) => Object.assign({}, x, {
                    quantity: quantity ? quantity : 1
                }))
            });
        }
    };

    const handleQuantityChange = (cart, widget) => {
        const lineItem = getCartItemByUUID(cart, widget.data('uuid'));
        const qtyDiff = (lineItem && lineItem.quantity || 0) - (widget.data('quantity') || 0);

        widget.analytics = lineItem && lineItem.analytics;

        if (qtyDiff > 0) {
            handleAddToCart(cart, widget, qtyDiff);
        } else if (qtyDiff < 0) {
            handleRemoveToCart(cart, widget, -qtyDiff);
        }
    };

    eventBus.on('product.detail.added.to.cart', (cart, widget, quantity) => handleAddToCart(cart, widget, quantity, 'PDP'));
    eventBus.on('product.tile.added.to.cart', (cart, widget, quantity) => handleAddToCart(cart, widget, quantity, 'PLP'));

    eventBus.on('bonus.product.added.to.cart', handleAddToCart);

    eventBus.on('cart.item.added', handleAddToCart);
    eventBus.on('cart.item.removed', handleQuantityChange);
    eventBus.on('cart.item.updated', handleQuantityChange);

    eventBus.on('minicart.item.removed', handleQuantityChange);
    eventBus.on('minicart.item.updated', handleQuantityChange);

    // ------------------------------------------
    // Checkout
    // ------------------------------------------

    const handleCheckout = (stepData, info = {}) => {
        if (stepData.order && stepData.order.analytics) {
            var data = {
                event: 'checkout',
                _clear: true,
                checkoutType: getCheckoutType(stepData.order.customerNo),
                ecommerce: {
                    currencyCode: 'GBP',
                    checkout: {
                        actionField: {
                            step: stepData.step
                        },
                        products: transformProductAnalyticsToEcommerce(stepData.order.analytics.products)
                    }
                }
            };
            if (stepData.step === 1) {
                delete data.checkoutType;
            }

            if (info.isCheckoutBegin) {
                data.event = 'begin_checkout';
                data.items = transformProductAnalyticsToItems(stepData.order.analytics.products);
            }

            if (stepData.step === 3) {
                data.deliveryType = getDeliveryType(stepData.order.shipping);
                data.deliveryMethod = getDeliveryMethod(stepData.order.shipping);
            }
            report(data);
        }
    };

    const handlePurchase = (stepData) => {
        if (stepData.order && stepData.order.analytics) {
            var paymentMethod = getOrderPayment(stepData.order);
            eventBus.emit('checkout.option.payment.type', paymentMethod, gtmData.checkoutGlobalE || stepData);

            let products = stepData.order.analytics.products;

            report({
                event: 'purchase',
                _clear: true,
                checkoutType: getCheckoutType(stepData.order.customerNo),
                deliveryType: stepData.order.deliveryType || getDeliveryType(stepData.order.shipping),
                deliveryMethod: stepData.order.deliveryMethod || getDeliveryMethod(stepData.order.shipping),
                paymentMethod: paymentMethod,
                ecommerce: {
                    purchase: {
                        actionField: stepData.order.analytics.purchase,
                        products: transformProductAnalyticsToEcommerce(products)
                    },
                    currencyCode: 'GBP'
                },
                transaction_id: stepData.order.analytics.purchase.id,
                value: stepData.order.analytics.purchase.revenue,
                coupon: stepData.order.analytics.purchase.coupon,
                shipping: stepData.order.analytics.purchase.shipping,
                tax: stepData.order.analytics.purchase.tax,
                items: transformProductAnalyticsToItems(products)
            });
        }
    };

    if (gtmData.checkoutLogin) {
        handleCheckout({
            ...gtmData.checkoutLogin,
            step: 1
        });
    }

    eventBus.on('navbar.click', (navigationmethod, navigationelement) => {
        report({
            event: 'navigation_click',
            navigation_method: navigationmethod,
            navigation_element: navigationelement
        });
    });
    eventBus.on('checkout.option.checkout.type', (isLogin) => {
        report({
            event: 'checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 1,
                        option: getCheckoutType(isLogin)
                    }
                }
            }
        });
    });

    eventBus.on('checkout.option.delivery.type', (stepData) => {
        report({
            event: 'add_shipping_info',
            _clear: true,
            ecommerce: {
                currencyCode: 'GBP',
                checkout_option: {
                    actionField: {
                        step: 2,
                        option: getDeliveryType(stepData.order.shipping)
                    }
                }
            },
            items: transformProductAnalyticsToItems((stepData.order.analytics && stepData.order.analytics.products) || stepData.order.products),
            shipping_tier: getDeliveryType(stepData.order.shipping)
        });
    });

    eventBus.on('checkout.option.payment.type', (methodId, stepData) => {
        report({
            event: 'add_payment_info',
            _clear: true,
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 3,
                        option: getPaymentNameByID(methodId)
                    }
                }
            },
            items: transformProductAnalyticsToItems(stepData.order.analytics.products),
            payment_type: getPaymentNameByID(methodId)
        });
    });

    eventBus.on('checkout.inited', (stepData) => {
        eventBus.emit('checkout.option.checkout.type', stepData.order.customerNo);
        handleCheckout({
            ...stepData,
            step: 2
        }, { isCheckoutBegin: true });
    });

    eventBus.on('checkout.billing.inited', (stepData, isInit) => {
        if (!isInit) {
            eventBus.emit('spa.page.meta.data', { gtmData: getGTMData()});
        }
        handleCheckout({
            ...stepData,
            step: 3
        });
    });

    eventBus.on('confirmation.inited', (stepData) => {
        handlePurchase(stepData);
    });

    eventBus.on('continueShopping', () => {
        report({
            event: 'continue_shopping'
        });
    });

    eventBus.on('miniCartCheckoutClicked', () => {
        report({
            event: 'minicart_checkout'
        });
    });

    window.onGlobaleReady = window.onGlobaleReady || [];
    window.onGlobaleReady.unshift(function() {
        const fireOnlyOnce = ((fn) => {
            let isFired = false;
            return (data) => {
                if (!isFired) {
                    fn(data);
                    isFired = true;
                }
            };
        })();

        const getShippingMethod = () => {
            const shippingAnalyticsEl = document.querySelector('#gle_shipping');

            if (!shippingAnalyticsEl || (shippingAnalyticsEl && !shippingAnalyticsEl.hasAttribute('data-shipping-analytics-map'))) {
                return;
            }

            return shippingAnalyticsEl;
        };

        const getAnalyticsShippingMethod = (geShippingMethodID) => {
            const shippingAnalyticsEl = getShippingMethod();

            if (!shippingAnalyticsEl) {
                return geShippingMethodID;
            }

            let shippingMethodsMap;
            let shippingMethod;

            try {
                shippingMethodsMap = JSON.parse(decodeURIComponent(shippingAnalyticsEl.getAttribute('data-shipping-analytics-map')));
                shippingMethod = shippingMethodsMap.find((record) => {
                    let methodIDs = record.analyticsID.split(',');

                    let method = methodIDs.find((method) => {
                        return geShippingMethodID.indexOf(method) > -1;
                    });

                    return method;
                });

                shippingAnalyticsEl.setAttribute('data-shipping-analytics-selected', shippingMethod.identifier);
            } catch (error) {
                shippingMethod = geShippingMethodID;
            }

            return shippingMethod.identifier || shippingMethod;
        };

        const getDefaultShippingMethod = () => {
            let defaultShippingMethod;
            const allAvailableShippingMethod = document.querySelectorAll('.so-service-name');
            let shippingMethodID;

            if (allAvailableShippingMethod.length > 1) {
                shippingMethodID = allAvailableShippingMethod[0].firstChild && allAvailableShippingMethod[0].firstChild.id;
            }

            if (shippingMethodID) {
                const shippingMethodIDSplitted = shippingMethodID.split('_')[1];
                defaultShippingMethod = getAnalyticsShippingMethod(shippingMethodIDSplitted);
            }

            return defaultShippingMethod;
        };

        const findSfccProduct = (globaleProduct, orderAnalytics) => {
            return (orderAnalytics
                && orderAnalytics.products
                && orderAnalytics.products.find(p => globaleProduct.sku === p.variantSku)) || {};
        };

        const getAttribute = (product, attributeKey) => {
            if (!product.MetaData.Attributes || product.MetaData.Attributes.length === 0) {
                return;
            }

            const attribute = product.MetaData.Attributes.find(attr => {
                if (attr.AttributeKey.toLowerCase() === attributeKey.toLowerCase()) {
                    return attr;
                }
            });

            return attribute && attribute.AttributeValue;
        };

        const getProductLines = (globaleData) => {
            return globaleData.products.map(gp => {
                const analyticsData = (window.gtmData && window.gtmData.checkoutGlobalE && window.gtmData.checkoutGlobalE.order
                    && window.gtmData.checkoutGlobalE.order.analytics) || { products: [] };
                const sfccProduct = findSfccProduct(gp, analyticsData);

                return {
                    name: gp.name,
                    position: gp.cartItemId,
                    brand: gp.Brand || 'Hush',
                    currency: 'GBP',
                    price: gp.customerListPriceInMerchantCurrency,
                    netPrice: gp.customerListPriceInMerchantCurrency * (1 - (gp.vatRate / 100)),
                    discount: gp.customerListPriceInMerchantCurrency - gp.customerPriceInMerchantCurrency,
                    discountedPrice: gp.customerPriceInMerchantCurrency,
                    discountedNetPrice: gp.customerPriceInMerchantCurrency * (1 - (gp.vatRate / 100)),
                    quantity: gp.quantity,
                    category: sfccProduct.category || (typeof gp.categories === 'string' ? gp.categories.join(' / ') : (gp.categories || []).map(c => c.name).join(' / ')),
                    color: getAttribute(gp, 'color'),
                    size: getAttribute(gp, 'size'),
                    length: getAttribute(gp, 'length'),
                    variantSku: sfccProduct.variantSku,
                    variationGroupSku: sfccProduct.variationGroupSku,
                    inventory: sfccProduct.inventory,
                    id: sfccProduct.id,
                    list: 'Order-Summary',
                    coupon: sfccProduct.coupon
                };
            });
        };

        const handleGlobaleCheckoutOpened = (globaleData) => {
            handleCheckout({
                step: 2,
                order: {
                    customerNo: (globaleData.CustomerInfo && globaleData.CustomerInfo.MerchantUserId),
                    products: getProductLines(globaleData)
                }
            }, { isCheckoutBegin: true });
        };

        const handleGlobaleCheckoutShippingMethod = (globaleData, shippingMethod) => {
            const payload = {
                step: 3,
                order: {
                    customerNo: (globaleData.CustomerInfo && globaleData.CustomerInfo.MerchantUserId),
                    products: getProductLines(globaleData),
                    shipping: shippingMethod
                }
            };

            eventBus.emit('checkout.option.delivery.type', payload);
            handleCheckout(payload, payload);
        };

        const handleGlobalePurchase = (globaleData) => {
            const analyticsData = (window.gtmData && window.gtmData.checkoutGlobalE && window.gtmData.checkoutGlobalE.order
                && window.gtmData.checkoutGlobalE.order.analytics) || { purchase: {} };

            const payload = {
                order: {
                    customerNo: globaleData.CustomerInfo && globaleData.CustomerInfo.MerchantUserId,
                    deliveryType: getDeliveryType(gtmData.checkoutGlobalE.order.isClickAndCollect, true),
                    deliveryMethod: getDeliveryMethod(gtmData.checkoutGlobalE.order.shipping, true),
                    analytics: {
                        id: globaleData.MerchantOrderId,
                        products: getProductLines(globaleData),
                        purchase: {
                            revenue: globaleData.customerTotalPriceInMerchantCurrency,
                            shipping: globaleData.customerShippingPriceInMerchantCurrency || globaleData.customerDiscountedShippingPriceInMerchantCurrency,
                            tax: globaleData.taxInMerchantCurrency,
                            coupon: analyticsData.purchase.coupon
                        }
                    }
                }
            };

            switch (globaleData.paymentMethodName) {
                case 'Visa':
                case 'Mastercard':
                case 'American Express':
                    payload.order.analytics.paymentMethod = 'Credit Card';
                    break;
                case 'Paypal':
                    payload.order.analytics.paymentMethod = 'PayPal';
                    break;
                default:
                    orderAnalytics.paymentMethod = data.details.paymentMethodName;
                    break;
            }

            handlePurchase(payload);
        };

        let globaleData = null;
        let shippingMethodTracked = false;
        window.gle('OnCheckoutStepLoaded', (data) => {
            globaleData = data.details;
            switch (data.StepId) {
                case data.Steps.LOADED:
                    fireOnlyOnce(handleGlobaleCheckoutOpened)(data.details);
                    break;

                case data.Steps.CONFIRMATION:
                    if (!shippingMethodTracked) {
                        handleGlobaleCheckoutShippingMethod(data.details, gtmData.checkoutGlobalE.order.shipping);
                        shippingMethodTracked = false;
                    }

                    eventBus.emit('spa.page.meta.data', { gtmData: getGTMData() });
                    if (data.IsSuccess) {
                        handleGlobalePurchase(data.details);
                    }
                    break;

                default:
                    break;
            }
        });

        window.gle('OnClientEvent', (source, data) => {
            if (source === 'ShipSelection') {
                const shippingMethod = getAnalyticsShippingMethod(data.id);
                if (window.gtmData && window.gtmData.checkoutGlobalE && window.gtmData.checkoutGlobalE.order) {
                    gtmData.checkoutGlobalE.order.shipping = shippingMethod || data.id;
                }
                if (globaleData) {
                    handleGlobaleCheckoutShippingMethod(globaleData, shippingMethod || data.id);
                }
                shippingMethodTracked = true;
            }

            if (source === 'BillingAddressCompleted' && !shippingMethodTracked) {
                gtmData.checkoutGlobalE.order.shipping = getDefaultShippingMethod();
                shippingMethodTracked = true;
            }

            if (source === 'ShippingAddressCompleted' && data.ShippingAddressType === 'CollectionPoint') {
                gtmData.checkoutGlobalE.order.isClickAndCollect = true;
            }
        });
    });
};

export default gtmPostSnippet;