let scriptLoaded = false;
var observer = new IntersectionObserver(function(entries, observer) {
  entries.forEach(function(entry) {
    if (entry.isIntersecting && !scriptLoaded) {
      loadScriptAndInit();
      observer.unobserve(entry.target);
    }
  });
});

document.addEventListener('DOMContentLoaded', function() {
  var duelPlaceholder = document.getElementById('duelvision-component');
  if (duelPlaceholder) {
    observer.observe(duelPlaceholder);
  }  
});

function loadScriptAndInit() {
    var script = document.createElement('script');
    script.src = 'https://vision.duel.me/loader.js';

    script.onload = function() {
      initialise();
    };

    document.body.appendChild(script);
}

function initialise() {
  if (!scriptLoaded) {
    window.DuelVision = window.DuelVision || function (o) {
      (DuelVision.s = DuelVision.s || []).push(o)
    };
    DuelVision({
      bgColor: '#ffffff',
      color: '#222222',
      columns: 5,
      id: '6462526b902024e7d44dc55c',
      layoutStyle: 'carousel',
    });
    scriptLoaded = true;
  }
}
