import BasicInput from "./BasicInput";

/**
 * @description Implementation
 */
export default class InputTextarea extends BasicInput {
    prefs() {
        return {
            patternMismatch: this.ref('field').attr('data-pattern-mismatch') ||
                this.config.Resources.msg.stirng.patternMismatch,
            rangeError: this.ref('field').attr('data-range-error') ||
                this.config.Resources.msg.stirng.rangeError,
            missingError: this.ref('field').attr('data-missing-error') ||
                this.config.Resources.msg.missingError,
            pattern: this.ref('field').attr('pattern') ||
                this.config.Resources.pattern.textarea,
            minLength: this.ref('field').attr('min') || 1,
            maxLength: this.ref('field').attr('min') || 256,
            ...super.prefs()
        };
    }
    init () {
        super.init();

        this.charactersLeftDefault = null;

        this.handleKeyUp(null, null, true);
    }
    isValid() {
        let msg = '';

        if (!this.ref('self').hasAttr('hidden')) {
            const field = this.ref('field');
            const value = field.val();

            if (field.hasAttr('required') && value.length === 0) {
                msg = this.prefs().missingError;
            } else if (value.length > 0) {
                const pattern = new RegExp(this.prefs().pattern, 'g');
                if ((value.length < this.prefs().minLength) || (value.length > this.prefs().maxLength)) {
                    msg = this.prefs().rangeError;
                } else if (!pattern.test(value)) {
                    msg = this.prefs().patternMismatch;
                }
            }

            this.error = msg;
        }

        return !msg.length;
    }
    handleKeyUp (el, ev, silently = false) {
        var newValue = (this.getValue() + '').replace(/\r?\n/g, '\r\n'),
            charactersLeft = this.charactersLeftDefault;

        if (this.prefs().maxLength && newValue.length) {
            charactersLeft = this.prefs().maxLength - newValue.length;
        } else {
            charactersLeft = this.prefs().maxLength;
        }

        if (charactersLeft <= 0) {
            newValue = newValue.slice(0, this.prefs().maxLength);
            this.setValue(newValue, silently);
        }

        charactersLeft = charactersLeft < 0 ? 0 : charactersLeft;
        this.has('counterLeft', counterLeft => counterLeft.setText(charactersLeft));

        if (!silently) {
            this.emit('keyup', this);
        }
    }
    blur () {
        super.blur();
        this.emit('blur', this);
    }
    focus () {
        super.focus();
        this.emit('focus', this);
    }
}