import eventBus from 'widgets/toolbox/eventBus';

function pushEvent(data) {
    if (typeof __s2tQ !== 'undefined') {
        // eslint-disable-next-line no-undef
        __s2tQ.push(data);
    }
}

[
    'minicart.updated',
    'product.detail.added.to.cart',
    'cart.updated',
    'cart.item.remove',
    'cart.lineitem.remove',
    'bonus.product.added.to.cart'
].map(event => eventBus.on(event, cart => {
    if (cart && cart.sub2Info) {
        pushEvent(['sendBasketJS', cart.sub2Info]);
    }
}));

eventBus.on('email.submitted', form => pushEvent(['storeData', {
    Email: form.emailId,
    OptIn: 1 // Subscribe action - 1, unsubscription - 0
}]));

var accountData = document.getElementById('sub2AccountData');
if (accountData) {
    try {
        pushEvent(['storeData', JSON.parse(accountData.getAttribute('data-account'))]);
    } catch (e) {
        // doit
    }
}

eventBus.on('order.placed', order => {
    if (order && order.sub2Info) {
        var { addOrder, addItem } = order.sub2Info;

        if (addOrder) {
            pushEvent(['addOrder', addOrder]);
        }

        if (addItem) {
            addItem.map(item => pushEvent(['addItem', item]));
        }
    }
});
