class WidgetDeferred {

    constructor(path) {
        this.path = path;
    }

    load() {
        if (!this.promise) {
            this.promise = import(/* webpackChunkName: "[request]" */ `./${this.path}.js`)
                .then(m => m.default)
                .catch(err => {
                    console.error(`Widget load for ${this.path} has failed`, err);
                    throw err;
                });
        }
        return this.promise;
    }
}
export default WidgetDeferred;