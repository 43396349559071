import InputText from 'widgets/forms/InputText';

/**
 * @description Implementation
 */
export default class InputGiftCertificate extends InputText {
    keyup(el, e) {
        if (e.keyCode === 13) {
            this.emit('keyup', this);
        }
    }
}