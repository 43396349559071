import Widget from '../Widget';
import { getCookie } from 'widgets/toolbox/cookie';
import { getContentByUrl } from 'widgets/toolbox/ajax';

export default class RetailAI extends Widget {
    init() {
        super.init();

        this.getRetailProducts();

        this.productsToRemove = [];

        this.eventBus().on('product.listing.updated', 'getRetailProducts');
    }

    async getRetailProducts(_, disableReload) {
        if (disableReload || !this.config.usingRetail) {
            return;
        }

        const {
            RETAIL_AI_API_KEY,
            RETAIL_AI_ACCESS_TOKEN,
            RETAIL_AI_PROJECT_NUMBER
        } = this.config.sitePreferences;

        this.productsToRemove = [];

        const servingConfig = this.data('servingConfig');
        const filterEl = this.get('self').querySelector('input[name="retailAIFilter"');
        const filter = filterEl && filterEl.value;
        if (filter === 'null') {
            return this.hidePlaceholders();
        }

        const url = 'https://retail.googleapis.com/v2/projects/' +
        RETAIL_AI_PROJECT_NUMBER +
        '/locations/global/catalogs/default_catalog/placements/' +
        servingConfig +
        ':predict?key=' +
        RETAIL_AI_API_KEY;

        const clientID = getCookie('sofia_cid') || '';

        if (!clientID) {
            return this.hidePlaceholders();
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + RETAIL_AI_ACCESS_TOKEN
            },
            body: JSON.stringify({
                userEvent: {
                    eventType: 'category-page-view',
                    pageCategories: [this.data('category')],
                    visitorId: clientID
                },
                useMostRecentServingConfig: true,
                params: {
                    returnProduct: false,
                    filterSyntaxV2: true,
                    strictFiltering: true
                },
                filter: filter
            })
        });

        if (!response.ok) {
            return this.hidePlaceholders();
        }

        const products = await response.json();

        if (!products) {
            return this.hidePlaceholders();
        }

        this.displayProducts(products.results);
    }

    hidePlaceholders() {
        const placeholders = Array.from(this.get('self').querySelectorAll('[data-ref^="retail-placeholder_"]'));
        for (const placeholder in placeholders) {
            this.hideTile(placeholders[placeholder]);
        }
    }

    hideTile(product) {
        const grid = document.querySelector('[data-ref=productGrid]');
        const tile = product.parentNode;
        if (grid && tile.parentNode === grid) {
            grid.removeChild(tile);
        }
    }

    async displayProducts(products) {
        const placeholders = Array.from(this.get('self').querySelectorAll('[data-ref^="retail-placeholder_"]'));
        for (const placeholder in placeholders) {
            if (products[placeholder]) {
                const productTile = await this.getProductTile(products[placeholder].id);
                if (!productTile) {
                    return this.hideTile(placeholder);
                }
                this.eventBus().emit('retail.product.loaded', products[placeholder].id);
                this.removeProductIfAlreadyExists(products[placeholder].id);
                placeholders[placeholder].replaceChildren(productTile);
            } else {
                this.hideTile(placeholders[placeholder]);
            }
        }
        this.eventBus().emit('remove.initial.products', this.productsToRemove.length);
    }

    async getProductTile(product) {
        const tileUrl = this.data('tileUrl');
        const response = await getContentByUrl(tileUrl, {
            pid: product,
            hidePLPPromo: false,
            swatches: true,
            pview: 'tile',
            iview: 'tile',
            hasaltimage: true,
            showbadge: true,
            showwishlist: true,
            quickbuy: true,
            isplp: true,
            placement: 'plp'
        });
        const productData = new DOMParser().parseFromString(response, 'text/html');
        const productMarkup = productData.querySelector('[data-pid="' + product + '"]');
        return productMarkup;
    }

    removeProductIfAlreadyExists(product) {
        const existingProduct = window.document.querySelector('[data-pid="' + product + '"]');
        if (existingProduct) {
            this.hideTile(existingProduct);

            if (this.productsToRemove.indexOf(product) === -1) {
                this.productsToRemove.push(product);
            }
        }
    }
}