import Widget from '../Widget';

/**
 * @description Base dropdown implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class TemplateRenderer extends Widget {
    prefs() {
        return {
            ...super.prefs()
        };
    }
    init() {
        this.onRefresh();
    }
    onRefresh() {
        this.emit('init', this.ref('self').get().innerHTML);
        if (this.prefs().triggerInit) {
            this.eventBus().emit(this.prefs().triggerInit, this.ref('self').get().innerHTML);
        }
    }
}