import { showErrorLayout, appendParamsToUrl } from './util';

let tokenName = '';
let tokenValue = '';

/**
 * @param {string} name tokenName
 * @param {string} val tokenValue
 */
export function setCurrentToken(name, val) {
    tokenName = name;
    tokenValue = val;
}

function getFetch() {
    if (window.fetch) {
        return Promise.resolve(window.fetch);
    }
    return import(/* webpackChunkName: 'fetch' */ 'whatwg-fetch');// use polyfill
}

/**
 * @param {string} url url of resource
 * @param {{[x: string]: string}} [data] form content
 * @param {'POST'|'GET'} [method] typeof request
 * @param {boolean} [skipToken] skip token for request
 */
export const submitFormJson = (url, data = {}, method = 'POST', skipToken = false) => {
    return getFetch().then(() => {
        /**
          * @type {URLSearchParams|undefined}
          */
        let formData;

        /**
         * @type {string}undefined
         */
        var valuedUrl;
        if (method === 'POST') {
            formData = new URLSearchParams();

            Object.keys(data).forEach(key => {
                const val = data[key];
                if (formData && typeof val !== 'undefined') {
                    formData.append(key, val);
                }
            });
            if (tokenName && !skipToken) {
                formData.append(tokenName, tokenValue);
            }
            valuedUrl = url;
        } else if (skipToken) {
            valuedUrl = appendParamsToUrl(url, data);
        } else {
            valuedUrl = appendParamsToUrl(url, { ...data, ...{ [tokenName]: tokenValue } });
        }

        /**
         * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
         */
        return Promise.resolve(fetch(valuedUrl, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: formData ? formData.toString() : void 0 // body data type must match "Content-Type" header
        })).then((response) => {
            var contentType = response.headers.get('content-type');

            if (response.ok) {
                if (contentType && contentType.includes('application/json')) {
                    return response.json();
                }
                showErrorLayout('Oops, we haven\'t got JSON!');
                throw new TypeError('Oops, we haven\'t got JSON!');
            } else if (response.status === 500) {
                return response.text().then(textResponse => {

                    if (textResponse.includes('"csrfError": true')) {
                        const error = JSON.parse(textResponse);
                        if (error) {
                            if (error.csrfError && error.redirectUrl) {
                                window.location.assign(error.redirectUrl);
                            } else {
                                showErrorLayout(error);
                            }
                        }
                    } else if (textResponse.includes('"errorMessage":')) {
                        return Promise.reject({ error: true, errorMessage: JSON.parse(textResponse).errorMessage });
                    } else {
                        var div = document.createElement('div');
                        div.innerHTML = textResponse;
                        const err = Array.from(div.querySelectorAll('code')).map(code => code.innerHTML).join('<br/>');
                        showErrorLayout(err);
                    }

                    return Promise.reject({ success: false });
                });
            }

            return response.json().then(errorJson => {
                return Promise.reject(errorJson);
            });
        });
    });
};

/**
 *
 * @param {string} url URL to get data
 * @param {object} [params] optional params to url
 */
export function getContentByUrl(url, params = {}) {
    return getFetch().then(() => {
        /**
         * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
         */
        return Promise.resolve(fetch(appendParamsToUrl(url, params), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            // headers: {
            //     //   'Content-Type': 'application/json'
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer' // no-referrer, *client
            // body: formData // body data type must match "Content-Type" header
        })).then((response) => {
            var contentType = response.headers.get('content-type');

            if (response.ok) {
                if (contentType && contentType.includes('text/html')) {
                    return response.text();
                }
                showErrorLayout('Oops, we haven\'t got text/html!');
                throw new TypeError('Oops, we haven\'t got text/html!');
            } else if (response.status === 500) {
                return response.text().then(textResponse => {

                    if (textResponse.includes('"csrfError": true')) {
                        const error = JSON.parse(textResponse);
                        if (error) {
                            if (error.csrfError && error.redirectUrl) {
                                window.location.assign(error.redirectUrl);
                            } else {
                                showErrorLayout(error);
                            }
                        }
                    } else if (textResponse.includes('errorMessage')) {
                        return Promise.reject({ error: true, errorMessage: JSON.parse(textResponse).errorMessage });
                    } else {
                        var div = document.createElement('div');
                        div.innerHTML = textResponse;
                        const err = Array.from(div.querySelectorAll('code')).map(code => code.innerHTML).join('<br/>');
                        showErrorLayout(err);
                    }

                    return Promise.reject({ success: false });
                });
            }

            return response.json().then(errorJson => {
                return Promise.reject(errorJson);
            });
        });
    });
}

/**
 *
 * @param {string} url URL to get data
 * @param {object} [params] optional params to url
 */
export function getJSONByUrl(url, params = {}) {
    return submitFormJson(url, params, 'GET');
}
