import Widget from '../Widget';

/**
 * Update text to have specified number of words in the last line
 *
 * @param {string} text - text to update
 * @param {number} transferNumber - number of words to transfer together
 * @returns {string} - updated text
 */
function getTransferWordHTML(text, transferNumber) {
    const wordSeparator = ' ';
    const wordArray = text.trim().split(wordSeparator);
    const noTransferNumber = wordArray.length - transferNumber;

    if (noTransferNumber > 1) {
        return [
            wordArray.slice(0, noTransferNumber).join(wordSeparator),
            [
                '<span class="d-inline-block">',
                wordArray.slice(noTransferNumber).join(wordSeparator),
                '</span>'
            ].join('')
        ].join(wordSeparator);
    }

    return text;
}

/**
 * @description Text element implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class Text extends Widget {
    prefs() {
        return {
            transferNumber: 1,
            ...super.prefs()
        };
    }

    init() {
        super.init();

        if (this.prefs().transferNumber > 1) {
            this.ref('self').get().innerHTML = getTransferWordHTML(this.ref('self').get().innerHTML, this.prefs().transferNumber);
        }
    }
}