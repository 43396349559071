import Widget from '../Widget';
import { scrollToTop, getScrollTop, elementOffset } from '../toolbox/scroll';
/**
 * @description Base button implementation
 * @param {typeof import('../Widget').default} baseWidget Base widget for extending
 */
export default class BackToTop extends Widget {
    prefs() {
        return {
            pageSize: 1.5,
            classesBottom: 'm-bottom',
            ...super.prefs()
        };
    }

    /**
     * @param {HTMLElement} el DOM element
     * @param {object} config widget config
     */
    constructor(el, config = {}) {
        super(el, config);
        this.bottomPosition = 0;
    }

    init() {
        this.setup();
        this.eventBus().on('viewtype.windowChange', 'setup');
        window.addEventListener('load', this.setup.bind(this));
        window.addEventListener('scroll', this.toggleBackToTopButton.bind(this));
    }

    setup() {
        const footerOffset = elementOffset(document.getElementById('pageFooter'));
        this.bottomPosition = footerOffset.top - window.innerHeight;
        this.toggleBackToTopButton();
    }

    backToTop() {
        scrollToTop();
        window.history.replaceState({ stateCount: !window.history.state ? 1 : ++window.history.state.stateCount, reviewed: false }, '', location.href);
        window.redirected = false;
        window.addEventListener('popstate', event => {
            event.preventDefault();
            if (!window.redirected) {
                window.redirected = true;
                if (window.history.state) {
                    window.history.go(-(window.history.state.stateCount));
                } else {
                    window.history.go(-1);
                }
            }
        });
    }

    toggleBackToTopButton() {
        const scrollTopPosition = getScrollTop();
        this.toggle(scrollTopPosition > this.prefs().pageSize * window.innerHeight);
        this.ref('self').toggleClass(this.prefs().classesBottom, scrollTopPosition > this.bottomPosition);
    }
}